export const BASE_API_URL = process.env.REACT_APP_API_KEY;
export const BASE_API_URL_CAP_DIEN = process.env.REACT_APP_API_URL_CAP_DIEN;
export const TOKEN_NAME = "token";
export const REFRESH_TOKEN = "refresh_token";
export const FILTER_TYPE_TEXT_INPUT = 1;
export const FILTER_TYPE_SELECT = 2;
export const FILTER_TYPE_SELECT_MULTIPLE = 3;
export const FILTER_TYPE_DATE = 4;
export const FILTER_TYPE_TIME = 5;
export const FILTER_TYPE_DATE_RANGE = 6;
export const FILTER_TYPE_TIME_RANGE = 7;
export const FILTER_TYPE_CHECK_BOX = 8;
export const SETTING = {
  email: "EMAIL",
  label: "LABEL",
  rate: "RATE",
  password: "DEFAULT_PW",
  transaction_fee: "TRANSACTION_FEE",
  share_rate: "DEFAULT_SHARE_RATE",
  ex_rate: "DEFAULT_EX_RATE",
};

export const gender = [
  {
    key: "Nam",
    title: "Nam",
  },
  {
    key: "Nữ",
    title: "Nữ",
  },
];

export const statusTag = [
  {
    key: 1,
    title: "Chờ xác nhận",
    background: "#FFFDE7",
    color: "#E5C100",
  },
  {
    key: 2,
    title: "Kinh doanh đã duyệt",
    background: "#ECFFF1",
    color: "#00AD30",
  },
  {
    key: 3,
    title: "Chờ phân công",
    background: "#E0F6FF",
    color: "#03A4FF",
  },
  {
    key: 4,
    title: "Đã phân công",
    background: "#F0FFEA",
    color: "#23C16B",
  },
  {
    key: 5,
    title: "Chờ kiểm duyệt",
    background: "#E3EDFF",
    color: "#1F61B6",
  },
  {
    key: 6,
    title: "Có trở ngại",
    background: "#FFEAEA",
    color: "#FF5247",
  },
  {
    key: 7,
    title: "Đã duyệt quyết toán",
    background: "#FFE9EF",
    color: "#C5164A",
  },
  {
    key: 8,
    title: "Chờ phòng kinh doanh duyệt phương án",
    background: "#EFE2FF",
    color: "#6316C5",
  },
  {
    key: 9,
    title: "Chờ duyệt phương án",
    background: "#FFECDB",
    color: "#F38300",
  },
  {
    key: 10,
    title: "Đã khảo sát, không có trở ngại",
    background: "#EDFEFF",
    color: "#00C0EA",
  },
  {
    key: 11,
    title: "Chưa thu tiền",
    background: "#FBFFEC",
    color: "#A8D200",
  },
  {
    key: 12,
    title: "Đã lập quyết toán NB",
    background: "#DFDCFF",
    color: "#1200E5",
  },
  {
    key: 13,
    title: "Trở ngại thi công",
    background: "#FFF1F0",
    color: "#CF1322",
  },
  {
    key: 14,
    title: "Chưa xuất vật tư",
    background: "#F5FFFF",
    color: "#00D0D0",
  },
  {
    key: 15,
    title: "Đã lập quyết toán KH",
    background: "#FFE4EE",
    color: "#FF1167",
  },
];

export const soPha = [
  {
    id: 0,
    name: "Tất cả",
  },
  {
    id: 1,
    name: "1 pha",
  },
  {
    id: 2,
    name: "3 pha",
  },
];

export const mucDichSuDung = [
  {
    id: 0,
    name: "Tất cả",
  },
  {
    id: 1,
    name: "Sinh hoạt",
  },
  {
    id: 2,
    name: "Ngoài sinh hoạt",
  },
];

export const trangThaiDiaChinh = [
  {
    key: 0,
    title: "Không hoạt động",
  },
  {
    key: 1,
    title: "Hoạt động",
  },
];

export const loaiDonViArr = [
  {
    id: 1,
    tenLoaiDonVi: "Công ty điện lực",
  },
  {
    id: 2,
    tenLoaiDonVi: "Nhà máy",
  },
  {
    id: 3,
    tenLoaiDonVi: "Truyền tải",
  },
  {
    id: 4,
    tenLoaiDonVi: "Khác",
  },
];

export const vungDonGiaArr = [
  {
    id: 1,
    tenVung: "Vùng 1",
  },
  {
    id: 2,
    tenVung: "Vùng 2",
  },
];

//MODULE

export const DEFAULT_PAGEINDEX = 1;
export const DEFAULT_PAGESIZE = 30;
export const PAGESIZE_5 = 5;
export const BLOCK_CSS = "block";
export const NONE_CSS = "none";
export const FORMAT_DATE = "DD/MM/YYYY";
export const MONTH_STRING = "month";
export const MODULE_DICHVUDIEN = "m01";
export const MODULE_TREOTHAOTHIETBI = "m02";
export const MODULE_DODEM = "m03";
export const MODULE_GHICHISO = "m04";
export const MODULE_CAPDIENTRUNGAP = "m05";
export const MODULE_BAOCAO = "m06";
export const MODULE_LOTRINH = "m07";
export const MODULE_THANHTOAN = "m08";
export const MODULE_CHISOCONGTO = "m09";
export const MODULE_CHAMSOCKHACHHANG = "m10";
export const MODULE_HETHONG = "m11";
export const MODULE_TIENICHCHUNG = "m12";

//navigate parent

export const NAVIGATE_DICHVUDIEN = "/dich-vu-dien";
export const NAVIGATE_TREOTHAOTHIETBI = "/thiet-bi-do-dem";
export const NAVIGATE_DODEM = "/giao-nhan-dien-nang";
export const NAVIGATE_GHICHISO = "/ghi-chi-so";
export const NAVIGATE_CAPDIENTRUNGAP = "/cap-dien-trung-ap";
export const NAVIGATE_BAOCAO = "/bao-cao";
export const NAVIGATE_LOTRINH = "/quan-ly-lo-trinh";
export const NAVIGATE_THANHTOAN = "/dang-ky-thanh-toan";
export const NAVIGATE_CHISOCONGTO = "/kiem-tra-chi-so";
export const NAVIGATE_CHAMSOCKHACHHANG = "/cham-soc-khach-hang";
export const NAVIGATE_HETHONG = "/quan-tri-he-thong";
export const NAVIGATE_TIENICHCHUNG = "/tien-ich-chung";
export const NAVIGATE_TRANGCHU = "/trang-chu";
export const NAVIGATE_DANGNHAP = "/dang-nhap";

//cấp điện

export const NAVIGATE_DICHVUDIEN_GIAODICHVIEN = "/giao-dich-vien";
export const NAVIGATE_DICHVUDIEN_APGIA = "/ap-gia";
export const NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT = "/nhan-vien-khao-sat";
export const NAVIGATE_DICHVUDIEN_DOITRUONG = "/doi-truong";
export const NAVIGATE_DICHVUDIEN_KIEMDUYET = "/kiem-duyet";
export const NAVIGATE_DICHVUDIEN_PHONGKINHDOANH = "/phong-kinh-doanh";
export const NAVIGATE_DICHVUDIEN_LANHDAO = "/lanh-dao";
export const NAVIGATE_DICHVUDIEN_TAICHINH = "/tai-chinh";
export const NAVIGATE_DICHVUDIEN_TIENICH = "/tien-ich";
export const NAVIGATE_DICHVUDIEN_THICONG = "/thi-cong";
export const NAVIGATE_DICHVUDIEN_NGHIEMTHU = "/nghiem-thu";
export const NAVIGATE_DICHVUDIEN_QUYETTOAN = "/quyet-toan";
export const NAVIGATE_TIEPNHANPHANCONG = "/tiep-nhan-phan-cong";
export const NAVIGATE_HOSODAPHANCONG = "/ho-so-da-phan-cong";
export const NAVIGATE_HOSODATRONGAI = "/quan-ly-ho-so-co-tro-ngai";
export const NAVIGATE_APGIAHOSO = "/ap-gia-ho-so";
export const NAVIGATE_LAPPHUONGAN = "/lap-phuong-an";
export const NAVIGATE_KHOIPHUCTHICONG = "/khoi-phuc-thi-cong";
export const NAVIGATE_DUYETHOSO = "/duyet-ho-so";
export const NAVIGATE_DUYETQUYETTOAN = "/duyet-quyet-toan";
export const NAVIGATE_XACNHANTHUTIEN = "/xac-nhan-thu-tien";
export const NAVIGATE_DONGBOTHOIGIAN = "/dong-bo-thoi-gian";
export const NAVIGATE_XACNHANTHICONG = "/xac-nhan-thi-cong";
export const NAVIGATE_NGHIEMTHUHOSO = "/nghiem-thu-ho-so";
export const NAVIGATE_LAPQUYETTOANCONGTY = "/lap-quyet-toan-cong-ty";
export const NAVIGATE_LAPQUYETTOANKHACHHANG = "/lap-quyet-toan-khach-hang";
export const NAVIGATE_XACNHANQUYETTOAN = "/xac-nhan-quyet-toan";

export const NAVIGATE_VATTU = "/vat-tu";
export const NAVIGATE_DANHSACHVATTU = "/danh-sach-vat-tu";
export const TITLE_GIAODICHVIEN = "Giao dịch viên";
export const TITLE_PHANCONGTIEPNHAN = "Tiếp nhận - Phân công";
export const TITLE_HOSODAPHANCONG = "Hồ sơ đã phân công";
export const TITLE_HOSOTRONGAI = "Quản lý hồ sơ có trở ngại";
export const TITLE_APGIA = "Áp giá";
export const TITLE_APGIAHOSO = "Áp giá hồ sơ";
export const TITLE_NHANVIENKHAOSAT = "Nhân viên khảo sát";
export const TITLE_LAPPHUONGAN = "Lập phương án";
export const TITLE_KHOIPHUCTHICONG = "Khôi phục thi công";
export const TITLE_DOITRUONG = "Đội trưởng";
export const TITLE_DUYETQUYETTOAN = "Duyệt quyết toán";
export const TITLE_KIEMDUYET = "Kiểm duyệt";
export const TITLE_PHONGKINHDOANH = "Phòng kinh doanh";
export const TITLE_LANHDAO = "Lãnh đạo";
export const TITLE_TAICHINH = "Tài chính";
export const TITLE_XACNHANTHUTIEN = "Xác nhận thu tiền";
export const TITLE_TIENICH = "Tiện ích";
export const TITLE_DONGBOTHOIGIAN = "Đồng bộ thời gian";
export const TITLE_THICONG = "Thi công";
export const TITLE_XACNHANTHICONG = "Xác nhận thi công";
export const TITLE_NGHIEMTHU = "Nghiệm thu";
export const TITLE_NGHIEMTHUHOSO = "Nghiệm thu hồ sơ";
export const TITLE_QUYETTOAN = "Quyết toán";
export const TITLE_LAPQUYETTOANCONGTY = "Lập quyết toán công ty";
export const TITLE_LAPQUYETTOANKHACHHANG = "Lập quyết toán khách hàng";
export const TITLE_XACNHANQUYETTOAN = "Xác nhận quyết toán";

export const TITLE_VATTU = "Vật tư";
export const TITLE_DANHSACHVATTU = "Danh sách vật tư";
//end cấp điện

//tiện ích chung

export const NAVIGATE_TIENICHCHUNG_DANHMUC = "/danh-muc";
export const NAVIGATE_TIENICHCHUNG_VATTU = "/vat-tu";
export const NAVIGATE_TIENICHCHUNG_DONVI = "/don-vi";
export const NAVIGATE_TIENICHCHUNG_DIACHINH = "/dia-chinh";
export const NAVIGATE_TIENICHCHUNG_IMEI = "/imei";
export const NAVIGATE_TIENICHCHUNG_TRAM = "/tram";
export const NAVIGATE_TIENICHCHUNG_LO = "/lo";
export const NAVIGATE_TIENICHCHUNG_NGANHANG = "/ngan-hang";
export const NAVIGATE_TIENICHCHUNG_DOI = "/doi";
export const NAVIGATE_TIENICHCHUNG_TILEBANDIEN = "/ti-le-ban-dien";
export const NAVIGATE_TIENICHCHUNG_UYQUYEN = "/uy-quyen";
export const NAVIGATE_TIENICHCHUNG_TINHTRANG = "/tinh-trang";
export const NAVIGATE_TIENICHCHUNG_TRANGTHAI = "/trang-thai";
export const NAVIGATE_TIENICHCHUNG_TRONGAI = "/tro-ngai";
export const NAVIGATE_TIENICHCHUNG_LOAIYEUCAU = "/loai-yeu-cau";
export const NAVIGATE_TIENICHCHUNG_EMAIL = "/email";
export const NAVIGATE_TIENICHCHUNG_CAUHINHCHUNG = "/cau-hinh-chung";
export const TITLE_QUANLYDANHMUC = "Quản lý danh mục";
export const TITLE_DANHMUCVATTU = "Vật tư";
export const TITLE_DANHMUCDONVI = "Đơn vị";
export const TITLE_DANHMUCDIACHINH = "Địa chính";
export const TITLE_DANHMUCIMEI = "Imei";
export const TITLE_DANHMUCTRAM = "Trạm";
export const TITLE_DANHMUCLO = "Lộ";
export const TITLE_DANHMUCNGANHANG = "Ngân hàng";
export const TITLE_DANHMUCDOI = "Đội";
export const TITLE_DANHMUCTILEBANDIEN = "Tỉ lệ giá bán điện";
export const TITLE_DANHMUCUYQUYEN = "Ủy quyền";
export const TITLE_DANHMUCTINHTRANG = "Tình trạng";
export const TITLE_DANHMUCTRONGAI = "Trở ngại";
export const TITLE_DANHMUCLOAIYEUCAU = "Loại yêu cầu";
export const TITLE_DANHMUCEMAIL = "Email";
export const TITLE_DANHMUCCAUHINH = "Cấu hình chung";
export const TITLE_DANHMUCTRANGTHAI = "Trạng thái";
//end

export const TITLE_TRANGCHU = "Trang chủ";
export const TITLE_DUYETHOSO = "Duyệt hồ sơ";

export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const STEP_THREE = 3;
export const STEP_FOUR = 4;

export const STATUS_CODE_200 = 200;
export const STATUSCODE_500 = 600;
export const STATUSCODE_400 = 400;
export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";
export const METHOD_DELETE = "DELETE";

export const ENUM_FUNCTION = {
  TIEPNHAN: 0,
  PHANCONG: 1,
  TRONGAI: 2,
  APGIA: 3,
  DUOCPHANCONG: 4,
  DANGKHAOSAT: 5,
  LAPPHUONGAN: 6,
  TRONGAIKHAOSAT: 7,
  DOITRUONG: 8,
  KIEMDUYET: 9,
  KINHDOANH: 10,
  LANHDAODUYETHOSO: 11,
  LANHDAODUYETQUYETTOAN: 12,
  TAICHINH: 13,
  XACNHANXUATVATTU: 14,
  DANHMUCVATTU: 15,
  XACNHANTHICONG: 16,
  KHOIPHUCTHICONG: 17,
  XACNHANNGHIEMTHU: 18,
  LAPQUYETTOANCONGTY: 19,
  LAPQUYETTOANKHACHHANG: 20,
  XACNHANQUYETTOAN: 21,
  TRACUUHOSO: 22,
};

export const ENUM_PHANCONG = {
  PHANCONG: 0,
  PHANCONGLAI: 1,
};
export const PAGE_SIZE_OPTIONS = [ 10, 20,30, 50, 100];
