import filters from "@modules/cap-dien/ho-so/tim-kiem";
import { notification, Select, message } from "antd";
import moment from "moment";

const { Option } = Select;

function importAllImageFile(r) {
  const images = [];
  r.keys().map((item) => {
    return images.push({
      name: item.replace("./", "").replace(".png", ""),
      path: r(item),
    });
  });
  return images;
}
export const imagesHomePageIcon = importAllImageFile(
  require.context(
    "../assets/images/home-page-icon",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export const stringToSlug = (str) => {
  const from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[,.*+?^${}()|[\]\\]/g, "")
    .split(" ")
    .join("_");
  return str;
};

export const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

export function buildQueryString(object) {
  if (typeof object !== "object") return "";
  const args = [];
  for (const key in object) {
    destructure(key, object[key]);
  }
  return args.join("&");

  function destructure(key, value) {
    if (key && (value || value === false || value === 0)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else if (toString(value) === "[object Object]") {
        for (const i in value) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else
        args.push(
          encodeURIComponent(key) +
            (value != null && value !== "" && value !== undefined
              ? "=" + encodeURIComponent(value)
              : "")
        );
    }
  }
}

export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== "undefined") obj[key] = params.getAll(key);
    } else {
      if (params.get(key) !== "undefined") obj[key] = params.get(key);
    }
  }

  return obj;
};

export function handlePagination(paging, sorter, filterConditions, setFilterConditions) {
  let sortKey = "orderBy";
  let currentKey = "orderByDesc";
  if (sorter.order === "descend") {
    sortKey = "orderByDesc";
    currentKey = "orderBy";
  } else {
    sortKey = "orderBy";
    currentKey = "orderByDesc";
  }
  setFilterConditions((oldState) => ({
    ...oldState,
    pageIndex: paging.pageSize !== filterConditions.pageSize ? paging.defaultCurrent : paging.current,
    pageSize: paging.pageSize,
    [sortKey]: sorter.order ? sorter.field : undefined,
    [currentKey]: undefined,
  }));
}
//hiển thị message

export function alertMessage(type, message, description) {
  notification[type]({
    message,
    description,
    duration: 3,
  });
}

export function convertDateString(date, month, year) {
  let dateString = date;
  let monthString = month;

  if (parseInt(dateString) < 10) dateString = "0" + date;
  if (parseInt(monthString) < 10) monthString = "0" + month;
  return year + "-" + monthString + "-" + dateString;
}

export function currencyFormat(num, suffix) {
  return num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " " + suffix;
}

export const formatterMoneyVN = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

export const selectAfterCurrency = (
  <Select defaultValue="VND" style={{ width: 75 }}>
    <Option value="VND">VNĐ</Option>
    <Option value="DONG/KWH">đồng/kWh</Option>
    {/* <Option value="USD">$</Option> */}
  </Select>
);

export function getErrorForm(res, form) {
  const entityError = Object.keys(res.data);
  if (entityError.length) {
    entityError.forEach((err) => {
      return form.setFields([
        {
          name: err,
          errors: [res.data[err]],
        },
      ]);
    });
  }
}

export function getErrorMessage(res, form) {
  //convert json string

  const arrMesage = JSON.parse(res.message);

  //thay thế chữ cái đầu tiên về chữ thường

  arrMesage.map((item) => {
    item.key = item.key.replace(
      item.key.charAt(0),
      item.key.charAt(0).toLocaleLowerCase()
    );
    return item;
  });

  // gán lại key = message ví dụ maDonVi: Mã đơn vị không được bỏ trống

  const newListErr = arrMesage.reduce(
    (acc, x) => [
      ...acc,
      {
        [x.key]: x.value,
      },
    ],
    []
  );

  // convert nhiều object thành 1 object cho array => {maDonVi: không được bỏ trống, tenDonVi: không được bỏ trống}

  const newObject = Object.assign({}, ...newListErr);

  //lấy key ví dụ {maDonVi: không được bỏ trống, tenDonVi: không được bỏ trống} => ['maDonVi', 'tenDonVi']

  const entityError = Object.keys(newObject);

  if (entityError.length) {
    entityError.forEach((err) => {
      return form.setFields([
        {
          name: err,
          errors: [newObject[err]],
        },
      ]);
    });
  }
}

export const getParamsUrlHoSo = (funcID) => {
  let params = "";
  let tuNgay = "";
  let denNgay = "";
  const toDay = moment();
  tuNgay = moment().startOf("month").format("YYYY-MM-DD");

  denNgay =
    toDay.year() +
    "-" +
    (toDay.month() < 10 ? "0" + (toDay.month() + 1) : toDay.month() + 1) +
    "-" +
    (toDay.date() < 10 ? "0" + toDay.date() : toDay.date());
  params = "?FuncId=" + funcID + "&StartDate=" + tuNgay + "&EndDate=" + denNgay;
  return params;
};

export const displayFilter = (condition) => {
  let newArrayFilter = [];
  if (condition) {
    // eslint-disable-next-line
    filters.map((item) => {
      if (condition.includes(item.display)) newArrayFilter.push(item);
    });
  } else newArrayFilter = filters;

  return newArrayFilter;
};

export function formatDateMonth(value) {
  if (value < 10) return "0" + value;
  return value;
}

export const disabledDate = (current) => {
  return current && current.valueOf() > Date.now(); // Can not select days after today and today
};

export const convertISODateToNormalDate = (ISODate) =>
  (ISODate.getMonth() > 8
    ? ISODate.getMonth() + 1
    : "0" + (ISODate.getMonth() + 1)) +
  "/" +
  (ISODate.getDate() > 9 ? ISODate.getDate() : "0" + ISODate.getDate()) +
  "/" +
  ISODate.getFullYear();

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Vui lòng tải ảnh có định dạng JPG/PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Dung lượng ảnh không được vượt quá 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const disabledDateMonth = (current) => {
  const currentDate = moment(current);

  const minDate = moment('01/2021', 'MM/YYYY');

  return currentDate.isBefore(minDate, 'month');
}

export const disabledDateTime = (current) => {
  const startDate = new Date('2021-01-01');

  current = new Date(current.format('YYYY-MM-DD'));

  return current < startDate;
}
  
export const mapLanguageToCulture = () => {
  const savedLanguage = localStorage.getItem("selectedLanguage");
  let culture = '';

    if (savedLanguage === 'vi') {
        culture = 'vi-VN';
    } else if (savedLanguage === 'en') {
        culture = 'en-US';
    }

    return culture;
}
  
export const mapCurrencyToCulture = () => {
  const savedCurrency = localStorage.getItem("selectedCurrency");
  let currency = '';

    if (savedCurrency === 'vnd') {
        currency = 'VND';
    } else if (savedCurrency === 'eur') {
        currency = 'EUR';
    }

    return currency;
}
