import { Layout } from "antd";
import styled from "styled-components";
const { Footer } = Layout;

export default function FooterComponent() {
  return (
    <Footer style={{ marginTop: "auto" }}>
      © 2022, Made with ❤️ by{" "}
      <Link href="https://www.3si.vn/" target="_blank">
        3S INTERSOFT
      </Link>
    </Footer>
  );
}

const Link = styled.a``;
