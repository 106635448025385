import {
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { BASE_API_URL } from "@utils/constants";
import { disabledDate, getErrorForm, mapLanguageToCulture, removeAccents } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Select,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";


const { Header } = Layout;

const CreateUser = () => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const [state, setState] = useState({ loading: false });
  const [load, setLoading] = useState(false);
  const [dataLabel, setDataLabel] = useState([]);
  const [authories, setAuthories] = useState(false);
  const [disablebutton, setdisablebutton] = useState(false);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t('Upload photo file other than JPG/PNG format!'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('Photos must be smaller than 2MB!'));
    }
    return isJpgOrPng && isLt2M;
  }
  const navigate = useNavigate();

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t('Success'),
        description: t('Upload photo successful'),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setState({
        imageUrl: BASE_API_URL + info.file.response.data,
        loading: false,
      });
    }
  };

  const onFinish = (values) => {
    const formData = {
      ...values,
      birthday: values.birthday ? moment(values.birthday).format('YYYY-MM-DDTHH:mm:ss') : null,
      password: "123456789",
      isAdmin: values.isAdmin,
      isDefault: values.isDefault,
      avatarUrl: imageUrl,
      gender: values.gender === undefined ? 0 : values.gender,
    };

    const { ...dataForm } = formData;
    authPostData({
      url: `api/v1/admin/user-register?culture=${culture}`,
      method: "POST",
      payload: dataForm,
      setLoading: setdisablebutton,
      setImportLoading: () => { },
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          form.resetFields();
          notification.open({
            message: t('Success'),
            description: t('Add new user successful'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          handleBackLink()
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const { loading, imageUrl } = state;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('Upload image')}</div>
    </div>
  );
  const [form] = Form.useForm();
  const [pwData, setPwData] = useState({});
  const [valueInput, setValueInput] = useState("")
  const [dataByEmail, setDataByEmail] = useState({})
  const onFinishFailed = () => { };
  const layout = {
    labelCol: { lg: 6, md: 8 },
    wrapperCol: { lg: 12, md: 15 },
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    placeholder: t('Select Label name'),
    maxTagCount: "responsive",
  };

  const handleChangeArtists = () => { };

  useEffect(() => {
    form.setFieldsValue({ password: "1111111111111111", isDefault: true });
  }, [])


  const handleAuthories = (value) => {
    setAuthories(value);
  };

  const handleChangeFields = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
  };

  const customFormat = (value) => `${moment(value).format("DD/MM/YYYY")}`;

  useEffect(() => {
    authGetData({
      url: "api/v1/overview/filter/labelnames",
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setDataLabel(res.data);
        }
      },
    });
  }, []);

  const handleBackLink = () => {
    window.history.back();
  }

  const ModalWarningChange = () => {
    const modal = Modal.confirm({
      centered: true,
      width: 600,
      title: t('Save the change?'),
      content:
        t('If you move/close tab without saving, the changes you made earlier will be lost'),
      okText: t('Agree'),
      okButtonProps: { type: "primary" },
      cancelText: t('Cancel'),
      onOk() {
        handleBackLink();
        modal.destroy();
      },
      onCancel() {
        modal.destroy();
      },
    });
  }

  const handleClose = () => {
    ModalWarningChange();
  };

  const handleChangeInput = (e) => {
    setValueInput(e.target.value)
  };

  const handleEmailArtist = () => {
    authGetData({
      url: `api/v1/admin/auto-generate?email=${valueInput}&culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res?.data?.isAutoGenerate) {
          setDataByEmail(res?.data)
        } else { setDataByEmail({}) }
      },
    });
  };

  useEffect(() => {
    if (dataByEmail) {
      form.setFieldsValue({
        address: dataByEmail.address ? dataByEmail.address : null,
        gender: dataByEmail.gender ? dataByEmail.gender : null,
        birthday: dataByEmail.birthday ? moment(dataByEmail?.birthday) : null,
        fullName: dataByEmail.fullName ? dataByEmail.fullName : null,
        phoneNumber: dataByEmail.phoneNumber ? dataByEmail.phoneNumber : null,
        userIds: dataByEmail.userRps?.map((item) => item.userId),
      });
      setState({ imageUrl: dataByEmail.avatarUrl });
    }
  }, [form, dataByEmail]);

  return (
    <>
      <div
        style={{
          padding: "20px",
          backgroundColor: "rgb(229, 229, 229)",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: "8px",
          }}
        >
          <div className="title">
            <h4>{t('Create a user account')}</h4>
          </div>
          <ContentStyled>
            <div className="image">
              <Upload
                name="File"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={BASE_API_URL + "api/v1/upload"}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%", height: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
            <div className="infor">
              <Form
                {...layout}
                form={form}
                name="basic"
                initialValues={{ isAdmin: false, isDefault: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onValuesChange={handleChangeFields}
              >
                <Form.Item label={t('Email')} name="email"
                  rules={[
                    { required: true, message: t('Please input email') },
                    {
                      type: 'email',
                      message: t('Invalid email format'),
                    }]}
                >
                  <Input
                    placeholder={t('User Email')}
                    onBlur={handleEmailArtist}
                    onPressEnter={handleEmailArtist}
                    onChange={handleChangeInput}
                  />
                </Form.Item>
                <Form.Item label={t('Full Name')} name="fullName"
                  rules={[
                    {
                      required: true,
                      message: t('Fullname is required'),
                    },
                    { min: 4, message: t('Full name must be between 4-50 characters in length') },
                    { max: 50, message: t('Full name must be between 4-50 characters in length') },
                  ]}>
                  <Input placeholder={t('Full Name')} />
                </Form.Item>
                <Form.Item name="isAdmin" label={t('Role')}
                  rules={[
                    { required: true, message: t('Role is required') },
                  ]}>
                  <Select onChange={handleAuthories}>
                    <Select.Option value={false}>{t('Users')}</Select.Option>
                    <Select.Option value={true}>{t('Admin')}</Select.Option>
                  </Select>
                </Form.Item>
                {!authories && (
                  <Form.Item label={t('Labels')} name="userIds"
                    rules={[
                      {
                        required: true,
                        message: t('Label is required'),
                      }]}>
                    <Select
                      showSearch
                      showArrow={true}
                      allowClear
                      onChange={handleChangeArtists}
                      {...selectProps}
                      filterOption={(input, option) => {
                        if (option && option.children) {
                          return (
                            removeAccents(option.children)
                              .toLowerCase()
                              .indexOf(removeAccents(input).toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {dataLabel.map((item) => {
                        const { userId, labelName } = item;
                        return (
                          <Select.Option value={userId} key={userId}>
                            {labelName ? labelName : t('Unknown')}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item name="birthday" label={t('Birth date')}>
                  <DatePicker
                    locale={i18n.language === 'vi' ? localeVi : localeEn}
                    disabledDate={disabledDate}
                    placeholder={t('Select birth date')}
                    format={customFormat}
                  />
                </Form.Item>

                <Form.Item name="gender" label={t('Gender')}>
                  <Select placeholder={t('Gender')}>
                    <Select.Option value={1}>{t('Male')}</Select.Option>
                    <Select.Option value={2}>{t('Female')}</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label={t('Phone number')} name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: t('Phone number is required'),
                    },
                    { max: 20, message: t('Phone number not exceed 20 characters') },
                  ]}>
                  <Input placeholder={t('Input user phone number')} />
                </Form.Item>

                <Form.Item name="address" label={t('Address')}
                  rules={[
                    { max: 255, message: t('Address information should not exceed 255 characters') },
                  ]}>
                  <Input.TextArea placeholder={t('Address')} rows="4" />
                </Form.Item>

                <Form.Item name="isDefault">
                  <button className="btn primary-btn" type="submit" disabled={disablebutton}>
                    {t('Add')}
                  </button>
                  <button type="button" className="btn close-btn" onClick={handleClose}>{t('Cancel')}</button>
                </Form.Item>
              </Form>
            </div>
          </ContentStyled>
        </div>
      </div>
    </>
  );
};

export default CreateUser;

const ContentStyled = styled.div`
  display: flex;
  .ant-upload.ant-upload-select-picture-card {
    height: 200px;
    width: 200px;
    margin-top: 25px;
    text-align: center;
    object-fit: cover;
  }
  .infor {
    flex: 1;
  }
  button {
    margin: 20px;
  }
  .ant-form-item-label {
    font-weight: 500;
    font-size: 20px;
  }
  .close-btn {
    border: #ccc;
    background-color: #ccc;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 992px) {
    .ant-upload.ant-upload-select-picture-card {
      height: 140px;
      width: 140px;
    }
  }
`;
