import { DeleteOutlined, EditOutlined, SmileOutlined } from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import {
  convertISODateToNormalDate,
  disabledDateMonth,
  getErrorForm,
  handlePagination,
  mapLanguageToCulture,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData, authPutData } from "@utils/request";
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Spin,
} from "antd";
import moment from "moment";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import styled from "styled-components";

const CurrencyMain = ({ data, loading, feeData, exRateData, shRateData }) => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const [form] = Form.useForm();
  const [form_fee] = Form.useForm();
  const [form_ex] = Form.useForm();
  const [form_sh] = Form.useForm();
  const [id, setId] = useState();
  const [load, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [visible, setVisible] = useState(false);
  const [dataCurrency, setDataCurrency] = useState([])

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const getData = () => {
    authGetData({
      url: `api/v1/admin/setting/exchange-rate?culture=${culture}`,
      method: "GET",
      setLoading: setLoadingList,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataCurrency(
            res?.data?.map((e, index) => ({
              ...e,
              no:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          )
        }
      },
    });
  };
  useEffect(() => {
    getData();
  }, [culture]);

  useEffect(() => {
    if (feeData || exRateData || shRateData) {
      form_fee.setFieldsValue({ value: feeData.value });
      form_ex.setFieldsValue({ value: exRateData.value });
      form_sh.setFieldsValue({ value: shRateData.value });
    }
  }, [form_fee, feeData, form_ex, exRateData, form_sh, shRateData]);
  const columns = [
    {
      title: t('No.'),
      dataIndex: "no",
      align: "center",
      width: "5%",
    },
    {
      title: t('Exchange rate') + " (đ)",
      dataIndex: "rate",
      render: (text) => text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      align: "left",
      width: "25%",
    },
    {
      title: t('Start month'),
      dataIndex: "startDate",
      render: (text) => moment(text).format("MM/YYYY"),
      align: "left",
      width: "25%",
    },
    {
      title: t('End month'),
      dataIndex: "endDate",
      render: (text) => moment(text).format("MM/YYYY"),
      align: "left",
      width: "25%",
    },
    {
      title: t('Custom...'),
      render: (item) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onEdit(item)}
          />
          {!item.isUsed && (
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12, cursor: "pointer" }}
              onClick={() => showDeleteConfirm(item)}
            />
          )}
        </Space>
      ),
      width: "20%",
    },
  ];
  const showDeleteConfirm = (item) => {
    setDeleteId(item?.rateId);
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/admin/setting/exchange-rate/${id}?culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getData();
          setVisible(false);
          notification.open({
            message: t('Success'),
            description: t('Delete successful'),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
        }
      },
    });
  };
  const onEdit = (item) => {
    setEdit(true);
    setId(item.rateId);
    form.setFieldsValue({
      rate: item?.rate,
      startDate: item?.startDate ? moment(item?.startDate) : null,
      endDate: item?.endDate ? moment(item?.endDate) : null,
    });
  };
  const onFinish = (values) => {
    const formData = {
      ...values,
      startDate: values.startDate && convertISODateToNormalDate(new Date(values.startDate._d)).substring(0, 2) + "/" + convertISODateToNormalDate(new Date(values.startDate._d)).substring(6, 10),
      endDate: values.endDate && convertISODateToNormalDate(new Date(values.endDate._d)).substring(0, 2) + "/" + convertISODateToNormalDate(new Date(values.endDate._d)).substring(6, 10),
    };
    if (edit) {
      const EditForm = {
        ...formData,
        id: id,
      };
      authPutData({
        url: `api/v1/admin/setting/exchange-rate?culture=${culture}`,
        method: "PUT",
        payload: EditForm,
        setLoading: setLoadingList,
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            setId();
            setEdit((state) => !state);
            form.resetFields();
            notification.open({
              message: t('Success'),
              description: t('Update successful'),
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            getData();
          } else {
            getErrorForm(res, form);
          }
        },
      });
    } else {
      authPostData({
        url: `api/v1/admin/setting/exchange-rate?culture=${culture}`,
        method: "POST",
        payload: formData,
        setLoading: setLoadingList,
        setImportLoading: () => { },
        onSuccess: (res) => {
          form.resetFields();
          if (res.statusCode === 200) {
            notification.open({
              message: t('Success'),
              description: t('Add new exchange rate successful'),
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            getData();
          } else {
            getErrorForm(res, form);
          }
        },
      });
    }
  };
  const onEditFee = (value) => {
    const formData = { ...value, key: feeData.key };
    authPutData({
      url: `api/v1/admin/setting/common-setting?culture=${culture}`,
      method: "PUT",
      payload: formData,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: t('Successfull transaction fee setting'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        }
      },
    });
  };
  const onEditEx = (value) => {
    const formData = { ...value, key: exRateData.key };

    authPutData({
      url: `api/v1/admin/setting/common-setting?culture=${culture}`,
      method: "PUT",
      payload: formData,
      setLoading: setTableLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: t('Update successful'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        }
      },
    });
  };

  const onEditSh = (value) => {
    const formData = { ...value, key: shRateData.key };

    authPutData({
      url: `api/v1/admin/setting/common-setting?culture=${culture}`,
      method: "PUT",
      payload: formData,
      setLoading: setTableLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: t('Update successful'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        }
      },
    });
  };

  const onCancelEdit = () => {
    setEdit(false);
    setId(null);
    form.resetFields();
  };

  const layout = {
    labelCol: { xl: 11, lg: 12, md: 13 },
    wrapperCol: { xl: 13, lg: 12, md: 11 },
  };

  return (
    <Spin spinning={loading}>
      <LayoutMain>
        <div>
          <Form
            name="nest-messages"
            onFinish={onEditFee}
            form={form_fee}
            {...layout}
          >
            <Row gutter={[20, 20]}>
              <Col xl={12} lg={12} md={18}>
                <Form.Item
                  name="value"
                  label={t('Transaction fee')}
                  tooltip={t('Set up currency conversion fees for intermediaries')}
                  rules={[
                    {
                      required: true,
                      message: t('Transaction fee is required'),
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    formatter={(value) =>
                      value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                    }
                    addonAfter="đ"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} lg={10} md={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="button">
                    {t('Set up')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <div>
          <Form
            name="nest-messages-2"
            onFinish={onEditEx}
            form={form_ex}
            {...layout}
          >
            <Row gutter={[20, 20]}>
              <Col xl={12} lg={12} md={18}>
                <Row gutter={0}>
                  <Col span={24}>
                    <Form.Item
                      name="value"
                      label={t('Default exchange rate')}
                      tooltip={t('Used when the Euro rate has not been defined')}
                      rules={[
                        {
                          required: true,
                          message: t('Exchange rate is required'),
                          pattern: new RegExp(/^[0-9]+$/),
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) =>
                          value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                        }
                        addonAfter="đ"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={11} lg={12} md={13}>
                    <div className="margin-left">
                      <em className="text-note">
                        {t('Get data from Vietcombank.')} <br />
                        {t('Exchange rate are updated at:')} {moment().format("YYYY/MM/DD")} 09:00
                      </em>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={10} md={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="button">
                    {t('Set up')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <div>
          <Form
            name="nest-messages-3"
            onFinish={onEditSh}
            form={form_sh}
            {...layout}
          >
            <Row gutter={[20, 20]}>
              <Col xl={12} lg={12} md={18}>
                <Form.Item
                  name="value"
                  label={t('Default revenue rate')}
                  tooltip={t('For Labels')}
                  rules={[
                    {
                      required: true,
                      message: t('Default revenue rate is required'),
                    },
                  ]}
                >
                  <InputNumber
                    formatter={(value) =>
                      value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                    }
                    addonAfter="%"
                    suffix="%"
                    decimalScale={2}
                  />
                </Form.Item>
              </Col>
              <Col xl={7} lg={10} md={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="button">
                    {t('Set up')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <h2>{t('EURO exchange rate set up')}</h2>
        <Form form={form} onFinish={onFinish} >
          <Row gutter={[20, 20]}>
            <Col xl={6} lg={8} md={12}>
              <Form.Item label={t('Exchange rate')} name="rate"
                rules={[
                  {
                    required: true,
                    message: t('Exchange rate is required'),
                  }]}>
                <InputNumber
                  formatter={(value) =>
                    value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                  }
                  addonAfter="đ"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} md={12}>
              <Form.Item label={t('Start month')} name="startDate"
                rules={[
                  {
                    required: true,
                    message: t('Please fill in all the information'),
                  }]}>
                <DatePicker
                  locale={i18n.language === 'vi' ? localeVi : localeEn}
                  placeholder={t('Select month')}
                  format="MM/YYYY"
                  picker="month"
                  disabledDate={disabledDateMonth}
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} md={12}>
              <Form.Item label={t('End month')} name="endDate"
                rules={[
                  {
                    required: true,
                    message: t('Please fill in all the information'),
                  }]}>
                <DatePicker
                  locale={i18n.language === 'vi' ? localeVi : localeEn}
                  placeholder={t('Select month')}
                  format="MM/YYYY"
                  picker="month"
                  disabledDate={disabledDateMonth}
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={24} md={12}>
              <div className="action-buttons">
                {!edit ? (
                  <button type="submit" className="btn primary-btn small-btn">
                    {t('Set up')}
                  </button>
                ) : (
                  <Space>
                    <button type="submit" className="btn primary-btn small-btn">
                      {t('Update')}
                    </button>
                    <button
                      className="btn outline-btn small-btn"
                      type="button"
                      onClick={onCancelEdit}
                    >
                      {t('Cancel')}
                    </button>
                  </Space>
                )}
              </div>
            </Col>
          </Row>
        </Form>
        <TableComponent
          rowKey="key"
          dataSource={dataCurrency}
          columns={columns}
          totalData={dataCurrency.length}
          loading={loadingList}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
        />
        <Modal
          title={t('Please confirm')}
          open={visible}
          onOk={() => handleDelete(deleteId)}
          onCancel={handleCancel}
          footer={[
            <button
              type="button"
              className="btn outline-btn"
              key="back"
              onClick={handleCancel}
            >
              {t('Cancel')}
            </button>,
            <button
              key="submit"
              type="submit"
              className="btn primary-btn"
              // loading={loading}

              onClick={() => handleDelete(deleteId)}
            >
              {t('Agree')}
            </button>,
          ]}
        >
          <p>{t('Are you sure you want to delete ?')}</p>
        </Modal>
      </LayoutMain>
    </Spin>
  );
};

export default memo(CurrencyMain);

const LayoutMain = styled.div`
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .button-edit {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .ant-form-inline {
    display: flex;
    justify-content: flex-start;
    .ant-form-item {
      margin-right: 20px !important;
    }
  }
  .ant-input-number-group-wrapper {
    display: flex;
  }
  .ant-table-row {
    text-align: center;
  }
  .action-buttons {
    justify-content: start;
    margin-top: 24px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .margin-left {
    margin-left: 18px;
  }
  .text-note {
    color: #1e1a1a;
    font-size: 10px;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: end;
      margin-top: 0;
    }
    .text-note {
      font-size: 8px;
    }
  }

  @media (max-width: 992px) {
    .action-buttons {
      justify-content: start;
      margin-top: 24px;
    }
  }
`;
