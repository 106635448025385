import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function Confirm({
  title,
  content,
  okText,
  cancelText,
  onOk = () => {},
  onCancel = () => {},
  className = "",
}) {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
    className,
  });
}

export default Confirm;
