import { ConfigProvider } from "antd";
import "moment/locale/vi"; // important for multi-language setup to work
import vi_VN from "antd/lib/locale/vi_VN";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./App";
import store, { persistor } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./scrollToTop";

import "./css/antd.css";
import "./css/Footer.css";
import "./css/grid.css";
import "./css/main.css";
import "./css/overview.css";
import "./css/textEditor.css";
import "./css/overviewdetail.css";
import "./css/rangePicker.css";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <ConfigProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
            <ScrollToTop />
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById("root")
);
reportWebVitals();
