import common from "./common.json";
import item from "./item.json";
import enums from "./enums.json"
import message from "./message.json";

const en = {
  ...common,
  ...item,
  ...enums,
  ...message,
};

export { en };