import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APILoaiYeuCau = {
  LIST_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/loai-yeu-cau/list`,
  SYNC_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/loai-yeu-cau/sync`,
  GET_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/loai-yeu-cau/get`,
  CREATE_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/loai-yeu-cau/create`,
  UPDATE_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/loai-yeu-cau/update`,
  REMOVE_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/loai-yeu-cau/delete`,
};
