import React from "react";

function StreamIcon() {
  return (
    <svg
      width="102"
      height="94"
      viewBox="0 0 102 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102 81.8334C90.8167 81.8334 81.6667 90.9834 81.6667 102.167C81.6667 113.35 90.8167 122.5 102 122.5C113.183 122.5 122.333 113.35 122.333 102.167C122.333 90.9834 113.183 81.8334 102 81.8334ZM163 102.167C163 65.9734 131.382 37.1 94.3751 41.675C67.7384 44.9284 45.7784 66.1767 41.7117 92.7117C38.3567 114.57 46.6934 134.497 61.3334 147.612C66.2134 151.983 73.8384 150.967 77.1934 145.273L77.2951 145.172C79.7351 140.902 78.7184 135.717 75.0584 132.362C64.5867 122.907 58.8934 108.267 62.6551 92.305C66.0101 77.8684 77.7017 66.1767 92.1384 62.72C118.775 56.315 142.667 76.445 142.667 102.167C142.667 114.163 137.38 124.838 129.145 132.26C125.485 135.513 124.367 140.8 126.807 145.07L126.908 145.172C130.06 150.56 137.38 152.187 142.158 148.018C154.867 136.835 163 120.467 163 102.167ZM90.1051 1.21168C43.1351 6.49834 5.2134 45.2333 0.841728 92.305C-2.71661 129.922 14.2617 163.675 41.7117 183.907C46.5917 187.465 53.5051 185.94 56.5551 180.755C59.0967 176.383 57.9784 170.69 53.9117 167.64C30.7317 150.458 16.8034 121.382 21.7851 89.3567C27.2751 53.7733 56.9617 25.4083 92.7484 21.4433C141.752 15.8517 183.333 54.18 183.333 102.167C183.333 129.108 170.218 152.797 150.088 167.64C146.022 170.69 144.903 176.282 147.445 180.755C150.495 186.042 157.408 187.465 162.288 183.907C187.4 165.403 203.667 135.717 203.667 102.167C203.667 42.0817 151.512 -5.80333 90.1051 1.21168Z"
        fill="#3399FF"
        fillOpacity="0.2"
      />
    </svg>
  );
}

export default StreamIcon;
