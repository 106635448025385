import {
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { BASE_API_URL } from "@utils/constants";
import { disabledDate, getErrorForm, mapLanguageToCulture } from "@utils/function";
import { authGetData, authPutData } from "@utils/request";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Spin,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Info = ({ userData, load }) => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const { isAdmin } = useSelector((state) => state.auth);
  const [, setLoading] = useState(false);
  const [loadingUpdateInfor, setLoadingUpdateInfor] = useState(false);
  const [state, setState] = useState({ loading: false });
  const [form] = Form.useForm();
  const [dataLabel, setDataLabel] = useState([]);
  const navigate = useNavigate();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t('Upload photo file other than JPG/PNG format!'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('Photos must be smaller than 2MB!'));
    }
    return isJpgOrPng && isLt2M;
  }

  useEffect(() => {
    if (userData) {
      const date = new Date(userData.birthday);
      form.setFieldsValue({
        ...userData,
        gender: userData.gender ? userData.gender : null,
        birthday: userData.birthday ? moment(date) : null,
        userIds: userData.userRps?.map((item) => item.userId),
      });
      setState({ imageUrl: userData.avatarUrl });
    }
  }, [form, userData]);
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      notification.open({
        message: t('Success'),
        description: t('Upload photo successful'),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setState({
        imageUrl: BASE_API_URL + info.file.response.data,
        loading: false,
      });
    }
  };
  const onFinish = (values) => {
    const formData = {
      ...values,
      avatarUrl: imageUrl,
      gender: values.gender === undefined ? 0 : values.gender,
      IsMobile: true,
      birthday: values.birthday ? moment(values.birthday).format('YYYY-MM-DDTHH:mm:ss') : null,
    }

    const form_data_with_no_null = Object.fromEntries(
      Object.entries(formData).filter(([_, v]) => v != null)
    );
    authPutData({
      url: `api/v1/user/update?culture=${culture}`,
      method: "PUT",
      payload: form_data_with_no_null,
      setLoading: setLoadingUpdateInfor,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: t('Update successful'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          navigate("/overview");
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };
  const { loading, imageUrl } = state;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('Upload image')}</div>
    </div>
  );
  const layout = {
    labelCol: { xl: 6, lg: 6, md: 8 },
    wrapperCol: { xl: 12, lg: 14, md: 16 },
  };

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/labelnames?culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setDataLabel(res.data);
        }
      },
    });
  }, [savedLanguage]);


  const handleChangeFields = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
  };

  return (
    <ContentStyled>
      <div className="image">
        <Upload
          name="File"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action={BASE_API_URL + `api/v1/upload?culture=${culture}`}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%", height: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
      <div className="infor">
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          form={form}
          onValuesChange={handleChangeFields}
        >
          <Form.Item name="fullName" label={t('Full Name')}
            rules={[
              {
                required: true,
                message: t('Fullname is required'),
              },
              { min: 4, message: t('Full name must be between 4-50 characters in length') },
              { max: 50, message: t('Full name must be between 4-50 characters in length') },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item name="email" label={t('Email')}
            rules={[
              {
                required: true,
                message: t('Please input email'),
              },
              {
                type: 'email',
                message: t('Invalid email format'),
              }]}>
            <Input disabled={true} />
          </Form.Item>


          {!userData.isAdmin && (
            <Form.Item label={t('Labels')} name="userIds"
              rules={[
                {
                  required: true,
                  message: t('Label is required'),
                }]}>
              <Select
                mode="tags"
                allowClear
                placeholder="Không có"
                disabled={true}
              >
                {dataLabel.map((item) => {
                  const { userId, labelName } = item;

                  return (
                    <Select.Option value={userId} key={userId}>
                      {labelName}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Form.Item label={t('Birth date')} name="birthday">
            <DatePicker
              locale={i18n.language === 'vi' ? localeVi : localeEn}
              disabledDate={disabledDate}
              placeholder={t('Select birth date')}
            />
          </Form.Item>

          <Form.Item name="gender" label={t('Gender')}>
            <Select>
              <Select.Option value={1}>{t('Male')}</Select.Option>
              <Select.Option value={2}>{t('Female')}</Select.Option>
            </Select>
          </Form.Item>


          <Form.Item
            name="phoneNumber"
            label={t('Phone number')}
            rules={[
              {
                required: true,
                message: t('Phone number is required'),
              },
              { max: 20, message: t('Invalid phone number format') },
            ]}
          >
            <Input placeholder={t('Input user phone number')} />
          </Form.Item>

          <Form.Item name="address" label={t('Address')}
            rules={[
              { max: 255, message: t('Address information should not exceed 255 characters') },
            ]}>
            <Input.TextArea rows="4" />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
            <button type="submit" className="btn primary-btn" disabled={loadingUpdateInfor}>
              {t('Update')}
            </button>
          </Form.Item>
        </Form>
      </div>
    </ContentStyled>
  );
};

export default Info;

const ContentStyled = styled.div`
  .avatar-uploader img {
    width: 100%;
    height: 100%;
  }
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  display: flex;
  .ant-upload.ant-upload-select-picture-card {
    height: 200px;
    width: 200px;
    margin-top: 25px;
    text-align: center;
    object-fit: cover;
  }
  .ant-spin-container {
    display: flex;
  }
  .infor {
    flex: 1;
  }
  button {
    margin-bottom: 50px;
  }
  .ant-form-item-label {
    font-weight: 500;
    font-size: 20px;
  }

  @media (max-width: 980px) {
    .ant-upload.ant-upload-select-picture-card {
      height: 140px;
      width: 140px;
    }
  }
`;
