import { createSlice } from "@reduxjs/toolkit";
import { TOKEN_NAME, REFRESH_TOKEN } from "@utils/constants";

const initialState = {
  isAuthenticated: false,
  isAdmin: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInFinish: (state, action) => {
      localStorage.setItem(TOKEN_NAME, action.payload.accessToken);
      localStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken);
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: action.payload.isAdmin,
      };
    },
    getUserProfile: (state) => {
      return {
        ...state,
      };
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    },
    logoutSuccess: (state) => {
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: null,
        user: {},
      };
    },
    resetAuth: () => initialState,
  },
});

export const {
  signInFinish,
  getUserProfile,
  loginSuccess,
  logoutSuccess,
  resetAuth,
} = authSlice.actions || {};

export default authSlice.reducer;
