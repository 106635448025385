import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIChungLoaiVatTu = {
  LIST_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/chung-loai-vat-tu/list`,
  SYNC_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/chung-loai-vat-tu/sync`,
  CREATE_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/chung-loai-vat-tu/create`,
  UPDATE_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/chung-loai-vat-tu/update`,
  REMOVE_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/chung-loai-vat-tu/delete`,
  GET_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/chung-loai-vat-tu/get`,
};
