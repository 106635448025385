import { ConfigProvider, Empty, Select, Table, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_API_URL } from "@utils/constants";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { mapCurrencyToCulture } from "@utils/function";

function OverViewDetailApplication({
  setValueSort,
  valueSort,
  setRevenueSort,
  revenueSort,
  queryRevenuePlatform,
  setQueryRevenuePlatform
}) {
  const { t } = useTranslation();
  const currency = mapCurrencyToCulture();
  const datas = useSelector((state) => state.date.streamPlatform);

  const [dataRender, setDataRender] = useState([]);

  const columns = [
    {
      title: t('No.'),
      dataIndex: "key",
      align: "center",
      width: "11%",
    },
    {
      title: t('Stores'),
      dataIndex: "referral",
      align: "left",
      className: "platform-cell",
      width: "25%",
      render: (cell) => <Tooltip title={cell}>{cell}</Tooltip>,
    },
    {
      title:  currency === 'EUR' ? t('Net revenue') + " (€)" : t('Net revenue') + " (đ)",
      dataIndex: "revenue",
      align: "right",
      width: "22%",
      className: "platform-revenue",
    },
    {
      title: t('Stream/Download'),
      className: "total-stream",
      dataIndex: "stream",
      align: "right",
      width: "25%",
    },
    {
      title:  currency === 'EUR' ? t('Unit price') + " (*10⁴ €)" : t('Unit price') + " (đ)",
      className: "averageNumber",
      dataIndex: "averageNumber",
      align: "right",
      width: "25%",
    },
  ];

  useEffect(() => {
    if (datas) {
      const renderData = datas.map((item, index) => {
        const { averageNumber, platformName, revenue, totalView, totalDownload, imageUrl } = item;

        return {
          key: `${index + 1}`,
          referral: (
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "5px" }}>
                <img
                  src={
                    imageUrl ? BASE_API_URL + imageUrl : "../platformImg.jpg"
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  alt="an-img"
                />
              </span>
              <span>{platformName}</span>
            </div>
          ),
          revenue: Number(revenue.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
          stream: totalView
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
          averageNumber: Number(averageNumber.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),

        };
      });
      setDataRender(renderData);
    }
  }, [datas]);

  const { Option } = Select;

  const handleChange = (value) => {
    setValueSort(value);

    setQueryRevenuePlatform({ ...queryRevenuePlatform, SortEnum: value })

  };

  const handleChangeSort = (value) => {
    setRevenueSort(value);

    setQueryRevenuePlatform({ ...queryRevenuePlatform, TopRevenueOrView: value })

  };

  return (
    <div className="overview-detail-application">
      <div
        className="overview-detail-application-beside"
      >
        <ConfigProvider
          style={{ height: "100%" }}
          renderEmpty={() => <Empty description={t('No Records Found')} />}
        >
          <Styled style={{ height: "100%" }}>
            <div className="overview-platform-styled">
              <Table
                columns={columns}
                dataSource={dataRender}
                scroll={{ y: Math.min(dataRender.length * 54 + 53, 220) }}
                title={() => {
                  return (
                    <div className="overview-platform-title">
                      <h1>{t('Digital music store')}</h1>
                      <div className="overview-platform-sort-container">
                        <div className="overview-platform-sort-content">
                          <Select
                            value={revenueSort}
                            style={{ width: 242 }}
                            onChange={handleChangeSort}
                          >
                            <Option value="1">{t('Sorting by net revenue')}</Option>
                            <Option value="0">{t('Sorting by stores')}</Option>
                            <Option value="2">{t('Sorting by stream/download')}</Option>
                            <Option value="3">{t('Sorting by unit price')}</Option>
                          </Select>
                        </div>
                        <div className="overview-platform-title-sort">
                          <Select
                            value={valueSort}
                            style={{ width: 114 }}
                            onChange={handleChange}
                          >
                            <Option value="1">{t('Highest')}</Option>
                            <Option value="2">{t('Lowest')}</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  );
                }}
                pagination={false}
              />
            </div>
          </Styled>
        </ConfigProvider>
      </div>
    </div>
  );
}

export default memo(OverViewDetailApplication);

const Styled = styled.div`
  .overview-platform-styled {
    height: 100%;
    padding: 10px 20px;
    background-color: #fdfdfd;
    border-radius: 10px;
    min-height: 378px ;
  }
  .ant-table-title {
    padding: 16px 0; 
  }
  .ant-table-tbody {
    height: auto !important;
  }
`;