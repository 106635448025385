import { disabledDateTime, removeAccents } from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Collapse, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import styled from "styled-components";
const { Option } = Select;

function PaymentHistoryFilter({
  loading,
  setLoading,
  setFilterConditions,
  setUserIds,
  userIds,
}) {
  const { t, i18n } = useTranslation();
  const [albumName, setAlbumName] = useState([]);
  const [selectArtirsts, setSelectArtirsts] = useState(null);
  const [, setLoadingAlbum] = useState(false);

  const [form] = Form.useForm();

  const { RangePicker } = DatePicker;

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/labelnames`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setUserIds(res.data);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectArtirsts) {
      authGetData({
        url: `api/v1/overview/filter/releases?UserId=${selectArtirsts}`,
        setLoading: setLoadingAlbum,
        onSuccess: (res) => {
          if (res.statusCode === 201) {
            setAlbumName(res.data);
          }
        },
      });
    }
  }, [selectArtirsts]);

  useEffect(() => {
    const endDate = moment.utc();
    const startDate = moment.utc().subtract(1, "year");

    form.setFieldsValue({
      time: [startDate, endDate],
      UserId: null,
      releaseId: null,
    });
  }, [form]);

  const handleClick = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
      startDate: values?.time[0] ? moment(values?.time[0]) : null,
      endDate: values?.time[1] ? moment(values?.time[1]) : null,
    })
  };

  // Xử lý xóa bộ lọc
  const handleReset = () => {
    const endDate = moment.utc();
    const startDate = moment.utc().subtract(1, "year");

    form.resetFields();
    form.setFieldsValue({
      time: [startDate, endDate],
      UserId: null,
      releaseId: null,
    });
    setFilterConditions({
      startDate,
      endDate,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    })
  };

  const handleChangeArtist = (value) => {
    setSelectArtirsts(value);
    if (!value) {
      setAlbumName([]);
    }
    form.setFieldsValue({
      releaseId: null,
    });
  };

  return (
    <>
      <Styled>
        <Form
          form={form}
          onFinish={handleClick}
        >
          <Row gutter={[20, 20]}>
            <Col xl={9} lg={13} md={24}>
              <Form.Item label={t('Payment time')} name="time">
                <RangePicker
                  locale={i18n.language === 'vi' ? localeVi : localeEn}
                  format="DD/MM/YYYY"
                  disabledDate={disabledDateTime}
                />
              </Form.Item>
            </Col>
            <Col xl={8} lg={11} md={12}>
              <Form.Item label={t('Labels')} name="UserId">
                <Select
                  placeholder={t('All')}
                  showSearch
                  showArrow={true}
                  allowClear={true}
                  loading={loading}
                  onChange={handleChangeArtist}
                  style={{ width: "100%" }}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {userIds?.length
                    ? userIds.map((item) => (
                      <Option key={item.userId} value={item.userId}>
                        {item.labelName}
                      </Option>
                    ))
                    : undefined}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={7} lg={13} md={12}>
              <Form.Item label={t('Releases')} name="releaseId">
              <Select
                  placeholder={t('All')}
                  showSearch
                  showArrow={true}
                  allowClear={true}
                  loading={loading}
                  style={{ width: "100%" }}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {albumName?.length
                    ? albumName.map((item) => (
                      <Option key={item.releaseId} value={item.releaseId}>
                        {item.releaseTitle}
                      </Option>
                    ))
                    : undefined}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={24} lg={11} md={24}>
              <div className="action-buttons">
                <Button onClick={handleReset}>{t('Clear all')}</Button>
                <Button
                  type="primary"
                  key="apply-filter"
                  htmlType="submit"
                  loading={loading}
                >
                  {t('Search')}
                </Button>
              </div>
            </Col>

          </Row>
        </Form>
      </Styled>
    </>
  );
}

const Styled = styled.div`
.ant-form {
  background-color: #fdfdfd;
  margin: 0 20px;
  padding: 20px 16px;
  border-radius: 8px;
}
  .title p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .ant-collapse-header {
    display: none !important;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    line-height: 20px;
    color: #7f8183;
  }
  .ant-select-selector {
    background: #f0f2f5 !important;
    border-radius: 4px !important;
  }
  .ant-select-arrow {
    color: #323232 !important;
  }
  .action-buttons {
    display: flex;
    justify-content: end;
    margin-top: 0;
  }
  .ant-form-item{
    margin: 0 !important;
  }
  .ant-col {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-form-item-control {
    overflow: hidden;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: start;
    }
  }

  @media (max-width: 992px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;

export default PaymentHistoryFilter;
