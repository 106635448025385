import { Empty } from "antd";
import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import styled from "styled-components";
function OverViewRevenue() {
  const {t} = useTranslation();
  const userRevenue = useSelector((state) => state.date.userRevenue);

  const maxRevenue =
    userRevenue !== null &&
    userRevenue.revenues.map((item) => {
      return item.netRevenue;
    });

  const findMax =
    maxRevenue &&
    maxRevenue.reduce((max, item) => {
      return max > item ? max : item;
    }, 0);

  const monthNames = [
    t('Jan'),
    t('Feb'),
    t('Mar'),
    t('Apr'),
    t('May'),
    t('Jun'),
    t('Jul'),
    t('Aug'),
    t('Sep'),
    t('Oct'),
    t('Nov'),
    t('Dec'),
  ];

  const data =
    userRevenue &&
    [...userRevenue.revenues].reverse().map((item) => {
      return {
        name: `${monthNames[new Date(item.saleMonth).getMonth()]}${new Date(
          item.saleMonth
        ).getFullYear()}`,

        Total: Number(item.netRevenue.toFixed(3)),
      };
    });

  const labelStyle = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#1E1E1F",
    top: "10px",
    display: "flex",
    left: "15px",
  };

  const CustomTooltip = useCallback(
    (props) => {
      // setHover(props);
      // payload[0] doesn't exist when tooltip isn't visible

      if (props.payload?.length > 0 && props.payload != null) {
        // mutating props directly is against react's conventions
        // so we create a new payload with the name and value fields set to what we want

        const newPayload = [
          {
            name: t('Total'),
            value: props.payload[0].payload.Total.toString().replace(
              /(\d)(?=(\d\d\d)+(?!\d))/g,
              "$1,"
            ),
          },
        ];

        // we render the default, but with our overridden payload

        return <DefaultTooltipContent {...props} payload={newPayload} />;
      }

      // we just render the default

      return <DefaultTooltipContent {...props} />;
    },
    [t]
  );

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
        >
          <tspan x="14" y="-12">
            {payload.value.slice(0, 3)}
          </tspan>
          <tspan x="16" y="20">
            {payload.value.slice(3)}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <div className="overview-bar-chart">
      <div className="overview-bar-chart-container">
        {userRevenue && userRevenue.revenues.length > 0 ? (
          <div className="overview-bar-chart-beside">
            <h1>{t('Monthly revenue')}</h1>
            <p>
              {t('Total')}:{" "}
              {Number(userRevenue.totalNetRevenue.toFixed(3))
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
            </p>
            <ResponsiveContainerStyled>
              <BarChart
                width={792}
                height={368}
                data={data}
                margin={{
                  top: 60,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={20}
              >
                <XAxis
                  dataKey="name"
                  padding={{ left: 10, right: 10 }}
                  tickLine={false}
                  tick={<CustomizedAxisTick />}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  domain={[0, Math.ceil(findMax)]}
                  tickFormatter={(item) =>
                    item.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                  }
                />
                <Tooltip
                  offset={10}
                  itemStyle={{ backgroundColor: "" }}
                  wrapperClassName="overview-bar-chart-tooltip"
                  contentStyle={{
                    borderRadius: "4px",
                    padding: "30px 15px 15px 30px",
                    width: "200px",
                    boxShadow: "0px 2px 17px rgba(0, 0, 0, 0.16)",
                    border: "none",
                  }}
                  labelStyle={labelStyle}
                  content={<CustomTooltip />}
                />
                <Legend verticalAlign="top" height={36} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="Total" fill="#3399FF" />
              </BarChart>
            </ResponsiveContainerStyled>
          </div>
        ) : (
          <div className="overview-bar-chart-empty">
            <h1>{t('Highest Revenue')}</h1>
            <Empty description={t('No Records Found')} />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(OverViewRevenue);

const ResponsiveContainerStyled = styled(ResponsiveContainer)`
  width: 100% !important;
  height: 100% !important;

  .recharts-default-legend {
    display: none;
  }

  @media (max-width: 980px) {
    height: 320px !important;
  }
`;