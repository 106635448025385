import { Empty } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

function OverViewDetailTopStream() {
  const {t} = useTranslation();
  const platformData = useSelector((state) => state.date.platformData);

  const datas =
    platformData !== null &&
    platformData.topPlatforms.map((item) => {
      return {
        name: item.platformName,
        value: item.platformValue,
        number: item.platformValue,
      };
    });

  const newData = datas && [
    ...datas,
  ];

  const COLORS = [
    "#3399ff",
    "#c882f1",
    "#ff69b6",
    "#ff7766",
    "#ffa600",
    "#65789B",
  ];

  const renderLegend = (props) => {
    const { payload } = props;
    
    return (
      <ul style={{ listStyle: "unset" }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: `${entry.color}`,
            }}
          >
            {entry.value}: {entry.payload.number
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
            ({(index + 1 != payload.length || payload.length == 1) ? (entry.payload.percent * 100).toFixed(1) : platformData.topPlatforms[platformData.topPlatforms.length - 1].platformPercent}%)
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
            fontSize: "11px",
          }}
        >
          <label>
            {payload[0].name} : {payload[0].value
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </label>
        </div>
      )
    };
    return null;
  };

  return (
    <div className="overview-detail-top-stream">
      {platformData !== null && platformData.topPlatforms.length > 0 ? (
        <div className="overview-detail-top-stream-container">
          <h1>{t('Top stream/download [By Stores]')}</h1>
          <div style={{ width: '100%' }}>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart
                style={{ display: 'flex', flexDirection: 'row', width: "100%" }}
              >
                <Pie
                  width="55%"
                  data={newData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius="75%" // sizeOfPieChart
                  fill="#8884d8"
                  dataKey="value"
                >
                  {newData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  width="40%"
                  align="right"
                  verticalAlign="middle"
                  layout="vertical"
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="overview-detail-top-stream-container-empty">
          <h1>{t('Top stream/download [By Stores]')}</h1>
          <Empty description={t('No Records Found')} />
        </div>
      )}
    </div>
  );
}

export default OverViewDetailTopStream;
