import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { METHOD_POST, STATUS_CODE_200 } from "@utils/constants";
import { alertMessage, getErrorMessage } from "@utils/function";
import { authPostData } from "@utils/request";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
} from "antd";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import moment from "moment";
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
function ModalCreateOrUpdateSpin({
  closeModal,
  currentRecord,
  act,
  mappingAction,
  exRateData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [listPaidSpins, setListPaidSpins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gameRule, setGameRule] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [gameRuleEn, setGameRuleEn] = React.useState(() =>
    EditorState.createEmpty()
  );
  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (currentRecord && form) {
      currentRecord?.prizes?.forEach((_record, index) => {
        if (!_record?.isNoPrize) {
          setListPaidSpins((prevList) => [...prevList, index]);
        }
      });

      if (currentRecord?.gameRules) {
        const _gameRule = EditorState.createWithContent(
          convertFromHTML(currentRecord?.gameRules)
        );
        setGameRule(_gameRule);
      }

      if (currentRecord?.gameRulesEn) {
        const _gameRuleEn = EditorState.createWithContent(
          convertFromHTML(currentRecord?.gameRulesEn)
        );
        setGameRuleEn(_gameRuleEn);
      }
      form.setFieldsValue({
        ...currentRecord,
        listPrize: currentRecord?.prizes || [],
        time: [
          moment(currentRecord?.startDate),
          moment(currentRecord?.endDate),
        ],
      });
    }
  }, [currentRecord]);

  const handleCloseModal = () => {
    form.resetFields();
    closeModal();
  };

  const onFinish = (values) => {
    const convertGameRule = convertToHTML(gameRule.getCurrentContent());
    const convertGameRuleEn = convertToHTML(gameRuleEn.getCurrentContent());

    const formData = {
      ...values,
      startDate: moment(values.time[0]).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(values.time[1]).format("YYYY-MM-DD HH:mm:ss"),
      gameRule: convertGameRule,
      gameRuleEn: convertGameRuleEn,
      listPrize: values.listPrize.map((prize, index) => ({
        ...prize,
        isNotPrize: !listPaidSpins.includes(index),
      })),
    };
    delete formData.time;
    if (act === mappingAction.create) {
      authPostData({
        url: "api/mobile/v1/spin/create-spin",
        method: METHOD_POST,
        payload: formData,
        setLoading,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === STATUS_CODE_200) {
            alertMessage("success", t("Success"), t("Add successfully"));
            handleCloseModal();
          } else {
            alertMessage(
              "error",
              t("Error"),
              res.message
            );
            // getErrorMessage(res, form);
          }
        },
      });
    } else {
      formData.spinWheelId = currentRecord?.id;
      authPostData({
        url: "api/mobile/v1/spin/update",
        method: METHOD_POST,
        payload: formData,
        setLoading,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === STATUS_CODE_200) {
            alertMessage("success", t("Success"), t("Update successful"));
            handleCloseModal();
          } else {
            alertMessage(
              "error",
              t("Error"),
              res.message
            );
            // getErrorMessage(res, form);
          }
        },
      });
    }
  };

  const handlePaidSpin = (key) => {
    const index = listPaidSpins.indexOf(key);
    if (index !== -1) {
      const updatedList = [...listPaidSpins];
      updatedList.splice(index, 1);
      setListPaidSpins(updatedList);
    } else {
      setListPaidSpins([...listPaidSpins, key]);
    }
  };

  const handleWrapClick = useCallback((evt) => {
    evt.stopPropagation();
  }, []);

  const layout = {
    labelCol: { xl: 8, lg: 12, md: 12 },
    wrapperCol: { xl: 15, lg: 12, md: 12 },
  };

  return (
    <Fragment>
      <Modal
        wrapProps={{ onClick: handleWrapClick }}
        title={
          act === mappingAction.create ? t("Create spin") : t("Update spin")
        }
        open
        onCancel={handleCloseModal}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
        width={"80%"}
        className="modal-create-of-update-spin"
        footer={[
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            form="create-of-update-spin"
          >
            {act === mappingAction.create ? t("Add") : t("Update")}
          </Button>,
        ]}
      >
        <ContentStyled>
          <Spin spinning={loading}>
            {/* <h2>{t("Thiết lập vòng quay")}</h2> */}
            <Form
              {...layout}
              name="create-of-update-spin"
              style={{ width: "100%" }}
              onFinish={onFinish}
              form={form}
            >
              <Form.Item
                name="name"
                label={t("Vietnamese name")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter"),
                  },
                ]}
                labelAlign="left"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="nameEn"
                label={t("English name")}
                rules={[{ required: true, message: t("Please enter") }]}
                labelAlign="left"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="subName"
                label={t("Vietnamese subtitle name")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter"),
                  },
                ]}
                labelAlign="left"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="subNameEn"
                label={t("English subtitle")}
                rules={[{ required: true, message: t("Please enter") }]}
                labelAlign="left"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="time"
                label={t("Time")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter"),
                  },
                ]}
                labelAlign="left"
              >
                <RangePicker
                  className="spin-ant-picker"
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>

              <Form.List
                className="spin-prize-list"
                name="listPrize"
                initialValue={[
                  {
                    isNoPrize: true,
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Card
                        className={name === 0 ? `mt-20` : ""}
                        size="small"
                        title={`${t("Prize")} ${name + 1}`}
                        key={key}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label={t("Vietnamese prize name")}
                          {...restField}
                          labelAlign="left"
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: t("Please enter"),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label={t("English prize name")}
                          {...restField}
                          labelAlign="left"
                          name={[name, "nameEn"]}
                          rules={[
                            {
                              required: true,
                              message: t("Please enter"),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item>
                          <Checkbox
                            checked={listPaidSpins.some(
                              (paid) => paid === name
                            )}
                            onChange={() => handlePaidSpin(key)}
                          >
                            {t("There are rewards")}
                          </Checkbox>
                        </Form.Item>
                        {listPaidSpins.some((item) => item === key) && (
                          <>
                            <Form.Item
                              label={t("Value of Vietnamese award")}
                              {...restField}
                              labelAlign="left"
                              name={[name, "prizeValue"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter"),
                                },
                              ]}
                            >
                              <InputNumber
                                width={"100%"}
                                formatter={(value) =>
                                  value
                                    .toString()
                                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                                }
                                addonAfter="đ"
                                onChange={(vale) => {
                                  if (exRateData) {
                                    // Lấy tỷ giá từ dữ liệu
                                    const exchangeRate = parseFloat(
                                      exRateData.value
                                    );

                                    // Chuyển đổi giá trị tiền tệ
                                    const prizeValueInUSD = (
                                      vale / exchangeRate
                                    )
                                      .toFixed(2)
                                      .toString();

                                    const _listPrize =
                                      form.getFieldsValue()?.listPrize;
                                    const updatedData = _listPrize.map(
                                      (item, index) => {
                                        if (index === key) {
                                          return {
                                            ...item,
                                            prizeValueEn: prizeValueInUSD,
                                          };
                                        }
                                        return item;
                                      }
                                    );
                                    form.setFieldValue(
                                      "listPrize",
                                      updatedData
                                    );
                                  }
                                }}
                                min={0}
                              />
                            </Form.Item>

                            <Form.Item
                              label={t("Value of English award")}
                              {...restField}
                              labelAlign="left"
                              name={[name, "prizeValueEn"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter"),
                                },
                              ]}
                            >
                              <InputNumber
                                width={"100%"}
                                formatter={(value) =>
                                  value
                                    .toString()
                                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                                }
                                addonAfter="€"
                                min={0}
                              />
                            </Form.Item>

                            <Form.Item
                              label={t("Total number of revolutions")}
                              {...restField}
                              labelAlign="left"
                              name={[name, "defaultRemainingCount"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter"),
                                },
                              ]}
                            >
                              <InputNumber
                                onChange={(e) => {
                                  const _listPrize =
                                    form.getFieldsValue()?.listPrize;
                                  const updatedData = _listPrize.map(
                                    (item, index) => {
                                      if (index === key) {
                                        return {
                                          ...item,
                                          remainingCount: e,
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                  form.setFieldValue("listPrize", updatedData);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label={t("Number of revolutions remaining")}
                              {...restField}
                              labelAlign="left"
                              name={[name, "remainingCount"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "The remaining number of revolutions must be less than or equal to the total number of revolutions"
                                  ),
                                  validator: async (_, value) => {
                                    const total =
                                      form.getFieldValue("listPrize")?.[name]
                                        ?.defaultRemainingCount;
                                    if (+value <= +total) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        t(
                                          "The remaining number of revolutions must be less than or equal to the total number of revolutions"
                                        )
                                      )
                                    );
                                  },
                                },
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                          </>
                        )}
                      </Card>
                    ))}

                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("More prizes")}
                    </Button>
                  </>
                )}
              </Form.List>
              <Form.Item label={t("Vietnamese game rules")} labelAlign="left">
                <Editor
                  editorState={gameRule}
                  onEditorStateChange={setGameRule}
                  wrapperClassName="draft-wrapper-class"
                  editorClassName="draft-editor-class"
                  toolbarClassName="draft-toolbar-class"
                />
              </Form.Item>
              <Form.Item label={t("English game rules")} labelAlign="left">
                <Editor
                  editorState={gameRuleEn}
                  onEditorStateChange={setGameRuleEn}
                  wrapperClassName="draft-wrapper-class"
                  editorClassName="draft-editor-class"
                  toolbarClassName="draft-toolbar-class"
                />
              </Form.Item>
            </Form>
          </Spin>
        </ContentStyled>
      </Modal>
    </Fragment>
  );
}

export default memo(ModalCreateOrUpdateSpin);

const ContentStyled = styled.div`
  .mt-20 {
    margin-top: 20px;
  }
  .ant-input-number-group-wrapper {
    width: 100%;
  }
  .spin-ant-picker {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }

  @media (max-width: 992px) {
    .ant-input {
      height: 44px;
    }
  }

  .ant-form-item-label > label {
    display: block;
    white-space: normal;
  }
`;
