import Info from "./components/Info";
import Password from "./components/Password";
import { Tabs } from "antd";
import { authGetData } from "@utils/request";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture } from "@utils/function";

const { TabPane } = Tabs;

const ProfileMain = () => {
  const { t } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    authGetData({
      url: `api/v1/update/Profile?culture=${culture}`,
      method: "GET",
      setLoading,
      onSuccess: (res) => {
        setData(res);
      },
    });
  }, [savedLanguage]);


  return (
    <>
      <ContentStyled>
        <div className="content">
          <Tabs defaultActiveKey="1">
            <TabPane tab={t('Personal information')} key="1">
              <Info userData={data} />
            </TabPane>
            <TabPane tab={t('Change Password')} key="2">
              <Password userData={data} />
            </TabPane>
          </Tabs>
        </div>
      </ContentStyled>
      {/* </div> */}
    </>
  );
};

export default ProfileMain;

const ContentStyled = styled.div`
  padding: 20px;
  .content {
    background-color: #fdfdfd;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 0px 20px;
    .ant-tabs-tab-btn {
      font-weight: 500 !important;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 20px !important;
  }
  button {
    margin-bottom: 20px;
  }

  @media (max-width: 980px) {
    padding: 20px;
  }
`;
