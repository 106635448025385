import RevenueIcon from "@assets/icon/RevenueIcon";
import StreamIcon from "@assets/icon/StreamIcon";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function OverViewDetailTotalStream({ detailTotalLoading }) {
    const { t } = useTranslation();
    const totalStream = useSelector((state) => state.date.totalStream);
    const previousTotalStream = useSelector(
        (state) => state.date.previousTotalStream
    );
    const [percentageStream, setPerStream] = useState();

    useEffect(() => {
        if (previousTotalStream !== 0 && totalStream !== 0) {
            setPerStream(
                ((totalStream - previousTotalStream) / previousTotalStream) * 100
            );
        } else if (totalStream === 0) {
            setPerStream(0);
        } else {
            setPerStream(100);
        }
    }, [previousTotalStream, totalStream]);

    const total = [
        {
            id: 2,
            title: t('Total Stream/Download'),
            number: `${totalStream
                ? totalStream.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                : "0"
                }`,
            percentage
                : percentageStream === 100 ? ""
                    : percentageStream ? `${Number(percentageStream.toFixed(2))}%`
                        : "0%",
            time: percentageStream === 100 ? "" : t('In the same period last year'),
            icon: <RevenueIcon />,
        },
    ];

    return (
        <>
            <div className="overview-total">
                <div className="overview-total-item ">
                    <div className="overview-total-item-beside">
                        <h1>{total[0].title}</h1>
                        <p>{total[0].number}</p>
                        {totalStream - previousTotalStream < 0 ? (
                            <span style={{ color: "red" }}>{total[0].percentage}</span>
                        ) : (
                            <span style={{ color: "#48bb78" }}>{total[0].percentage}</span>
                        )}
                        <span>{total[0].time}</span>
                    </div>
                    {total[0].icon}
                </div>
            </div>
        </>
    );
}

export default memo(OverViewDetailTotalStream);
