import {
  buildQueryString,
  mapLanguageToCulture,
  disabledDateMonth,
  removeAccents,
  mapCurrencyToCulture,
} from "@utils/function";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { authGetData } from "@utils/request";
import { Button, Form, Select, Row, Col, DatePicker } from "antd";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import styled from "styled-components";
import {
  setCountryData,
  setPlatformData,
  setPreviousTotalRevenue,
  setPreviousTotalStream,
  setReleaseData,
  setStreamPlatForm,
  setTotalRevenue,
  setTotalStream,
  setTrackData,
  setUserRevenue,
  setValueArtist,
  setReleaseId,
  setTrackId,
  setIncomeData,
  setTotalGrossRevenue,
  setPreviousGrossRevenue,
  setArtistsData,
} from "../../redux/Date/DateReducer";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const FilterInput = ({
  setDetailTotalLoading,
  setDetailRevenueLoading,
  setRevenueLoading,
  setApplicationLoading,
  valueSort,
  revenueSort,
  queryRevenuePlatform,
  setQueryRevenuePlatform
}) => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const currency = mapCurrencyToCulture();
  const { RangePicker } = DatePicker;
  const [releases, setReleases] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [dates, setDates] = useState();
  const dispatch = useDispatch();

  const valueArtist = useSelector((state) => state.date.valueArtist);
  const releaseId = useSelector((state) => state.date.releaseId);
  const valueOwner = false;

  const [searchParams, setSearchParams] = useSearchParams();
  const [queryConditions, setQueryConditions] = useState({})
  const [queryRevenues, setQueryRevenues] = useState({})

  const [form] = Form.useForm();

  useEffect(() => {
    authGetData({
      url: `api/v1/update/reportingmonth`,
      setLoading: () => { },
      onSuccess: (res) => {
        if (res) {
          setDates(res);
          setQueryConditions({
            StartDate: moment(new Date(res)).startOf("month").format("MM/YYYY"),
            EndDate: moment(new Date(res)).format("MM/YYYY"),
            UserId: "",
            ReleaseId: "",
            TrackId: "",
            culture: culture,
          });
          setQueryRevenues({
            StartDate: moment(new Date(res)).subtract(11, 'months').startOf("month").format("MM/YYYY"),
            EndDate: moment(new Date(res)).format("MM/YYYY"),
            UserId: "",
            ReleaseId: "",
            TrackId: "",
            culture: culture,
          })
          setQueryRevenuePlatform({
            StartDate: moment(new Date(res)).startOf("month").format("MM/YYYY"),
            EndDate: moment(new Date(res)).format("MM/YYYY"),
            UserId: "",
            ReleaseId: "",
            TrackId: "",
            SortEnum: valueSort ? valueSort : "1",
            TopRevenueOrView: revenueSort ? revenueSort : "1",
            culture: culture,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("queryRevenue", queryRevenue);

  useEffect(() => {
    const endDate = moment(dates);
    const startDate = moment(dates).startOf("month");

    form.setFieldsValue({
      filterDate: [startDate, endDate],
      UserId: null,
      ReleaseId: null,
      TrackId: null,
    });

  }, [dates, form]);

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/labelnames`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setUserIds(res.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (releaseId) {
      authGetData({
        url: `api/v1/overview/filter/tracks?ReleaseId=${releaseId}`,
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === 201) {
            setTracks(res.data);
          }
        },
      });
    }
  }, [releaseId]);

  useEffect(() => {
    if (valueArtist) {
      authGetData({
        url: `api/v1/overview/filter/releases?UserId=${valueArtist}`,
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === 201) {
            setReleases(res.data);
          }
        },
      });
    }
  }, [valueArtist]);

  useEffect(() => {
    dispatch(setReleaseId(searchParams.get("ReleaseId")));
    dispatch(setTrackId(searchParams.get("TrackId")));
  }, [dispatch, searchParams]);

  const handleChangeArtist = (value) => {
    setValueArtist(value);
    if (!value) {
      setReleases([]);
      setTracks([]);
    }
    dispatch(setValueArtist(value));
    dispatch(setReleaseId(""));
    dispatch(setTrackId(""));
    form.setFieldsValue({
      ReleaseId: null,
      TrackId: null,
    });
  };

  useEffect(() => {
    dispatch(setValueArtist(""));
    dispatch(setReleaseId(""));
    dispatch(setTrackId(""));
  }, [dispatch, searchParams]);

  const handleChangeRelease = (value) => {
    setReleaseId(value);
    if (value === "") {
      setTracks([]);
    }
    dispatch(setReleaseId(value));
    dispatch(setTrackId(""));
    form.setFieldsValue({
      TrackId: null,
    });
  };

  const handleChangeTrack = (value) => {
    dispatch(setTrackId(value));
  };

  const handleClick = (values) => {
    setQueryConditions({
      StartDate: moment(values?.filterDate[0]).format("MM/YYYY"),
      EndDate: moment(values?.filterDate[1]).format("MM/YYYY"),
      UserId: values.UserId,
      ReleaseId: values.ReleaseId,
      TrackId: values.TrackId,
      culture: culture,
    });

    setQueryRevenues({
      StartDate: moment(values?.filterDate[1]).diff(values?.filterDate[0], 'years') < 1
        ? moment(values?.filterDate[1]).subtract(11, 'months').startOf("month").format("MM/YYYY")
        : moment(values?.filterDate[0]).format("MM/YYYY"),
      EndDate: moment(values?.filterDate[1]).format("MM/YYYY"),
      UserId: values.UserId,
      ReleaseId: values.ReleaseId,
      TrackId: values.TrackId,
      culture: culture,
    })

    setQueryRevenuePlatform({
      StartDate: moment(values?.filterDate[0]).format("MM/YYYY"),
      EndDate: moment(values?.filterDate[1]).format("MM/YYYY"),
      SortEnum: valueSort !== null ? valueSort : "1",
      TopRevenueOrView: revenueSort !== null ? revenueSort : "1",
      UserId: values.UserId,
      ReleaseId: values.ReleaseId,
      TrackId: values.TrackId,
      culture: culture,
    });

    setSearchParams(
      buildQueryString({
        StartDate: moment(values?.filterDate[0]).format("MM/YYYY"),
        EndDate: moment(values?.filterDate[1]).format("MM/YYYY"),
        UserId: values.UserId,
        ReleaseId: values.ReleaseId,
        TrackId: values.TrackId,
        culture: culture,
      })
    );
  };

  const getData = useCallback((culture, currency) => {
    const queryData = { ...queryConditions, culture };
    const query = buildQueryString(queryData);

    authGetData({
      url: `api/v1/overview/platform?${query}`,
      setLoading,
      onSuccess: (res) => {
        dispatch(setPlatformData(res.data));
      },
      currency,
    });

    authGetData({
      url: `api/v1/overview/artists?${query}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          dispatch(setArtistsData(res.data));
        }
      },
      currency,
    });

    authGetData({
      url: `api/v1/overview/track?${query}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          dispatch(setTrackData(res.data));
        }
      },
    });

    authGetData({
      url: `api/v1/overview/release?${query}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          dispatch(setReleaseData(res.data));
        }
      },
      currency,
    });

    authGetData({
      url: `api/v1/overview/summary?${query}`,
      setLoading: setDetailTotalLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          const {
            totalStream,
            totalRevenue,
            totalGrossRevenue,
            previousTotalStream,
            previousTotalRevenue,
            previousGrossRevenue,
          } = res.data;
          dispatch(setTotalStream(totalStream));
          dispatch(setTotalRevenue(totalRevenue));
          dispatch(setTotalGrossRevenue(totalGrossRevenue));
          dispatch(setPreviousTotalStream(previousTotalStream));
          dispatch(setPreviousTotalRevenue(previousTotalRevenue));
          dispatch(setPreviousGrossRevenue(previousGrossRevenue));
        }
      },
      currency,
    });

    authGetData({
      url: `api/v1/overview/country?${query}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          dispatch(setCountryData(res.data));
        }
      },
      currency,
    });

    authGetData({
      url: `api/v1/overview/income?${query}`,
      setLoading: setDetailRevenueLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          dispatch(setIncomeData(res.data));
        }
      },
      currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryConditions, culture, currency]);

  const getDataRevenue = useCallback((culture) => {
    const queryDataRevenue = { ...queryRevenues, culture };
    const queryRevenue = buildQueryString(queryDataRevenue);

    authGetData({
      url: `api/v1/overview/revenue-user?${queryRevenue}`,
      setLoading: setRevenueLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          dispatch(setUserRevenue(res.data));
        }
      },
      currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRevenues, culture, currency])

  const getDataRevenuePlatform = useCallback((culture) => {
    const queryData = { ...queryRevenuePlatform, culture };
    const query = buildQueryString(queryData)

    authGetData({
      url: `api/v1/overview/revenue-stream-platform?${query}`,
      setLoading: setApplicationLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          dispatch(setStreamPlatForm(res.data));
        }
      },
      currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRevenuePlatform, culture, currency])

  useEffect(() => {
    if (Object.keys(queryConditions).length > 0) {
      getData(culture, currency);
    }
  }, [getData, queryConditions, culture, currency]);

  useEffect(() => {
    if (Object.keys(queryRevenues).length > 0) {
      getDataRevenue(culture, currency);
    }
  }, [getDataRevenue, queryRevenues, culture, currency]);

  useEffect(() => {
    if (Object.keys(queryRevenuePlatform).length > 0) {
      getDataRevenuePlatform(culture);
    }
  }, [getDataRevenuePlatform, queryRevenuePlatform, culture, currency]);

  const handleReset = () => {
    const endDate = moment(dates);
    const startDate = moment(dates).startOf("month");
    const EndDate = moment(dates).format("MM/YYYY");
    const StartDate = moment(dates).startOf("month").format("MM/YYYY");
    const StartDateRevenue = moment(dates).subtract(11, 'months').startOf("month").format("MM/YYYY");

    setReleases([]);
    setTracks([]);

    dispatch(setValueArtist(""));

    form.setFieldsValue({
      filterDate: [startDate, endDate],
      UserId: null,
      ReleaseId: null,
      TrackId: null,
    });

    setQueryConditions({
      StartDate,
      EndDate,
      culture: culture,
    });
    setQueryRevenues({
      StartDate: StartDateRevenue,
      EndDate,
      culture: culture,
    });
    setQueryRevenuePlatform({
      StartDate,
      EndDate,
      SortEnum: valueSort !== null ? valueSort : "1",
      TopRevenueOrView: revenueSort !== null ? revenueSort : "1",
      culture: culture,
    });
  }

  return (
    <Styled>
      <Form
        onFinish={handleClick}
        form={form}
        initialValues={{
          UserId: "",
          ReleaseId: "",
          TrackId: "",
        }}
      >
        <Col>
          <Row gutter={[20, 20]}>
            <Col xl={9} lg={12} md={24}>
              <Form.Item layout="vertical" name="filterDate" label={t('Reporting month')} >
                <RangePicker
                  locale={i18n.language === 'vi' ? localeVi : localeEn}
                  format="MM/YYYY"
                  picker="month"
                  disabledDate={disabledDateMonth}
                />
              </Form.Item>
            </Col>
            {!valueOwner && (
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t('Labels')} name="UserId" >
                  <Select
                    placeholder={t('All')}
                    showSearch
                    showArrow={true}
                    allowClear={true}
                    loading={loading}
                    onChange={handleChangeArtist}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {userIds?.length
                      ? userIds.map((item) => (
                        <Option key={item.userId} value={item.userId}>
                          {item.labelName}
                        </Option>
                      ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col xl={7} lg={8} md={12}>
              <Form.Item label={t('Releases')} name="ReleaseId">
                <Select
                  placeholder={t('All')}
                  showSearch
                  showArrow={true}
                  allowClear={true}
                  loading={loading}
                  onChange={handleChangeRelease}
                  style={{ width: "100%" }}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {releases?.length
                    ? releases.map((item) => (
                      <Option key={item.releaseId} value={item.releaseId}>
                        {item.releaseTitle}
                      </Option>
                    ))
                    : undefined}
                </Select>
              </Form.Item>
            </Col>

            <Col xl={9} lg={8} md={12}>
              <Form.Item label={t('Tracks')} name="TrackId" >
                <Select
                  placeholder={t('All')}
                  showSearch
                  showArrow={true}
                  allowClear={true}
                  loading={loading}
                  onChange={handleChangeTrack}
                  style={{ width: "100%" }}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {tracks?.length
                    ? tracks.map((item) => (
                      <Option key={item.trackId} value={item.trackId}>
                        {item.trackTitle}
                      </Option>
                    ))
                    : undefined}
                </Select>
              </Form.Item>
            </Col>

            <Col xl={8} lg={8} md={12}>
              <div className="action-buttons">
                <Button onClick={handleReset}>{t('Clear all')}</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t('Search')}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Form>
    </Styled>
  );
};

export default memo(FilterInput);

const Styled = styled.div`
  .ant-form {
    background-color: #ffffff;
    margin: 0 20px;
    padding: 20px;
    border-radius: 10px;
  }
  .ant-form-item{
    margin: 0 !important;
  }
  .ant-col-8 {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .action-buttons {
    display: flex;
    justify-content: start;
    margin-top: 0;
  }
  .ant-form-item-control {
    overflow: hidden;
  }
`;
