import LoadingComponent from "@components/Loading";
import { Button, DatePicker, Form, Modal } from "antd";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";

export function ModalChooseTime({
  closeModal,
  visible,
  fetchData,
  setIsModalChooseTime,
  timePicker,
}) {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    form.setFieldsValue({ filterDate: timePicker });
  }, [form, visible, timePicker]);

  const handleSubmitData = (values) => {
    fetchData(values, true);
    setIsModalChooseTime(false);
  };

  return (
    <Fragment>
      <Modal
        title={t("Choose a time period")}
        open={visible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="form-add-spin"
          >
            {t("Agree")}
          </Button>,
        ]}
      >
        <LoadingComponent
          children={
            <Form
              form={form}
              name="form-add-spin"
              layout="vertical"
              onFinish={handleSubmitData}
              initialValues={{}}
              children={
                <Fragment>
                  <Form.Item
                    layout="vertical"
                    name="filterDate"
                    rules={[
                      {
                        required: true,
                        message: t("Please select a time period"),
                      },
                    ]}
                  >
                    <RangePicker
                      locale={i18n.language === "vi" ? localeVi : localeEn}
                      format="MM/YYYY"
                      picker="month"
                      style={{ marginBottom: "10px" }}
                    />
                  </Form.Item>
                </Fragment>
              }
            />
          }
        />
      </Modal>
    </Fragment>
  );
}
