import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import SuccessIcon from "../../assets/icon/SuccessIcon";
import Footer from "../../components/Layout/Footer";
import LogoComponent from "../../components/Layout/Logo";

export default function ChangePasswordSuccess() {
  return (
    <div style={{ backgroundColor: "#e5e5e5" }}>
      <div className="login-container">
        <LogoComponent />
        <div className="login-form">
          <div className="form-title">
            <span>Đặt lại mật khẩu</span>
          </div>
          <div className="form-success-icon">
            <div className="form-success-icon-beside">
              <SuccessIcon />
            </div>
          </div>
          <p className="form-password-change-successfully">
            Mật khẩu của bạn đã được đặt lại thành công
          </p>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="full login-button"
          >
            <Link to="/login">Đăng nhập</Link>
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
