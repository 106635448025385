import React from "react";

function RevenueIcon() {
  return (
    <svg
      width="112"
      height="106"
      viewBox="0 0 112 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2942 18.6125L22.5725 13.8992V89.9016L2.12 40.58C-1.33083 31.995 2.79333 22.1475 11.2942 18.6125ZM175.419 49.7542L133.673 150.502C131.063 156.814 124.919 160.686 118.438 160.854C116.25 160.854 113.978 160.517 111.789 159.592L49.7583 133.921C43.4458 131.312 39.5742 125.252 39.4058 118.771C39.3217 116.498 39.7425 114.226 40.6683 112.037L82.415 11.29C85.0242 4.89331 91.2525 1.02165 97.8175 0.937485C100.006 0.937485 102.194 1.35831 104.298 2.19998L166.245 27.8708C174.83 31.4058 178.954 41.2533 175.419 49.7542ZM56.3233 110.354C51.6942 110.354 47.9067 114.142 47.9067 118.771C47.9067 123.4 51.6942 127.187 56.3233 127.187C60.9525 127.187 64.74 123.4 64.74 118.771C64.74 114.142 60.9525 110.354 56.3233 110.354ZM39.49 17.7708C39.49 8.51248 47.065 0.937485 56.3233 0.937485H68.5275L39.49 71.1325V17.7708Z"
        fill="#3399FF"
        fillOpacity="0.2"
        className="myClass"
      />
    </svg>
  );
}

export default RevenueIcon;
