import { disabledDateMonth } from "@utils/function";
import { authGetData } from "@utils/request";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import SelectionLabels from "@components/SelectLabels";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const { Option } = Select;

function PaymentFilter({
  loadingList,
  setFilterConditions,
}) {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [xdates, setxDates] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    authGetData({
      url: `api/v1/update/reportingmonth`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          setxDates(res);
          setFilterConditions({
            startDate: moment(res).startOf("month").format("MM/YYYY"),
            endDate: moment(res).format("MM/YYYY"),
            reportStatus: 2,
            pageIndex: 1,
             pageSize: DEFAULT_PAGESIZE,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const endDate = moment(xdates);
    const startDate = moment(xdates).startOf("month");

    form.setFieldsValue({
      time: [startDate, endDate],
    });
  }, [form, xdates]);


  const handleClick = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
       pageSize: DEFAULT_PAGESIZE,
      startDate: values?.time[0] ? moment(values?.time[0]).format("MM/YYYY") : null,
      endDate: values?.time[1] ? moment(values?.time[1]).format("MM/YYYY") : null,
      stupidLabelName: values?.stupidLabelName && values?.stupidLabelName.length > 0 ? values?.stupidLabelName : null,
      reportStatus: values?.reportStatus ? values?.reportStatus : 2,
    })
  };

  // Xử lý xóa bộ lọc

  const handleReset = () => {
    const endDate = moment(xdates);
    const startDate = moment(xdates).startOf("month");

    form.resetFields();
    form.setFieldsValue({
      time: [startDate, endDate],
      reportStatus: 2
    });
    setFilterConditions({
      startDate,
      endDate,
      reportStatus: 2,
      pageIndex: 1,
       pageSize: DEFAULT_PAGESIZE,
    })
  };

  return (
    <Styled>
      <Form
        form={form}
        onFinish={handleClick}
      >
        <Row gutter={[20, 20]} >
          <Col xl={9} lg={12} md={24}>
            <Form.Item label={t('Reporting month')} name="time">
              <RangePicker
                locale={i18n.language === 'vi' ? localeVi : localeEn}
                format="MM/YYYY"
                picker="month"
                disabledDate={disabledDateMonth}
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={12} md={13}>
            <Form.Item label={t('Labels')} name="userIds">
              <SelectionLabels
                url={`api/v1/overview/filter/labelnames`}
                formKey="userIds"
                form={form}
                mode="multiple"
              />
            </Form.Item>
          </Col>
          <Col xl={7} lg={12} md={11}>
            <Form.Item label={t('Status')} name="reportStatus">
              <Select allowClear defaultValue={2} >
                <Option value={3}>{t('All')}</Option>
                <Option value={2}>{t('Unpaid')}</Option>
                <Option value={1}>{t('Paid')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={24} lg={12} md={24}>
            <div className="action-buttons">
              <Button onClick={handleReset}>{t('Clear all')}</Button>
              <Button type="primary" htmlType="submit" loading={loadingList}>
                {t('Search')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Styled>
  );
}

const Styled = styled.div`
  .ant-form {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    line-height: 20px;
    color: #7f8183;
  }
  .ant-select-selector {
    background: #f0f2f5 !important;
    border-radius: 4px !important;
  }
  .ant-select-arrow {
    color: #323232 !important;
  }
  .ant-form-item{
    margin: 0 !important;
  }
  .action-buttons {
    display: flex;
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: start;
    }
  }

  @media (max-width: 992px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;

export default PaymentFilter;