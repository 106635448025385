import { PlusOutlined, RedoOutlined, SmileOutlined } from "@ant-design/icons";
import { handlePagination, mapLanguageToCulture } from "@utils/function";
import { authDeleteData, authPostData, authGetData } from "@utils/request";
import { Button, Form, Input, Modal, notification } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UserItem from "./UserItem";
import { useNavigate } from "react-router-dom";
import { alertMessage } from "@utils/function";
import { Select, Row, Col } from "antd";
import {
  setValueArtist,
  setValueViewer,
  setValueOwner,
  setReleaseId,
  setTrackId,
  setEndDay,
  setStartDay,
} from "../../../redux/Date/DateReducer";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../../../redux/Auth/authReducer";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import FormItem from "antd/lib/form/FormItem";
import { useTranslation } from "react-i18next";

const UserManagementMain = () => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const payloaded = {
    token: "",
    versioning: "",
    platform: "",
  };

  const checkAdmin = useSelector((state) => state.auth.isAdmin);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
    setPage(paging.current);
  };

  useEffect(() => {
    getData();
  }, [filterConditions, culture]);

  const navigate = useNavigate();

  const getData = useCallback(() => {
    authPostData({
      url: `api/v1/admin/users?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        setData(
          res.data.map((e, index) => ({
            ...e,
            no:
              (filterConditions.pageIndex - 1) * filterConditions.pageSize +
              (index + 1),
          }))
        );
      },
    });
  }, [filterConditions, culture]);

  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/admin/user?UserId=${id}&culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setVisible(false);

          setData((prev) => prev.filter((item) => item.id !== id));
          setInitialData((prev) => prev.filter((item) => item.id !== id));
          getData();
          if (userId == id) {
            dispatch(setStartDay(null));
            dispatch(setEndDay(null));
            dispatch(setValueOwner(false));
            dispatch(setValueArtist(""));
            dispatch(setValueViewer(""));
            dispatch(setReleaseId(""));
            dispatch(setTrackId(""));
            authPostData({
              url: "api/v1/user/logout",
              method: "POST",
              payload: payloaded,
              setLoading: () => {},
              setImportLoading: () => {},
            });
            dispatch(logoutSuccess());
          }
          notification.open({
            message: t("Success"),
            description: t("Delete successful"),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
          navigate("/admin/user-management");
        }
      },
    });
  };

  const handleSyncSpinForArtists = () => {
    const onOk = () => {
      authGetData({
        url: "api/mobile/v1/spin/enable-artist-spin",
        method: "GET",
        payload: data,
        setLoading,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res) {
            alertMessage(
              "success",
              t("Successful"),
              t("Sync spin successfully")
            );
            getData();
          } else {
            alertMessage(
              "error",
              t("Error"),
              t("An error occurred. Please try again later")
            );
          }
        },
      });
    };

    Modal.confirm({
      centered: true,
      width: 600,
      title: t("Confirm?"),
      content: t("Confirm spin sync for all artists?"),
      onOk,
      onCancel() {},
      okText: t("Agree"),
      okButtonProps: { type: "primary" },
      cancelText: t("Cancel"),
    });
  };

  const handleClick = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  const handleReset = () => {
    form.resetFields();
    setFilterConditions({
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  return (
    <>
      <ContentStyled>
        <div className="select-option">
          <Form form={form} onFinish={handleClick}>
            <Row gutter={[20, 20]}>
              <Col xl={7} lg={7} md={12}>
                <FormItem name="users">
                  <Input placeholder={t("Input user name/email")} />
                </FormItem>
              </Col>

              <Col xl={7} lg={7} md={12}>
                <FormItem label={t("Role")} name="isAdmin">
                  <Select defaultValue="">
                    <Select.Option value="">{t("All")}</Select.Option>
                    <Select.Option value="Admin">{t("Admin")}</Select.Option>
                    <Select.Option value="Người dùng">
                      {t("Users")}
                    </Select.Option>
                  </Select>
                </FormItem>
              </Col>
              <Col xl={7} lg={7} md={24}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Account list")}</div>
              <div className="flex-row-act">
                <button
                  type="button"
                  onClick={handleSyncSpinForArtists}
                  className="btn primary-btn btn-sync"
                >
                  {t("Sync spin for artists")} <RedoOutlined />
                </button>
                {checkAdmin && (
                  <Link className="create" to="create-user">
                    <button type="button" className="btn primary-btn">
                      {t("Add")} <PlusOutlined />
                    </button>
                  </Link>
                )}
              </div>
            </div>

            <div className="content">
              <div className="user-list">
                <UserItem
                  onChangePagination={onChangePagination}
                  filterConditions={filterConditions}
                  page={page}
                  users={data}
                  handleDelete={handleDelete}
                  loading={loading}
                  setVisible={setVisible}
                  visible={visible}
                  getData={getData}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentStyled>
    </>
  );
};

export default UserManagementMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 8px;
  }

  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }

  .ant-row {
    align-items: center;
  }

  .ant-form-item {
    margin: 0 !important;
  }
  .ant-select {
    display: block;
  }

  .header {
    box-shadow: inset 0px -0.5px 0px #d9dbdd;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 14px;
      align-self: center;
    }
    .button {
      background-color: #003366;
      border-color: #003366;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      border-radius: 4px;
    }
  }
  .flex-row-act {
    display: flex;
  }
  .btn-sync {
    margin-right: 10px;
  }
  .content {
    .user-list {
      img {
        width: 50px;
        height: 50px;
      }
      .ant-table-row {
        text-align: center;
      }
    }
  }

  @media (max-width: 992px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;
