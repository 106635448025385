import {
  PlusOutlined,
  SmileOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import { handlePagination, mapLanguageToCulture } from "@utils/function";
import { authDeleteData, authPostData, authPutData } from "@utils/request";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import { useSearchParams } from "react-router-dom";
import { getErrorForm } from "@utils/function";
import {
  Button,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Space,
  Input,
  Spin,
  Form,
  Select,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

const VersionMain = () => {
  const { t } = useTranslation();
  const culture = mapLanguageToCulture();
  const cancelTokenSource = useRef();
  const [total, setTotal] = useState();
  const [dataCountryAlbum, setDataCountryAlbum] = useState([]);
  const [load, setLoading] = useState(false);
  const [edittingShareRate, setEdittingShareRate] = useState(false);
  const [newestversion, setnewestversion] = useState(false);
  const [checkPopup, setCheckPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [putid, setputId] = useState();
  const [booleane, setBooleane] = useState();
  const [booleaneactive, setBooleaneactive] = useState();
  const [deleteId, setDeleteId] = useState();
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    layout: "vertical",
  };

  const getData = () => {
    const payload = {
      pageIndex: filterConditions.pageIndex,
      pageSize: filterConditions.pageSize,
    };
    if (typeof cancelTokenSource.current != typeof undefined) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }

    cancelTokenSource.current = axios.CancelToken.source();

    authPostData({
      url: `api/v1/versionweb?culture=${culture}`,
      method: "POST",
      payload,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataCountryAlbum(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
      cancelToken: cancelTokenSource.current,
    });
  };

  const columns = [
    {
      title: t("No."),
      dataIndex: "key",
      align: "center",
      width: "5%",
    },
    {
      title: t("App version"),
      dataIndex: "version",
      width: "10%",
      className: "min-width-100",
    },
    {
      title: t("Devices"),
      dataIndex: "platform",
      render: (text) => (
        <div style={{ textTransform: "uppercase" }}>{text}</div>
      ),
      width: "10%",
      className: "min-width-100",
    },
    {
      title: t("Mandatory"),
      dataIndex: "isRequired",
      width: "10%",
      className: "min-width-100",
      render: (text) => <div>{text ? t("Yes") : t("No")}</div>,
    },

    {
      title: t("Status"),
      dataIndex: "isActive",
      width: "15%",
      className: "min-width-120",
      render: (text) => <div>{text ? t("Active") : t("Inactive")}</div>,
    },

    {
      title: t("Version contents"),
      dataIndex: "note",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
          <div>{text}</div>
        </div>
      ),
      width: "35%",
      className: "min-width-300",
    },
    {
      title: t("Release date"),
      dataIndex: "dateRelease",
      render: (text) => <span>{moment(text).format("DD/MM/YYYY")}</span>,
      width: "10%",
      className: "min-width-120",
    },
    {
      title: t("Custom..."),
      render: (record) => (
        <Space size="middle">
          {record.isEdit && (
            <EditOutlined
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => showEditModal(record)}
            />
          )}
          {record.isTrash && (
            <DeleteOutlined
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => showDeleteConfirm(record)}
            />
          )}
        </Space>
      ),
      align: "center",
      width: "5%",
    },
  ];

  const showEditModal = (record) => {
    setCheckPopup(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setEdit(true);
    setEdittingShareRate(true);
    setputId(record.id);
    setBooleane(record.isRequired);
    setBooleaneactive(record.isActive);
    form.setFieldsValue({
      version: record.version,
      isRequired: `${record.isRequired}`,
      isActive: `${record.isActive}`,
      platform: record.platform,
      note: record.note,
    });
  };

  const showDeleteConfirm = (record) => {
    setDeleteId(record.id);
    setVisible(true);
  };

  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/versionweb/delete/${id}?culture=${culture}`,
      method: "DELETE",
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setVisible(false);
          notification.open({
            message: t("Success"),
            description: t("Delete successful"),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
          getData();
          setLoading(false);
        }
      },
    });
  };

  const handleSubmit = (values) => {
    const { version, platform, isRequired, isActive, note } = values;
    if (edit) {
      const editFormData = {
        id: putid,
        version: version,
        platform: platform,
        isRequired: isRequired,
        isActive: isActive,
        note: note,
      };
      updateShareRate(editFormData, "PUT");
      return;
    } else {
      const createFormData = {
        version: version,
        platform: platform,
        isRequired: isRequired,
        isActive: isActive,
        note: note,
      };
      createShareRate(createFormData, "POST");
      return;
    }
  };

  const createShareRate = (payload, method) => {
    authPostData({
      url: `api/v1/versionweb/create?culture=${culture}`,
      method,
      payload,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          getData();

          notification.open({
            message: t("Success"),
            description: t("Add successfully"),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          setEdit(false);
          setEdittingShareRate(false);
          setCheckPopup(false);

          form.resetFields();
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const [query, setQuery] = useState({
    pageSize: searchParams.get("pageSize") || filterConditions.pageSize,
    pageIndex: searchParams.get("pageIndex") || filterConditions.pageIndex,
  });

  useEffect(() => {
    setSearchParams(query);
    getData();
  }, [query, culture]);

  const updateShareRate = (payload, method) => {
    authPutData({
      url: `api/v1/versionweb/edit?culture=${culture}`,
      method,
      payload,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getData();

          notification.open({
            message: t("Success"),
            description: t("Update successful"),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          setEdittingShareRate(false);
          setEdit(false);
          setCheckPopup(false);
          form.resetFields();
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const ModalWarningChange = () =>
    Modal.confirm({
      centered: true,
      width: 600,
      title: t("Save the change?"),
      content: t(
        "If you move/close tab without saving, the changes you made earlier will be lost"
      ),
      onOk() {
        setEdit(false);
        setEdittingShareRate(false);
        setputId(null);
        setCheckPopup(false);
        form.resetFields();
      },
      onCancel() {
        navigate("/admin/version");
      },
      okText: t("Agree"),
      okButtonProps: { type: "primary" },
      cancelText: t("Cancel"),
    });

  const handleCancel = () => {
    setVisible(false);
    setnewestversion(false);
    if (checkPopup === true) {
      ModalWarningChange();
    }
  };

  const handleQuery = (queryParams) => {
    const new_query = { ...queryParams, ...filterConditions };
    setQuery(new_query);
    setSearchParams(new_query);
  };

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);

    setQuery(() => {
      return { pageIndex: paging.current, pageSize: paging.pageSize };
    });
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Software Version list")}</div>

              <button
                className="btn primary-btn"
                onClick={() => {
                  setCheckPopup(true);
                  setEdittingShareRate(true);
                }}
              >
                {t("Add")} <PlusOutlined />
              </button>
            </div>

            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  defaultCurrent={filterConditions.pageIndex}
                  rowKey="key"
                  dataSource={dataCountryAlbum}
                  columns={columns}
                  totalData={total}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                  handleQuery={handleQuery}
                />
              </ConfigProvider>
            </div>
            <Modal
              title={edit ? t("Version update") : t("Create vesion")}
              open={edittingShareRate}
              onCancel={handleCancel}
              footer={null}
            >
              <Styled>
                <Form {...layout} onFinish={handleSubmit} form={form}>
                  <Form.Item
                    label={t("App version")}
                    rules={[
                      {
                        required: true,
                        message: t("Version name is required"),
                      },
                    ]}
                    name="version"
                  >
                    <Input placeholder={t("Software input vesion")} />
                  </Form.Item>

                  <Form.Item
                    label={t("Devices")}
                    rules={[
                      {
                        required: true,
                        message: t("This field is required"),
                      },
                    ]}
                    name="platform"
                  >
                    <Select placeholder={t("Select devices")}>
                      <Select.Option value="ios">{t("IOS")}</Select.Option>
                      <Select.Option value="android">
                        {t("Android")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={t("Mandatory")}
                    name="isRequired"
                    rules={[
                      {
                        required: true,
                        message: t("This field is required"),
                      },
                    ]}
                  >
                    <Select placeholder={t("Select Mandatory")}>
                      <Select.Option value="true">{t("Yes")}</Select.Option>
                      <Select.Option value="false">{t("No")}</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={t("Status")}
                    name="isActive"
                    rules={[
                      {
                        required: true,
                        message: t("This field is required"),
                      },
                    ]}
                  >
                    <Select placeholder={t("Select status")}>
                      <Select.Option value="true">{t("Active")}</Select.Option>
                      <Select.Option value="false">
                        {t("Inactive")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label={t("Version contents")} name="note">
                    <Input.TextArea
                      placeholder={t("Version contents")}
                      rows="4"
                    />
                  </Form.Item>
                  <div className="btn-action">
                    <Space key="update-album-thumb">
                      <Button htmlType="button" onClick={handleCancel}>
                        {t("Cancel")}
                      </Button>
                      <Button htmlType="submit" type="primary" loading={load}>
                        {edit ? t("Update") : t("Add")}
                      </Button>
                    </Space>
                  </div>
                </Form>
              </Styled>
            </Modal>
            <Modal
              title={t("Please confirm")}
              open={visible}
              onOk={() => handleDelete(deleteId)}
              onCancel={handleCancel}
              footer={[
                <Button
                  type="outline"
                  className="btn outline-btn"
                  key="back"
                  onClick={handleCancel}
                >
                  {t("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={load}
                  className="btn primary-btn"
                  onClick={() => handleDelete(deleteId)}
                >
                  {t("Agree")}
                </Button>,
              ]}
            >
              <p>{t("Are you sure you want to delete ?")}</p>
            </Modal>
          </div>
        </div>
      </ContentStyled>
    </Spin>
  );
};

export default VersionMain;

const ContentStyled = styled.div`
  .select-option {
    padding: 16px;
    padding-bottom: 20px;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        background-color: #fdfdfd !important;
        border: 1px solid #ccc !important;
        line-height: 40px;
      }
    }
  }

  .min-width-160 {
    min-width: 160px;
  }
`;

const Styled = styled.div`
  .btn-action {
    display: flex;
    justify-content: end;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;
