import React, { Fragment, useEffect, useState } from "react";
import { Table, Collapse, ConfigProvider } from "antd";
import styled from "styled-components";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  PAGE_SIZE_OPTIONS,
} from "@utils/constants";
import { useLocation, useSearchParams } from "react-router-dom";
import { parseParams } from "@utils/function";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/locale-provider/vi_VN";
import localeEn from "antd/es/locale-provider/en_US";

const { Panel } = Collapse;

export default memo(function TableComponent(props) {
  const {
    disableGetQueryParams,
    renderExtraAction,
    dataSource = [],
    columns,
    header,
    totalData,
    rowSelection,
    onRow,
    loading,
    onChangePagination,
    pagination,
    scrollX,
    rowKey,
    expandable,
    summary,
    defaultCurrent,
  } = props;
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const [tablePagination, setTablePagination] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (pagination) {
      setTablePagination(pagination);
    }
  }, [pagination]);
  useEffect(() => {
    if (location.search && !disableGetQueryParams) {
      setTablePagination(parseParams(location.search));
    } else {
      setTablePagination({
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      });
    }
  }, [location.search, disableGetQueryParams]);
  return (
    <Fragment>
      <Collapse
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: 18 }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel
          header={header}
          extra={renderExtraAction ? renderExtraAction() : null}
          collapsible={"header"}
          key="1"
        >
          <ConfigProvider locale={i18n.language === "vi" ? localeVi : localeEn}>
            <TableStyled>
              <Table
                defaultCurrent={2}
                loading={loading}
                rowSelection={rowSelection ? rowSelection : null}
                columns={columns}
                bordered={true}
                dataSource={dataSource}
                rowKey={(record) => record[rowKey] ?? record.id}
                scroll={{
                  x: scrollX ? scrollX : true,
                }}
                summary={summary ? summary : null}
                onChange={onChangePagination}
                pagination={
                  pagination
                    ? {
                        total: totalData,
                        showTotal: (total) => `${
                          (tablePagination.pageIndex - 1) *
                            tablePagination.pageSize +
                          1
                        } - 
                         ${
                           tablePagination.pageIndex *
                             tablePagination.pageSize >
                           total
                             ? total
                             : tablePagination.pageIndex *
                               tablePagination.pageSize
                         } / ${total}`,
                        defaultpageSize: DEFAULT_PAGESIZE,
                        defaultCurrent: 1,
                        current: Number(pagination.pageIndex) || defaultCurrent,
                        pageSize: parseInt(tablePagination.pageSize),
                        showSizeChanger: true,
                        showLessItems: true,
                        responsive: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                      }
                    : false
                }
                onRow={onRow ? onRow : null}
                expandable={expandable ? expandable : null}
              />
            </TableStyled>
          </ConfigProvider>
        </Panel>
      </Collapse>
    </Fragment>
  );
});

const TableStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
      font-size: 13px;
    }
  }
`;
