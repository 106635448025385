import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import {
  getErrorForm,
  handlePagination,
  mapLanguageToCulture,
} from "@utils/function";
import { authDeleteData, authPostData, authPutData } from "@utils/request";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import styled from "styled-components";
const { confirm } = Modal;
const Label = ({ pwData, data }) => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const [form] = Form.useForm();
  const [form_label] = Form.useForm();
  const [id, setId] = useState();
  const [load, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [dataLabel, setDataLabel] = useState([]);
  const [total, setTotal] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };

  const getData = () => {
    authPostData({
      url: `api/v1/admin/setting/labelnames?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading: setLoadingList,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataLabel(
            res?.data?.map((e, index) => ({
              ...e,
              no:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
    });
  };
  useEffect(() => {
    getData();
  }, [filterConditions, culture]);

  useEffect(() => {
    if (pwData || data) {
      form.setFieldsValue({
        value: pwData.value,
      });
    }
  }, [form, pwData, data]);

  const onFinishLabelName = (values) => {
    if (edit) {
      const EditForm = {
        sourceLabel: values.sourceLabel,
        targetLabel: values.targetLabel,
        id: id,
      };
      authPutData({
        url: `api/v1/admin/label?culture=${culture}`,
        method: "PUT",
        payload: EditForm,
        setLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            setId();
            getData();
            setEdit((state) => !state);
            form_label.resetFields();
            notification.open({
              message: t("Success"),
              description: t("Update successful"),
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
          } else {
            getErrorForm(res, form_label);
          }
        },
      });
    } else {
      const formData = { ...values };
      authPostData({
        url: `api/v1/admin/label?culture=${culture}`,
        method: "POST",
        payload: formData,
        setLoading: () => {},
        setImportLoading: () => {},
        onSuccess: (res) => {
          form_label.resetFields();
          if (res.statusCode === 200) {
            getData();
            notification.open({
              message: t("Success"),
              description: t("Add successfully"),
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
          } else {
            getErrorForm(res, form_label);
          }
        },
      });
    }
  };
  const columns = [
    {
      title: t("No."),
      dataIndex: "no",
      align: "center",
      width: "5%",
    },
    {
      title: t("Original Label"),
      dataIndex: "sourceLabel",
      align: "left",
      width: "25%",
    },
    {
      title: "",
      align: "left",
      width: "20%",
      render: () => (
        <Space size="middle">
          <ArrowRightOutlined />
        </Space>
      ),
    },
    {
      title: t("Designated Label"),
      dataIndex: "targetLabel",
      align: "left",
      width: "25%",
    },
    {
      title: t("Custom..."),
      render: (item) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onEdit(item)}
          />
          <DeleteOutlined
            style={{ color: "red", marginLeft: 12, cursor: "pointer" }}
            onClick={() => showDeleteConfirm(item.id)}
          />
        </Space>
      ),
      width: "25%",
    },
  ];
  const showDeleteConfirm = (id) => {
    setDeleteId(id);
    setVisible(true);
  };
  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/admin/label/${id}?culture=${culture}`,
      setLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getData();
          setVisible(false);
          notification.open({
            message: t("Success"),
            description: t("Delete successful"),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
        }
      },
    });
  };
  const onEdit = (item) => {
    setEdit(true);
    setId(item.id);
    form_label.setFieldsValue({
      sourceLabel: item.sourceLabel,
      targetLabel: item.targetLabel,
    });
  };

  const handleChangeFields = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form_label.getFieldError(field);
      if (!error.length) {
        return;
      }
      form_label.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onCancelEdit = () => {
    setEdit(false);
    setId(null);
    form_label.resetFields();
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div>
          <h2>{t("Set up Label")}</h2>
          <Form
            form={form_label}
            onFinish={onFinishLabelName}
            layout="inline"
            onValuesChange={handleChangeFields}
          >
            <Row gutter={[20, 20]}>
              <Col xl={8} lg={12} md={24}>
                <Form.Item
                  label={t("Original Label")}
                  name="sourceLabel"
                  rules={[
                    {
                      required: true,
                      message: t("Original Label is required"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={8} lg={12} md={24}>
                <Form.Item
                  label={t("Designated Label")}
                  name="targetLabel"
                  rules={[
                    {
                      required: true,
                      message: t("Designated Label is required"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={8} lg={24} md={24}>
                <Form.Item>
                  <div className="action-buttons">
                    {!edit ? (
                      <Button htmlType="submit" type="primary">
                        {t("Set up")}
                      </Button>
                    ) : (
                      <Space>
                        <Button htmlType="submit" type="primary">
                          {t("Update")}
                        </Button>
                        <Button type="outline" onClick={onCancelEdit}>
                          {t("Cancel")}
                        </Button>
                      </Space>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {dataLabel.length > 0 && (
            <TableComponent
              rowKey="key"
              dataSource={dataLabel}
              columns={columns}
              totalData={total}
              loading={loadingList}
              onChangePagination={onChangePagination}
              pagination={filterConditions}
            />
          )}
        </div>
        <Modal
          key="setting"
          title={t("Please confirm")}
          open={visible}
          onOk={() => handleDelete(deleteId)}
          onCancel={handleCancel}
          footer={[
            <button
              type="button"
              className="btn outline-btn"
              key="back"
              onClick={handleCancel}
            >
              {t("Cancel")}
            </button>,
            <button
              key="submit"
              type="submit"
              className="btn primary-btn"
              // loading={loading}

              onClick={() => handleDelete(deleteId)}
            >
              {t("Agree")}
            </button>,
          ]}
        >
          <p>{t("Are you sure you want to delete ?")}</p>
        </Modal>
      </ContentStyled>
    </Spin>
  );
};

export default memo(Label);

const ContentStyled = styled.div`
  .button-edit {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .ant-row {
    width: 100%;
    flex-flow: inherit;
  }
  .ant-form-inline {
    display: flex;
    /* justify-content: flex-start; */
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-table-row {
    text-align: center;
  }
  .action-buttons {
    margin-top: 0;
    justify-content: start;
  }
  .ant-col {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-form-item-control {
    overflow: hidden;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;
