import { CrownOutlined, CustomerServiceOutlined, LoadingOutlined, RiseOutlined, ShareAltOutlined, SmileOutlined } from "@ant-design/icons";
import { Select, Spin, Tabs, Tooltip, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import moment from "moment";
import { getData, postData } from "@utils/request";
import { BASE_API_URL, } from "@utils/constants";
import { ModalChooseTime } from "./ModalChooseTime";

const payloaded = {
  startDate: null,
  endDate: null,
  months: 1,
};

const OverViewLandingPage = () => {
  const { t, i18n } = useTranslation();
  const options = useMemo(() => {
    return [
      {
        value: 1,
        label: t('Last month')
      },
      {
        value: 3,
        label: t('Last 3 months')
      },
      {
        value: 12,
        label: t('Last year')
      },
      {
        value: "all",
        label: t('All times')
      },
      {
        value: "custom",
        label: t('Custom')
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, i18n.language])

  const [isModalChooseTime, setIsModalChooseTime] = useState(false);
  const [selectedTime, setSelectedTime] = useState(1);
  const [timePicker, setTimePicker] = useState(null);
  const [optionsTime, setOptionsTime] = useState(options);
  const [timeUpdateTop, setTimeUpdateTop] = useState('');
  const [loading, setLoading] = useState(false)
  const [topRelease, setTopRelease] = useState(null)
  const { Option } = Select;

  useEffect(() => {
    const optionsClone = JSON.parse(JSON.stringify(options));
    setOptionsTime((pre) => {
      if (selectedTime === 'custom') {
        optionsClone[4].label = pre[4].label
      }
      return optionsClone
    })
  }, [i18n.language, options, selectedTime]);

  useEffect(() => {
    postData({
      url: BASE_API_URL + `api/mobile/v1/release/top-release`,
      method: 'POST',
      payload: payloaded,
      setLoading: setLoading,
      onSuccess: (res) => {
        setTopRelease(res.data);
      },
    });
    getData({
      url: BASE_API_URL + `api/mobile/v1/updatemobile/reportingmonthstring`,
      setLoading: setLoading,
      onSuccess: (res) => {
        const formatTime = moment(res.data.reportingMonth, 'DD/MM/YYYY').format('MM/YYYY')
        console.log(formatTime)
        setTimeUpdateTop(formatTime)
      },
    });
  }, [])

  const format = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(2).replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')}M`;
    }
    if (number >= 1000) {
      return `${(number / 1000).toFixed(0)}K`;
    }
    return number;
  }

  const formatNumber = (number) => {
    return number
      ? number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      : "0"
  }

  const CustomDot = (props) => {
    const { cx, cy, value } = props;

    // Tính toán kích thước và vị trí của hộp chứa văn bản
    const boxWidth = format(value[1]).toString().length * 10; // Tính toán độ rộng của văn bản
    const boxHeight = 20; // Chiều cao của hộp

    return (
      <g>
        <circle cx={cx} cy={cy} r={4} stroke="#fff" strokeWidth={2} fill="black" />
        <rect x={cx - (boxWidth / 2)} y={cy - 25} width={boxWidth} height={boxHeight} fill="#446AA7" rx={3} ry={3} />
        <text x={cx} y={cy - 10} textAnchor="middle" fill="#fff">
          {format(value[1])}
        </text>
      </g>
    );
  };

  const fetchData = (value, fromModal) => {
    let newPayload = {
      startDate: null,
      endDate: null,
      months: value === 'all' ? null : value,
    };

    // Trường hợp "Tùy chọn"

    if (fromModal) {
      setTimePicker(value.filterDate)
      const from = value.filterDate[0].format('MM/YYYY');
      const to = value.filterDate[1].format('MM/YYYY');

      const optionsClone = JSON.parse(JSON.stringify(optionsTime));
      optionsClone[4].label = from + ' - ' + to
      setOptionsTime(optionsClone)
      setSelectedTime('custom')

      newPayload = {
        startDate: from,
        endDate: to,
        months: null,
      }
    }

    postData({
      url: BASE_API_URL + `api/mobile/v1/release/top-release`,
      method: 'POST',
      payload: newPayload,
      setLoading: setLoading,
      onSuccess: (res) => {
        console.log(res.data)
        setTopRelease(res.data);
      },
    });
  }

  const handleChangeTime = (value) => {
    if (value === "custom") {
      setIsModalChooseTime(true)
    } else {
      fetchData(value)
      setSelectedTime(value)

      // Reset Option time

      setTimePicker(null)
      const optionsClone = JSON.parse(JSON.stringify(optionsTime));
      optionsClone[4].label = t('Custom')
      setOptionsTime(optionsClone)
    }
  }

  const CloseModalChooseTime = () => {
    setIsModalChooseTime((pre) => !pre);

  };

  const handleCallBackSelect = (value) => {
    if (value === 'custom') {
      setIsModalChooseTime(true)
    }
  }

  const totalStream = (data) => {
    if (data) {
      const sum = data?.reduce((acc, curr) => {
        return acc + curr.stream
      }, 0)
      return formatNumber(sum)
    }
    return "0"
  }

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      notification.open({
        message: t("Success"),
        description: t("Copy link successful"),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    }).catch((error) => {
      console.error('Failed to copy link: ', error);
    });
  }

  return (
    <ContentStyled>
      <ModalChooseTime
        visible={isModalChooseTime}
        closeModal={CloseModalChooseTime}
        fetchData={fetchData}
        setIsModalChooseTime={setIsModalChooseTime}
        timePicker={timePicker}
      />
      <div style={{
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "20px",
      }}>
        <div className="row title-chard-style">
          <strong className="col l-6 m-12" style={{
            fontSize: "20px"
          }}>
            <RiseOutlined style={{ marginRight: "5px" }} />
            {`${t('Global stream')} (${t('From')} 01/2021 ${t('to')} ${timeUpdateTop})`}
          </strong>
          <div className="col l-6 m-12 all-view-style">
            <CardNumber>{totalStream(topRelease?.yearStream)}</CardNumber>
          </div>
        </div>

        {
          !topRelease?.yearStream ?
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px'
            }}>
              <Spin indicator={<LoadingOutlined spin />} />
            </div> :
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={topRelease?.yearStream}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="2">
                    <stop offset="5%" stopColor="#45a6ff" stopOpacity={1} />
                    <stop offset="95%" stopColor="#45a6ff" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="year" stroke="#000" />
                <YAxis tickFormatter={format} stroke="#000" />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="stream" dot={<CustomDot />} fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </ResponsiveContainer>
        }
      </div>
      <div style={{
        padding: "15px 3vw",
        borderRadius: "10px",
        minHeight: "60vh",
      }}>
        <HeaderTop>
          <strong><CrownOutlined /> TOP 3</strong>
          <Select
            showArrow={true}
            value={selectedTime}
            style={{ width: "200px" }}
            defaultValue={1}
            onChange={handleChangeTime}
            onSelect={handleCallBackSelect}
          >
            {
              optionsTime.map((item) => (
                <Option key={item.value} value={item.value} >
                  {item.label}
                </Option>
              ))
            }
          </Select>
        </HeaderTop>
        {
          loading ?
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px'
            }}>
              <Spin indicator={<LoadingOutlined spin />} />
            </div> :
            <GridContainer>
              {
                topRelease?.topTracks?.map((item, index) => {
                  return (
                    <div key={index} className="grid-item">
                      <div>
                        <CardImage>
                          <img className="card-image" src={item.image} alt={item.name} />
                          <CardInfo className="top-info">#{item.position}</CardInfo>
                          <CardInfo className="view-info">
                            <CustomerServiceOutlined style={{ marginRight: "5px" }} />
                            {formatNumber(item.stream)}
                          </CardInfo>
                        </CardImage>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: "center",
                          marginTop: "8px"
                        }}>
                          <strong style={{
                            fontSize: "15px"
                          }}>{item.name}</strong>
                          <CardInfo className="icon-share" onClick={() => handleCopyLink(item.link)}><ShareAltOutlined /></CardInfo>
                        </div >
                      </div>
                    </div>
                  )
                })
              }
            </GridContainer>
        }
      </div >
    </ContentStyled >
  );
};

export default OverViewLandingPage;

const ContentStyled = styled.div`
  min-height: 100vh;
  .content {
    background-color: #fdfdfd;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 0px 20px;
    .ant-tabs-tab-btn {
      font-weight: 500 !important;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 20px !important;
  }
  button {
    margin-bottom: 20px;
  }
  .icon-share {
    cursor: pointer;
    font-size: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
  }

  .title-chard-style {
    align-items: center;
    padding: 0px 10vh
  }

  .all-view-style {
    display: flex;
    justify-content: end;
  }

  @media (max-width: 1100px) {
    .all-view-style {
      justify-content: center;
    }
  }

  @media (max-width: 740px) {
    .icon-share {
      font-size: 15px;
      width: 25px;
      height: 25px;
    }

    .all-view-style {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .title-chard-style {
      padding: unset
    }
  }
`;

const CardNumber = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 26px;
  background: rgb(68 106 167);
  padding: 5px 15px;
  max-width: fit-content;
  border-radius: 6px;
  color: white;
`

const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .grid-item {
    width: 45vh;
    border-radius: 10px;
  }
`

const CardImage = styled.div`
  position: relative;
  min-height: 100%;
  min-width: 100%;
  aspect-ratio: 1 / 1;

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    display: block;
  }

  .view-info {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
  }

  .top-info {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
  }

  @media (max-width: 740px) {
    .view-info {
      font-size: 15px;
    }

    .top-info {
      font-size: 15px;
    }
  }
`

const CardInfo = styled.div`
  color: white;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  background: rgb(34 33 33);
  border-radius: 5px;
`

const HeaderTop = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .ant-select-selector {
    background-color: rgb(76 74 86) !important;
    border-radius: 6px !important;
    color: white;
    border: unset !important;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-form-item {
    margin: 0 !important;
  };
`