import { Button, Form, Input, Spin, notification } from "antd";
import React, { useState, useCallback } from "react";
import PasswordIcon from "@assets/icon/PasswordIcon";
import WarningIcon from "@assets/icon/WarningIcon";
import UserIcon from "@assets/icon/UserIcon";
import Footer from "@components/Layout/Footer";
import { authPostData } from "@utils/request";
import { useNavigate } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import LogoComponent from "../../components/Layout/Logo";
import { setEmail } from "@redux/Date/DateReducer";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  setValueArtist,
  setValueViewer,
  setValueOwner,
  setReleaseId,
  setTrackId,
  setEndDay,
  setStartDay
} from "../../redux/Date/DateReducer";
import { logoutSuccess } from "../../redux/Auth/authReducer";


export default function ChangePasswordUser() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (window.location.href.includes("email="))
    {
      console.log(window.location.href.split("email=")[1]);
      localStorage.setItem("userid", (window.location.href.split("email=")[1]).split("&type=")[0]);
    }
  }, [window.location.href]);
  // const email = useSelector((state) => state.date.email);
  const onFinish = useCallback((values) => {
    authPostData({
      url: "api/v1/user/change-password-user",
      method: "POST",
      payload: values,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: "Thành công",
            description: res.message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          dispatch(setStartDay(null));
          dispatch(setEndDay(null));
          dispatch(setValueOwner(false));
          dispatch(setValueArtist(""));
          dispatch(setValueViewer(""));
          dispatch(setReleaseId(""));
          dispatch(setTrackId(""));
          dispatch(logoutSuccess());
          navigate("/login");
        } else {
          setLoading(false);
        }
      },
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ backgroundColor: "#e5e5e5" }}>
        <div className="login-container">
          <LogoComponent />
          <div className="login-form">
            <div className="form-title">
              <span>Thay đổi mật khẩu</span>
            </div>
            <Form
              // form={form}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="form-login"
            >
              <Form.Item
                name="oldPassword"
                hasFeedback
                placeholder="Nhập mật khẩu hiện tại"
                rules={[
                  {
                    required: true,
                    message: "Nhập mật khẩu hiện tại của bạn",
                  }
                  ]}
              >
                <Input.Password
                  className="form-input-password-again form-input"
                  placeholder="Nhập mật khẩu hiện tại"
                  size="large"
                  prefix={<PasswordIcon style={{ marginRight: "12px" }} />}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Nhập mật khẩu mới của bạn",
                  },
                  {
                    validator(rule, value) {
                      if (!value || value.length >= 8) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Mật khẩu mới yêu cầu tối thiểu 8 ký tự"
                      );
                    },
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("oldPassword") !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Mật khẩu phải khác mật khẩu cũ");
                    }
                  }),

                ]}
              >
                <Input.Password
                  className="form-input-password-again form-input"
                  size="large"
                  placeholder="Nhập mật khẩu mới"
                  prefix={<PasswordIcon style={{ marginRight: "12px" }} />}
                />
              </Form.Item>

              <Form.Item
                name="confirmNewPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Xác nhận mật khẩu của bạn",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      console.log(1111, value);
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Mật khẩu xác nhận không trùng khớp"
                      );
                    },
                  }),

                ]}
              >
                <Input.Password
                  className="form-input-password-again form-input"
                  size="large"
                  placeholder="Nhập lại mật khẩu mới"
                  prefix={<PasswordIcon style={{ marginRight: "12px" }} />}
                />
              </Form.Item>

              <Button
                style={{ marginTop: "30px" }}
                htmlType="submit"
                type="primary"
                size="large"
                className="full login-button"
              >
                <span>Xác nhận</span>
              </Button>
            </Form>
          </div>
        </div>
        <Footer />
      </div>
    </Spin>
  );
}
