import { DesktopOutlined, FileTextOutlined, SettingOutlined, TransactionOutlined, UserOutlined } from "@ant-design/icons";
import Dashboard from "@assets/icon/DashboardIcon";
import LogoutIcon from "@assets/icon/LogoutIcon";
import SettingUserIcon from "@assets/icon/SettingUserIcon";
import StatisticIcon from "@assets/icon/StatisticIcon";
import PaymentHistoryIcon from "@assets/icon/PaymentHistoryIcon";
import AvatarDefault from "@assets/images/Frame 3019.png";
import { authPostData } from "@utils/request";
import { authGetData } from "@utils/request";
import { Image, Layout, Menu, Select, Space, Tooltip } from "antd";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Collapse, Form } from "antd";
import { logoutSuccess } from "../../redux/Auth/authReducer";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import {
  setValueArtist,
  setValueViewer,
  setValueOwner,
  setReleaseId,
  setTrackId,
  setEndDay,
  setStartDay
} from "../../redux/Date/DateReducer";
import { useTranslation } from "react-i18next";
const { Sider } = Layout;
export default memo(function SiderComponent() {
  const { t } = useTranslation();
  const { isAdmin } = useSelector((state) => state.auth);
  const [isEdit, setisEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [fullName, setFullName] = useState("");
  const [usermanagement, setUserManagement] = useState(false);
  const [usermanagementedit, setUserManagementedit] = useState(false);
  const Linking = styled.a``;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [form] = Form.useForm();

  const [dataVersion, setDataVerSion] = useState("")
  const payloaded = {
    token: "",
    versioning: "",
    platform: ""
  };

  const getDataVersion = () => {
    authGetData({
      url: `api/v1/versionweb/currentversionweb`,
      setLoading,
      onSuccess: (res) => {
        setDataVerSion(res?.data?.version)
      },
    });
  };
  useEffect(() => {
    getDataVersion();
  }, []);

  useEffect(() => {
    if (window.location.pathname == "/admin/user-management/create-user") {
      setUserManagement(true);
    }
    else {
      setUserManagement(false);
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (window.location.pathname.includes("/admin/") || window.location.pathname.includes("/user/")) {
      authGetData({
        url: `api/v1/user/profile`,
        setLoading: () => { },
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            setAvatar(res.data.avatarUrl);
            setFullName(res.data.fullName);
            if (res.data.labelName == "DeleteUser1234567890") {
              dispatch(setStartDay(null));
              dispatch(setEndDay(null));
              dispatch(setValueOwner(false));
              dispatch(setValueArtist(""));
              dispatch(setValueViewer(""));
              dispatch(setReleaseId(""));
              dispatch(setTrackId(""));
              authPostData({
                url: "api/v1/user/logout",
                method: "POST",
                payload: payloaded,
                setLoading: () => { },
                setImportLoading: () => { },
              });
              dispatch(logoutSuccess());
            }
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (fullName != null && fullName.length > 18) {
      setFullName(fullName.substring(0, 16) + "...");
    }
  }, [fullName]);

  useEffect(() => {
    if (window.location.pathname.includes("/admin/user-management/")
      || window.location.pathname.includes("/user/user-management/")) {
      setUserManagementedit(true);
    }
    else {
      setUserManagementedit(false);
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (window.location.pathname == "/admin/profile"
      || window.location.pathname == "/user/profile"
      || window.location.pathname == "/admin/user-management/create-user"
      || window.location.pathname.includes("/admin/user-management/")
      || window.location.pathname.includes("/user/user-management/")) {
      setisEdit(true);
    }
    else {
      setisEdit(false);
    }
  }, [window.location.pathname])

  const getKey = (route) => {
    if (isAdmin) {
      return `/admin/${route}`;
    } else {
      return `/user/${route}`;
    }
  };

  useEffect(() => {
    if (window.location.pathname == "/admin/profile"
      || window.location.pathname == "/user/profile"
      || window.location.pathname == "/admin/user-management/create-user") {
      setisEdit(true);
    }
  }, []);

  const checkingEditOverview = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate(isAdmin ? "/admin/overview" : "/user/overview");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditStatistic = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate(isAdmin ? "/admin/statistic" : "/user/statistic");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditUserManager = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate(isAdmin ? "/admin/user-management" : "/user/user-management");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditPaymentManagement = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate(isAdmin ? "/admin/payment-management" : "/user/payment-management");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditPaymentHistory = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate(isAdmin ? "/admin/payment-history" : "/user/payment-history");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditSetting = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/setting");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditSettingShareRate = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/setting-share-rate");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditAlbumManagement = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/album-management");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditAlbumManagementPlatform = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/album-management-platform");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };
  const checkingEditAlbumManagementCountry = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/album-management-country");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };

  const checkinglogTime = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/log-time");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };

  const checkingsalesReport = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/sales-report");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };

  const checkingEditArtist = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate(isAdmin ? "/admin/artist-management" : "/user/artist-management");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };

  const checkingEditImportCSV = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/import-csv");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };

  const checkingEditVersion = () => {
    if (isEdit) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: t('Save the change?'),
        content:
          t('If you move/close tab without saving, the changes you made earlier will be lost'),
        onOk() {
          navigate("/admin/version");
          setisEdit(false);
        },
        onCancel() {
          setisEdit(true);
        },
        okText: t('Agree'),
        okButtonProps: { type: "primary" },
        cancelText: t('Cancel'),
      });
    }
  };

  return (
    <SideStyled collapsedWidth="0">
      <div className="navbar">
        <Menu mode="inline">
          <Menu.Item key={getKey("overview")} onClick={checkingEditOverview}>
            {!isEdit && (<NavLink to={isAdmin ? "/admin/overview" : "/user/overview"}>
              <Dashboard />
              <span>{t('Dashboard')}</span>
            </NavLink>)}
            {isEdit &&
              <Linking to={"profile"}><Dashboard className="App-logo" />
                <span>{t('Dashboard')}</span></Linking>
            }
          </Menu.Item>
          <Menu.Item key={getKey("statistic")} onClick={checkingEditStatistic}>
            {!isEdit && (<NavLink to={isAdmin ? "/admin/statistic" : "/user/statistic"}>
              <StatisticIcon />
              <span>{t('Statistics')}</span>
            </NavLink>)}
            {isEdit &&
              <Linking to={"profile"}><StatisticIcon />
                <span>{t('Statistics')}</span></Linking>
            }
          </Menu.Item>
          <Menu.Item key={getKey("payment-history")} onClick={checkingEditPaymentHistory}>
            {!isEdit && (<NavLink to={isAdmin ? "/admin/payment-history" : "/user/payment-history"}>
              <PaymentHistoryIcon />
              <span>{t('Activity Log')}</span>
            </NavLink>)}
            {isEdit &&
              <Linking to={"profile"}><PaymentHistoryIcon />
                <span>{t('Activity Log')}</span></Linking>
            }
          </Menu.Item>
          <Menu.Item key={getKey("user-management")} onClick={checkingEditUserManager}>
            {!isEdit && (<NavLink to={isAdmin ? "/admin/user-management" : "/user/user-management"}>
              <UserOutlined />
              <span>{t('Users')}</span>
            </NavLink>)}
            {isEdit &&
              <Linking to={"profile"}><UserOutlined />
                <span>{t('Users')}</span></Linking>
            }
          </Menu.Item>
          <Menu.SubMenu
            key={t('Transactions')}
            title={t('Transactions')}
            icon={<TransactionOutlined />}
          >
            <Menu.Item key={getKey("payment-management")} onClick={checkingEditPaymentManagement}>
              {!isEdit && (<NavLink to={isAdmin ? "/admin/payment-management" : "/user/payment-management"}>
                <span>{t('Revenue management')}</span>
              </NavLink>)}
              {isEdit &&
                <Linking to={"profile"}>
                  <span>{t('Revenue management')}</span></Linking>
              }
            </Menu.Item>
            {isAdmin && (
              <Menu.Item key="/admin/import-csv" onClick={checkingEditImportCSV}>
                {!isEdit && (<NavLink to="/admin/import-csv">
                  <span>{t('CSV management')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('CSV management')}</span></Linking>
                }
              </Menu.Item>
            )}
          </Menu.SubMenu>
          <Menu.SubMenu
            key={t('Configuration')}
            title={t('Configuration')}
            icon={<SettingOutlined />}
          >
            {isAdmin && (
              <Menu.Item key="/admin/setting" onClick={checkingEditSetting}>
                {!isEdit && (<NavLink to="/admin/setting">
                  <span>{t('Common')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Common')}</span></Linking>
                }
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item key="/admin/setting-share-rate" onClick={checkingEditSettingShareRate}>
                {!isEdit && (<NavLink to="/admin/setting-share-rate">
                  <span>{t('Revenue')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Revenue')}</span></Linking>
                }
              </Menu.Item>
            )}
            <Menu.Item key={getKey("artist-management")} onClick={checkingEditArtist}>
              {!isEdit && (<NavLink to={isAdmin ? "/admin/artist-management" : "/user/artist-management"}>
                <span>{t('Artist')}</span>
              </NavLink>)}
              {isEdit &&
                <Linking to={"profile"}>
                  <span>{t('Artist')}</span></Linking>
              }
            </Menu.Item>
            {isAdmin && (
              <Menu.Item key="/admin/album-management" onClick={checkingEditAlbumManagement}>
                {!isEdit && (<NavLink to="/admin/album-management">
                  <span>{t('Album Cover photo')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Album Cover photo')}</span></Linking>
                }
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item key="/admin/album-management-platform" onClick={checkingEditAlbumManagementPlatform}>
                {!isEdit && (<NavLink to="/admin/album-management-platform">
                  <span>{t('Store photo')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Store photo')}</span></Linking>
                }
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item key="/admin/album-management-country" onClick={checkingEditAlbumManagementCountry}>
                {!isEdit && (<NavLink to="/admin/album-management-country">
                  <span>{t('Teritory photo')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Teritory photo')}</span></Linking>
                }
              </Menu.Item>
            )}
          </Menu.SubMenu>
          {isAdmin && (
            <Menu.SubMenu
              key={t('System')}
              title={t('System')}
              icon={<DesktopOutlined />}
            >
              <Menu.Item key="/admin/version" onClick={checkingEditVersion}>
                {!isEdit && (<NavLink to="/admin/version">
                  <span>{t('Mobile App Version')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Mobile App Version')}</span></Linking>
                }
              </Menu.Item>
              <Menu.Item key="/admin/log-time" onClick={checkinglogTime}>
                {!isEdit && (<NavLink to="/admin/log-time">
                  <span>{t('Active history')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Active history')}</span></Linking>
                }
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {isAdmin && (
            <Menu.SubMenu
              key={t('Reports')}
              title={t('Reports')}
              icon={<FileTextOutlined />}
            >
              <Menu.Item key="sales-report" onClick={checkingsalesReport}>
                {!isEdit && (<NavLink to="/admin/sales-report">
                  <span>{t('Revenue report')}</span>
                </NavLink>)}
                {isEdit &&
                  <Linking to={"profile"}>
                    <span>{t('Revenue report')}</span></Linking>
                }
              </Menu.Item>
            </Menu.SubMenu>
          )}

        </Menu>
      </div>
      <Layout>
        <div className="version">
          {t('Web version')}  {dataVersion}
        </div>
      </Layout>
    </SideStyled>
  );
});

const SideStyled = styled(Sider)`
  box-sizing: border-box;
  box-shadow: inset -0.5px 0px 0px rgba(181, 181, 181, 0.25);
  z-index: 9;
  height: 100%;
  width: 210px !important;
  background-color: #003366;
  position: fixed;
  .logo {
    box-shadow: inset 0px -0.5px 0px #d9dbdd;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 28px 0;
    justify-content: center;
  }
  .collapse-icon {
    :hover {
      cursor: pointer;
    }
  }
  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px -0.5px 0px #d9dbdd;
    padding-bottom: 16px;
    padding-top: 16px;
    .logout {
      width: 15%;
      padding-right: 1rem;
      cursor: pointer;
    }
    .user-wrapper {
      display: flex;
      width: 82%;
      align-items: center;
      padding-left: 0.5rem;
      img {
        border-radius: 5px;
      }
      .info {
        color: #fdfdfd;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0 12px;
        a {
          padding-bottom: 6px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          color: #fdfdfd;
          cursor: pointer;
        }
        span::after {
          color: #fdfdfd;
          /* cursor: pointer; */
        }
      }
    }
  }
  .sidebar-menu {
    div {
      cursor: pointer;
      padding-bottom: 10px;
      box-shadow: inset 0px -0.5px 0px #d9dbdd;
      .li {
        margin: 10px 15px;
        padding: 10px;
        list-style: none;
        display: flex;
        align-items: center;
        color: white;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        svg {
          fill: #f0f2f5;
          font-size: 20px;
        }
        span {
          margin-top: 2px;
          padding-left: 14px;
          letter-spacing: 0.1px;
          font-size: 12px;
          font-weight: 400;
        }
        .anticon-wallet {
          margin-left: -14px;
        }
      }
      .li.active {
        background: #fdfdfd;
        color: #003366;
        border-radius: 8px;
        font-weight: 500;
        svg {
          fill: #003366;
        }
      }
    }

  }
  .navbar {
    height: calc(100% - 101px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .version {
    position: fixed;
    bottom: 0;
    width: 202px;
    height: 48px;
    color: #fff;
    padding: 16px 20px;
    background-color: #003366;
    box-shadow: inset 0px 0.5px 0px #d9dbdd;
  }

  @media (max-width: 980px) {
    min-width: 150px !important;
    width: 160px !important;

    .user {
      .logout {
        padding-right: 6px;
      }
      .user-wrapper {
        .info {
          padding: 0 6%;
          small {
            font-size: 74%;
          }
        }
      }
    }
    .version {
      width: 152px;
    }
  }
`;
