import { authGetData } from "@utils/request";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ChangeInfomation from "./Component/ChangeInfomation";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture } from "@utils/function";

const InforMain = () => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    authGetData({
      url: `api/v1/admin/user/${id}?culture=${culture}`,
      method: "GET",
      setLoading,
      onSuccess: (res) => {
        setData(res.data);
      },
    });
  }, [id, savedLanguage]);
  return (
    <>
      <div
        style={{
          padding: "20px",
          backgroundColor: "rgb(229, 229, 229)",
        }}
      >
        <ContentStyled>
          <div className="header">
            <div className="title">{t('User information')}</div>
          </div>
          <div className="content">
            <ChangeInfomation artistData={data} load={loading} />
          </div>
        </ContentStyled>
      </div>
    </>
  );
};

export default InforMain;

const ContentStyled = styled.div`
  background-color: #fdfdfd;
  border-radius: 8px;
  .header {
    box-shadow: inset 0px -0.5px 0px #d9dbdd;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 14px;
      align-self: center;
    }
    .button {
      background-color: #003366;
      border-color: #003366;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .content {
    .ant-form-item {
      justify-content: center;
    }
    .ant-form-item:last-child {
      .ant-form-item-control-input-content {
        text-align: center;
      }
    }
    .button {
      background-color: #003366;
      color: #fff;
      border-color: #003366;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      border-radius: 4px;
    }
    .button-edit {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      border-radius: 4px;
    }
  }
`;
