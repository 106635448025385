import RevenueIcon from "@assets/icon/RevenueIcon";
import StreamIcon from "@assets/icon/StreamIcon";
import { Spin } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mapCurrencyToCulture } from "@utils/function";

function OverViewDetailTotalGrosssRevenue() {
    const { t } = useTranslation();
    const currency = mapCurrencyToCulture();
    const totalGrossRevenue = useSelector((state) => state.date.totalGrossRevenue);
    const previousGrossRevenue = useSelector(
        (state) => state.date.previousGrossRevenue
    );
    const [percentageGrossRevenue, setPerGrosssRevenue] = useState();

    useEffect(() => {
        if (previousGrossRevenue !== 0 && totalGrossRevenue !== 0) {
            setPerGrosssRevenue(
                ((totalGrossRevenue - previousGrossRevenue) / previousGrossRevenue) * 100
            );
        } else if (totalGrossRevenue === 0) {
            setPerGrosssRevenue(0);
        } else {
            return setPerGrosssRevenue(100);
        }
    }, [previousGrossRevenue, totalGrossRevenue]);

    const total = [
        {
            id: 1,
            title: t('Gross revenue'),
            number: `${totalGrossRevenue
                ? Number(totalGrossRevenue.toFixed(3))
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                : "0"
                } `,
            percentage
                : percentageGrossRevenue === 100 ? ""
                    : percentageGrossRevenue ? `${Number(percentageGrossRevenue.toFixed(2))}%`
                        : "0%",
            time: percentageGrossRevenue === 100 ? "" : t('In the same period last year'),
            icon: <StreamIcon />,
        },
    ];

    return (
        <>
            <div className="overview-total">
                <div className="overview-total-item">
                    <div className="overview-total-item-beside">
                        <h1>{total[0].title}</h1>
                        {currency === 'EUR' ? (
                            <p style={{ color: "#629cd1" }}>{total[0].number} €</p>
                        ) : (
                            <p style={{ color: "#629cd1" }}>{total[0].number} đ</p>
                        )}
                        {totalGrossRevenue - previousGrossRevenue < 0 ? (
                            <span style={{ color: "red" }}>{total[0].percentage}</span>
                        ) : (
                            <span style={{ color: "#48bb78" }}>{total[0].percentage}</span>
                        )}
                        <span>{total[0].time}</span>
                    </div>
                    {total[0].icon}
                </div>
            </div>
        </>
    );
}

export default memo(OverViewDetailTotalGrosssRevenue);
