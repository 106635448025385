import { STATUS_CODE_200, TOKEN_NAME } from "@utils/constants";
import { authGet } from "@utils/request";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { loginSuccess, logoutSuccess } from "../Auth/authReducer";
import { signInFinish } from "./authReducer";

// function* checkAuthenticated() {
//   yield fork(() => watchGetProfile());
// }

function* watchGetProfile() {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token) {
    const res = yield call(authGet, "api/v1/user/profile");
    if (res && res.data.statusCode === STATUS_CODE_200) {
      yield put(loginSuccess(res.data.data));
    } else {
      yield put(logoutSuccess());
    }
  }
}

export function* authSagas() {
  yield all([
    takeLatest(signInFinish, watchGetProfile),
    // fork(() => checkAuthenticated()),
    // takeLatest(getUserProfile, watchGetProfile),
  ]);
}
