import { Endpoint } from "@utils/endpoint";
import {
  FILTER_TYPE_TEXT_INPUT,
  FILTER_TYPE_SELECT,
  FILTER_TYPE_DATE_RANGE,
  soPha,
  mucDichSuDung,
  DEFAULT_PAGESIZE,
  DEFAULT_PAGEINDEX,
} from "@utils/constants";

const filters = [
  {
    label: "Đơn vị",
    api: `${Endpoint.LIST_DON_VI}?pageIndex=${DEFAULT_PAGEINDEX}&pageSize=${DEFAULT_PAGESIZE}`,
    type: FILTER_TYPE_SELECT,
    key: "donVi",
    placeholder: "",
    display: 1,
  },
  {
    label: "Đội",
    api: `${Endpoint.LIST_DOI}`,
    type: FILTER_TYPE_SELECT,
    key: "doi",
    placeholder: "",
    display: 2,
  },
  {
    label: "Loại yêu cầu",
    api: `${Endpoint.LIST_LOAI_YEU_CAU}`,
    type: FILTER_TYPE_SELECT,
    key: "loaiYeuCau",
    placeholder: "",
    display: 3,
  },
  {
    label: "Tình trạng",
    api: "",
    type: FILTER_TYPE_SELECT,
    key: "tinhTrang",
    placeholder: "",
    display: 4,
  },
  {
    label: "Mục đích",
    api: mucDichSuDung,
    type: FILTER_TYPE_SELECT,
    key: "mucDich",
    placeholder: "",
    display: 5,
  },
  {
    label: "Số pha",
    api: soPha,
    type: FILTER_TYPE_SELECT,
    key: "soPha",
    placeholder: "",
    display: 6,
  },
  {
    label: "Thời gian",
    api: "",
    type: FILTER_TYPE_DATE_RANGE,
    key: "thoiGian",
    placeholder: "",
    display: 7,
  },
  {
    label: "Từ khóa tìm kiếm",
    type: FILTER_TYPE_TEXT_INPUT,
    key: "search",
    placeholder: "",
    display: 8,
  },
];
export default filters;
