import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import { BASE_API_URL } from "@utils/constants";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import {
  beforeUpload,
  buildQueryString,
  handlePagination,
} from "@utils/function";
import { authGetData, authPutData } from "@utils/request";
import {
  Button,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Space,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const PlatformLogos = () => {
  const showUploadModal = (platform) => {
    setOnEditPlatformId(platform.id);
    setUploadedImgUrl(platform.imageUrl);
    setUploadModalVisible(true);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      align: "center",
      width: "5%",
    },
    {
      title: "Nền tảng",
      dataIndex: "name",
      align: "left",
      width: "30%",
    },
    {
      title: "Logo",
      render: (record) => {
        return record.imageUrl ? (
          <img
            src={BASE_API_URL + record.imageUrl}
            alt="Avatar"
            style={{ height: "40px", width: "40px", objectFit: "cover" }}
          />
        ) : (
          <p style={{ color: "red" }}>Chưa có ảnh</p>
        );
      },
      align: "center",
    },
    {
      title: "Cập nhật Logo",
      render: (record, item) => (
        <CloudUploadOutlined
          style={{ color: "blue", cursor: "pointer", fontSize: 20 }}
          onClick={() => showUploadModal(item)}
        />
      ),
      align: "center",
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const [_, setPlatformLoading] = useState(false);
  const [onEditPlatformId, setOnEditPlatformId] = useState(null);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [tableData, setTableData] = useState({
    dataLoading: true,
    platforms: [],
    total: 0,
    columns,
  });
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [uploadedImgUrl, setUploadedImgUrl] = useState(null);
  const [query, setQuery] = useState({
    pageSize: searchParams.get("pageSize") || filterConditions.pageSize,
    pageIndex: searchParams.get("pageIndex") || filterConditions.pageIndex,
  });

  useEffect(() => {
    setSearchParams(query);
    getTableData(query);
  }, [query]);

  const getTableData = (queryObj) => {
    setTableData((prev) => {
      return {
        ...prev,
        dataLoading: true,
      };
    });
    const queryString = buildQueryString(queryObj);
    const url = queryString
      ? "api/v1/overview/list-platforms?" + queryString
      : "api/v1/overview/list-platforms";

    authGetData({
      url,
      method: "GET",
      setLoading: setPlatformLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setTableData((prev) => {
            return {
              ...prev,
              dataLoading: false,
              platforms: res.data.map((e, index) => ({
                ...e,
                key:
                  (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                  (index + 1),
              })),
              total: res.paging.totalCount,
            };
          });
        }
      },
    });
  };

  const handleUpdateLogo = () => {
    setUploading(true);

    const payload = {
      uploadType: 4,
      imageUrl: uploadedImgUrl,
    };

    authPutData({
      url: `api/v1/change-image/${onEditPlatformId}`,
      method: "PUT",
      payload,
      setLoading: setUploading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setUploadModalVisible(false);

          notification.open({
            message: "Cập nhật thành công",
            description: res.message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });

          getTableData(query);

          setUploading(false);

          setUploadedImgUrl(null);
        }
      },
    });
  };

  const handleCancelUpdateLogo = () => {
    setOnEditPlatformId(null);
    setUploadModalVisible(false);
  };

  const handleUploadChange = (info) => {
    if (info.file.status === "uploading") {
      setUploading(true);
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: "Thành công",
        description: info.file.response.message,
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setUploading(false);
      setUploadedImgUrl(info.file.response.data);
    }
  };

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);

    setQuery((prev) => {
      return { ...prev, pageIndex: paging.current, pageSize: paging.pageSize };
    });
  };

  return (
    <>
      <div className="table-data--wrap">
        <div className="table-data--main">
          <div className="header">
            <div className="title">Danh sách</div>
          </div>

          <div className="content">
            <ConfigProvider
              renderEmpty={() => <Empty description="Chưa có dữ liệu" />}
            >
              <TableComponent
                defaultCurrent={filterConditions.pageIndex}
                rowKey="key"
                dataSource={tableData.platforms}
                columns={tableData.columns}
                totalData={tableData.total}
                loading={tableData.dataLoading}
                onChangePagination={onChangePagination}
                pagination={filterConditions}
              />
            </ConfigProvider>

            <Modal
              title="Cập nhật Logo"
              visible={uploadModalVisible}
              onOk={handleUpdateLogo}
              onCancel={handleCancelUpdateLogo}
              footer={[
                <Space key="update-logo">
                  <Button
                    key="back"
                    type="outline"
                    onClick={handleCancelUpdateLogo}
                  >
                    Hủy bỏ
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    loading={uploading}
                    onClick={handleUpdateLogo}
                  >
                    Cập nhật
                  </Button>
                </Space>,
              ]}
            >
              <Upload
                name="File"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={BASE_API_URL + "api/v1/upload"}
                beforeUpload={beforeUpload}
                onChange={handleUploadChange}
              >
                {uploadedImgUrl ? (
                  <img
                    src={BASE_API_URL + uploadedImgUrl}
                    alt="logo"
                    style={{ width: "95%" }}
                  />
                ) : (
                  <div>
                    {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Tải ảnh</div>
                  </div>
                )}
              </Upload>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
