import React from "react";

function FileIcon() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.333 2.00008H6.99967L6.05967 1.06008C5.80634 0.806748 5.46634 0.666748 5.11301 0.666748H1.66634C0.926341 0.666748 0.339674 1.26008 0.339674 2.00008L0.333008 10.0001C0.333008 10.7401 0.926341 11.3334 1.66634 11.3334H12.333C13.0663 11.3334 13.6663 10.7334 13.6663 10.0001V3.33341C13.6663 2.60008 13.0663 2.00008 12.333 2.00008ZM10.9997 7.33341H9.66634V8.66675C9.66634 9.03341 9.36634 9.33341 8.99967 9.33341C8.63301 9.33341 8.33301 9.03341 8.33301 8.66675V7.33341H6.99967C6.63301 7.33341 6.33301 7.03341 6.33301 6.66675C6.33301 6.30008 6.63301 6.00008 6.99967 6.00008H8.33301V4.66675C8.33301 4.30008 8.63301 4.00008 8.99967 4.00008C9.36634 4.00008 9.66634 4.30008 9.66634 4.66675V6.00008H10.9997C11.3663 6.00008 11.6663 6.30008 11.6663 6.66675C11.6663 7.03341 11.3663 7.33341 10.9997 7.33341Z" fill="#7F8183"/>
    </svg>
  );
}

export default FileIcon;