import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import { authGetData } from "@utils/request";
import { removeAccents } from "@utils/function";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const SelectionLabels = (props) => {
  const { t, i18n } = useTranslation();
  const { url, form, formKey, mode } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        setData(res?.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url) {
      getData();
    }
  }, [getData, url]);

  const handleChange = useCallback(
    (value) => {
      form.setFieldsValue({
        [formKey]: value,
      });
    },
    [form, formKey]
  );

  return (
    <Select
      placeholder={t('All')}
      mode={mode}
      showSearch
      showArrow={true}
      maxTagCount= "responsive"
      allowClear={true}
      loading={loading}
      onChange={handleChange}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data?.length
        ? data.map((item) => (
            <Option key={item.userId} value={item.userId}>
              {item.labelName}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export default SelectionLabels;
