import React from "react";

function SuccessIcon() {
  return (
    <svg
      width="42"
      height="30"
      viewBox="0 0 42 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29163 14.9999L15.0833 26.7916L38.7083 3.20825"
        stroke="#FDFDFD"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessIcon;
