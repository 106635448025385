import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APILo = {
  LIST_LO: `${BASE_API_URL_CAP_DIEN}/lo/list`,
  SYNC_LO: `${BASE_API_URL_CAP_DIEN}/lo/sync`,
  CREATE_LO: `${BASE_API_URL_CAP_DIEN}/lo/create`,
  UPDATE_LO: `${BASE_API_URL_CAP_DIEN}/lo/update`,
  REMOVE_LO: `${BASE_API_URL_CAP_DIEN}/lo/delete`,
  GET_LO: `${BASE_API_URL_CAP_DIEN}/lo/get`,
};
