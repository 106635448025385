import TableComponent from "@components/TableComponent";

import {
  disabledDateTime,
  handlePagination,
  removeAccents,
} from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
import moment from "moment";
import {
  ConfigProvider,
  Empty,
  Spin,
  Select,
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Button,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { mapLanguageToCulture } from "@utils/function";
import { DEFAULT_PAGESIZE } from "@utils/constants";
const { Option } = Select;

const LogTimeMain = () => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const cancelTokenSource = useRef();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [total, setTotal] = useState();
  const [dataCountryAlbum, setDataCountryAlbum] = useState([]);
  const [load, setLoading] = useState(false);
  const [state, setState] = useState({ imageUrl: null, loading: false });
  const [releaseIds, setReleaseIds] = useState([]);
  const [statusIds, setStatusIds] = useState([]);
  const { loading, imageUrl } = state;

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  useEffect(() => {
    const endDate = moment.utc();
    const startDate = moment.utc().subtract(1, "year");

    form.setFieldsValue({
      time: [startDate, endDate],
    });
  }, [form]);

  const getData = () => {
    authPostData({
      url: `api/v1/admin/getdevices?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataCountryAlbum(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
      cancelToken: cancelTokenSource.current,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions, culture]);

  useEffect(() => {
    authGetData({
      url: `api/v1/admin/getdevicenames`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setReleaseIds(res.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    authGetData({
      url: `api/v1/admin/getstatusnames`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setStatusIds(res.data);
        }
      },
    });
  }, []);

  const columns = [
    {
      title: t("No."),
      dataIndex: "key",
      align: "center",
      width: "4%",
    },
    {
      title: t("Accounts"),
      dataIndex: "email",
      align: "center",
      width: "15%",
    },
    {
      title: t("Stores"),
      dataIndex: "platform",
      align: "center",
      width: "13%",
      className: "min-width-120",
    },
    {
      title: t("Device name"),
      dataIndex: "deviceName",
      align: "center",
      width: "13%",
      className: "min-width-120",
    },
    {
      title: t("OS version"),
      dataIndex: "osVersion",
      align: "center",
      width: "14%",
      className: "min-width-130",
    },
    {
      title: t("3S Media version"),
      dataIndex: "version",
      align: "center",
      width: "13%",
      className: "min-width-120",
    },
    {
      title: t("Time of record"),
      dataIndex: "createdTime",
      render: (text) => (
        <span>{moment(text).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
      align: "center",
      width: "15%",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "center",
      width: "13%",
      className: "min-width-120",
    },
  ];

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
      status: [values.status],
      startTime: values?.time[0] ? moment(values?.time[0]) : null,
      endTime: values?.time[1] ? moment(values?.time[1]) : null,
    });
  };

  // Xử lý xóa bộ lọc

  const handleReset = () => {
    const endTime = moment.utc();
    const startTime = moment.utc().subtract(1, "year");

    form.resetFields();
    form.setFieldsValue({
      time: [startTime, endTime],
    });
    setFilterConditions({
      startTime,
      endTime,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div className="select-option">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[20, 20]}>
              <Col xl={7} lg={11} md={24}>
                <Form.Item name="users">
                  <Input placeholder={t("Input user account")} />
                </Form.Item>
              </Col>

              <Col xl={9} lg={13} md={24}>
                <Form.Item label={t("Time of record")} name="time">
                  <RangePicker
                    locale={i18n.language === "vi" ? localeVi : localeEn}
                    format="DD/MM/YYYY"
                    disabledDate={disabledDateTime}
                  />
                </Form.Item>
              </Col>

              <Col xl={8} lg={8} md={12}>
                <Form.Item label={t("Devices")} name="deviceNames">
                  <Select
                    placeholder={t("All")}
                    mode="multiple"
                    maxTagCount="responsive"
                    showSearch
                    showArrow={true}
                    allowClear={true}
                    loading={loading}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {releaseIds?.length
                      ? releaseIds.map((item) => (
                          <Option key={item.releaseId} value={item.deviceName}>
                            {item.deviceName}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={7} lg={8} md={12}>
                <Form.Item label={t("Status")} name="status">
                  <Select
                    placeholder={t("All")}
                    showSearch
                    showArrow={true}
                    allowClear={true}
                    loading={loading}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {statusIds?.length
                      ? statusIds.map((item) => (
                          <Option key={item.status} value={item.statusName}>
                            {item.statusName}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={8} lg={8} md={24}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Active history")}</div>
            </div>
            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  defaultCurrent={filterConditions.pageIndex}
                  rowKey="key"
                  dataSource={dataCountryAlbum}
                  columns={columns}
                  totalData={total}
                  loading={loading}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </ContentStyled>
    </Spin>
  );
};

export default LogTimeMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }
  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }
  .ant-row {
    align-items: center;
  }
  .ant-form-item {
    margin: 0 !important;
  }

  @media (max-width: 992px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;
