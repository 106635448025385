import {
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { BASE_API_URL } from "@utils/constants";
import { disabledDate, getErrorForm, mapLanguageToCulture, removeAccents } from "@utils/function";
import { authGetData, authPutData, authPostData } from "@utils/request";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Spin,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// const validateMessages = {
//   required: "${label} is required!",
//   types: {
//     email: "${label} is not a valid email!",
//     number: "${label} is not a valid number!",
//   },
// };
const ChangeInfomation = ({ artistData, load }) => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const { isAdmin } = useSelector((state) => state.auth);
  const [, setLoading] = useState(false);
  const [state, setState] = useState({ loading: false });
  const [id, setId] = useState("");
  const [form] = Form.useForm();
  const [dataartists, setDataartists] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loadingUpdateInfor, setLoadingUpdateInfor] = useState(false);
  const [antimul, setAntimul] = useState(false);
  const [dataLabel, setDataLabel] = useState([]);
  const navigate = useNavigate();

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t('Upload photo file other than JPG/PNG format!'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('Photos must be smaller than 2MB!'));
    }
    return isJpgOrPng && isLt2M;
  }

  useEffect(() => {
    if (artistData) {
      const arrayIdArtist =
        artistData.artists &&
        artistData.artists.map((item) => {
          return item.artistId;
        });
      const date = new Date(artistData.birthday);
      form.setFieldsValue({
        ...artistData,
        gender: artistData.gender ? artistData.gender : null,
        birthday: artistData.birthday ? moment(artistData?.birthday) : null,
        userIds: artistData.userRps?.map((item) => item.userId),
      });
      setState({ imageUrl: artistData.avatarUrl });
      setId(artistData.id);
    }
  }, [form, artistData]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t('Success'),
        description: t('Upload photo successful'),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setState({
        imageUrl: BASE_API_URL + info.file.response.data,
        loading: false,
      });
    }
  };

  const onFinish = (values) => {
    const formData = {
      ...values,
      avatarUrl: imageUrl,
      userId: id,
      isAdmin: artistData.isAdmin,
      gender: values.gender === undefined ? 0 : values.gender,
      birthday: values.birthday ? moment(values.birthday).format('YYYY-MM-DDTHH:mm:ss') : null,
    }

    const form_data_with_no_null = Object.fromEntries(
      Object.entries(formData).filter(([_, v]) => v != null)
    );

    authPutData({
      url: `api/v1/admin/user?culture=${culture}`,
      method: "PUT",
      payload: form_data_with_no_null,
      setLoading: setLoadingUpdateInfor,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: t('Update successful'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          navigate("/admin/user-management");
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const { loading, imageUrl } = state;

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('Upload image')}</div>
    </div>
  );

  const layout = {
    labelCol: { lg: 6, md: 8 },
    wrapperCol: { lg: 12, md: 15 },
  };

  const handleChangeArtists = () => { };

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/artists?culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setDataartists(res.data);
        }
      },
    });
  }, [savedLanguage]);

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/labelnames?culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setDataLabel(res.data);
        }
      },
    });
  }, [savedLanguage]);

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    maxTagCount: "responsive",
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setAntimul(true);
    authPutData({
      url: `api/v1/admin/${artistData.id}/reset-password?culture=${culture}`,
      method: "PUT",
      payload: "",
      setLoading,
      setImportLoading: () => { },
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setVisible(false);
          notification.open({
            message: t('Success'),
            description: t('Success'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          setAntimul(false);
        }
      },
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleChangeFields = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
  };

  const handleBackLink = () => {
    window.history.back();
  }

  const ModalWarningChange = () => {
    const modal = Modal.confirm({
      centered: true,
      width: 600,
      title: t('Save the change?'),
      content:
        t('If you move/close tab without saving, the changes you made earlier will be lost'),
      okText: t('Agree'),
      okButtonProps: { type: "primary" },
      cancelText: t('Cancel'),
      onOk() {
        handleBackLink();
        modal.destroy();
      },
      onCancel() {
        modal.destroy();
      },
    });
  }

  const handleClose = () => {
    ModalWarningChange();
  };

  return (
    <ContentStyled>
      <Spin spinning={load}>
        <div className="image">
          <Upload
            name="File"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={BASE_API_URL + "api/v1/upload"}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%", height: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div className="infor">
          <Form
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            // validateMessages={validateMessages}
            form={form}
            onValuesChange={handleChangeFields}
          >
            <Form.Item name="fullName" label={t('Full Name')}
              rules={[
                {
                  required: true,
                  message: t('Fullname is required'),
                },
                { min: 4, message: t('Full name must be between 4-50 characters in length') },
                { max: 50, message: t('Full name must be between 4-50 characters in length') },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item label={t('Email')} name="email"
              rules={[
                { required: true, message: t('Please input email') },
                {
                  type: 'email',
                  message: t('Invalid email format'),
                }]}
            >
              <Input disabled={true} />
            </Form.Item>

            {!artistData.isAdmin && (
              <Form.Item label={t('Labels')} name="userIds"
                rules={[
                  {
                    required: true,
                    message: t('Label is required'),
                  }]}>
                <Select
                  showSearch
                  showArrow={true}
                  allowClear
                  placeholder={t('Select Label name')}
                  onChange={handleChangeArtists}
                  {...selectProps}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataLabel.map((item) => {
                    const { userId, labelName } = item;

                    return (
                      <Select.Option value={userId} key={userId}>
                        {labelName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item label={t('Birth date')} name="birthday">
              <DatePicker
                locale={i18n.language === 'vi' ? localeVi : localeEn}
                placeholder={t('Select birth date')}
                disabledDate={disabledDate}
                format="DD/MM/YYYY"
              />
            </Form.Item>

            <Form.Item name="gender" label={t('Gender')}>
              <Select>
                <Select.Option value={1}>{t('Male')}</Select.Option>
                <Select.Option value={2}>{t('Female')}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              label={t('Phone number')}
              rules={[
                {
                  required: true,
                  message: t('Phone number is required'),
                },
                { max: 20, message: t('Invalid phone number format') },
              ]}
            >
              <Input placeholder={t('Input user phone number')} />
            </Form.Item>

            <Form.Item name="address" label={t('Address')}
              rules={[
                { max: 255, message: t('Address information should not exceed 255 characters') },
              ]}
            >
              <Input.TextArea rows="4" />
            </Form.Item>

            <Form.Item
              className="action_btn"
            >
              <button
                type="submit"
                className="btn primary-btn"
                disabled={loadingUpdateInfor}
              >
                {t('Update')}
              </button>

              <Modal
                key="update-info"
                title={t('Please confirm')}
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <button
                    className="btn outline-btn"
                    type="button"
                    key="back"
                    onClick={handleCancel}
                  >
                    {t('Cancel')}
                  </button>,
                  <button
                    key="submit"
                    type="submit"
                    onClick={handleOk}
                    className="btn primary-btn"
                    disabled={antimul}
                  >
                    {t('Agree')}
                  </button>,
                ]}
              >
                <p>{t('Are you sure you want to reset this user password?')}</p>
              </Modal>

              <button
                type="button"
                className="btn outline-btn"
                onClick={showModal}
                disabled={visible}
              >
                {t('Reset password')}
              </button>
              <button
                type="button"
                className="btn close-btn"
                onClick={handleClose}
              >
                {t('Close')}
              </button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </ContentStyled>
  );
};

export default ChangeInfomation;

const ContentStyled = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    height: 200px;
    width: 200px;
    text-align: center;
    margin-top: 25px;
    object-fit: cover;
  }
  .image {
    flex-basis: 30% !important;
    text-align: center;
  }
  .ant-spin-container {
    display: flex;
  }
  .infor {
    flex: 1;
    .ant-form-item-control-input-content {
      text-align: left !important;
    }
    .ant-form-item {
      justify-content: unset !important;
    }
  }
  button {
    margin-bottom: 30px;
  }
  .ant-form-item-label {
    font-weight: 500;
    font-size: 20px;
  }
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .button-password {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }

  .close-btn {
    border: #ccc;
    background-color: #ccc;
  }

  .action_btn .ant-form-item-control-input-content {
    display: flex;
    gap: 15px;
  }

  .action_btn .ant-form-item-control-input-content button {
    flex: 1 1;
  }

  @media (max-width: 980px) {
    .ant-upload.ant-upload-select-picture-card {
      height: 140px;
      width: 140px;
    }
  }
`;
