import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APiTinhTrang = {
  LIST_TINH_TRANG: `${BASE_API_URL_CAP_DIEN}/tinh-trang/list`,
  SYNC_TINH_TRANG: `${BASE_API_URL_CAP_DIEN}/tinh-trang/sync`,
  GET_TINH_TRANG: `${BASE_API_URL_CAP_DIEN}/tinh-trang/get`,
  CREATE_TINH_TRANG: `${BASE_API_URL_CAP_DIEN}/tinh-trang/create`,
  UPDATE_TINH_TRANG: `${BASE_API_URL_CAP_DIEN}/tinh-trang/update`,
  REMOVE_TINH_TRANG: `${BASE_API_URL_CAP_DIEN}/tinh-trang/delete`,
};
