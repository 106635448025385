import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIToDoi = {
  LIST_DOI: `${BASE_API_URL_CAP_DIEN}/doi/list`,
  SYNC_DOI: `${BASE_API_URL_CAP_DIEN}/doi/sync`,
  CREATE_DOI: `${BASE_API_URL_CAP_DIEN}/doi/create`,
  UPDATE_DOI: `${BASE_API_URL_CAP_DIEN}/doi/update`,
  REMOVE_DOI: `${BASE_API_URL_CAP_DIEN}/doi/delete`,
  GET_DOI: `${BASE_API_URL_CAP_DIEN}/doi/get`,
};
