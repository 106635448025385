import { Button, Modal, Popover } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authPostData } from "@utils/request";
import AvatarDefault from "@assets/images/Frame 3019.png";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { logoutSuccess } from "../../redux/Auth/authReducer";


const AccountInfo = (props) => {
    const { profile, payloaded } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isAdmin } = useSelector((state) => state.auth);

    const [openPopover, setOpenPopover] = useState(false);
    const handleOpenChange = () => {
        setOpenPopover(!openPopover);
    };

    const handlOpenModalEditProfile = () => {
        setOpenPopover(false);
    };

    const onClickLogout = () => {
        authPostData({
            url: "api/v1/user/logout",
            method: "POST",
            payload: payloaded,
            setLoading: () => { },
            setImportLoading: () => { },
        });
        dispatch(logoutSuccess());

    };

    return (
        <Popover
            onOpenChange={handleOpenChange}
            open={openPopover}
            title={t('Account information')}
            trigger="click"
            placement="bottomRight"
            content={
                <ProfileContent>
                    <div className="account-info">
                        <div className="text-center avatar-user">
                            {profile.avatarUrl !== null ? (
                                <img src={profile.avatarUrl} alt="avatar" width="100px" height="100px" />
                            ) : (
                                <img src={AvatarDefault} alt="avatar" width="100px" height="100px" />
                            )}
                        </div>
                        <div className="text-center user-name">{profile.fullName}</div>
                        <NavLink style={{ color: "#fff" }} to={isAdmin ? "/admin/profile" : "/user/profile"}>
                            <Button
                                className="info-user bg-main"
                                onClick={handlOpenModalEditProfile}
                                type="primary"
                            >
                                {t('Change information')}
                            </Button>
                        </NavLink>

                        <Button className="btn-logout" onClick={onClickLogout}>
                        {t('Logout')}
                        </Button>
                    </div>
                </ProfileContent>
            }
            arrow={false}
        >
            <ProfileWrapper>
                <div className="login-avatar">
                    {profile.avatarUrl !== null ? (
                        <img src={profile.avatarUrl} alt="avatar" width="36px" height="36px" />
                    ) : (
                        <img src={AvatarDefault} alt="avatar" width="36px" height="36px" />
                    )}
                </div>
            </ProfileWrapper>
        </Popover>
    );
};
export default AccountInfo;

const ProfileWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    :hover {
        cursor: pointer;
    }

    .login-avatar {
        margin-left: 10px;
    }
`;
const ProfileContent = styled.div`
  .user-name {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .account-info {
    width: 160px;
    text-align: center;
  }
  .account-info .avatar-user img {
    display: inline;
  }
  .info-user {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10px auto;
  }
  .btn-logout {
    background-color: #ff0000;
    color: #fff;
  }
  .ant-btn {
    font-size: 12px;
  }
`;
