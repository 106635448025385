import {
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import { BASE_API_URL, gender } from "@utils/constants";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import {
  beforeUpload,
  handlePagination,
  mapLanguageToCulture,
  removeAccents,
} from "@utils/function";
import { authGetData, authPostData, authPutData } from "@utils/request";
import {
  Button,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Space,
  Spin,
  Upload,
  Select,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Table,
  Checkbox,
  Switch,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ModalSocialLink from "./Modal/ModalSocialLink";
import ModalDeleteLink from "./Modal/ModalDeleteLink";
import { useSelector } from "react-redux";
const { Option } = Select;

const AlbumArtistMain = () => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const { isAdmin } = useSelector((state) => state.auth);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [total, setTotal] = useState();
  const [dataCountryAlbum, setDataCountryAlbum] = useState([]);
  const [load, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [state, setState] = useState({ imageUrl: null, loading: false });
  const [dataBank, setDataBank] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataLink, setDataLink] = useState([]);
  const [dataLinkDetail, setDataLinkDetail] = useState({});
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const [contracts, setContracts] = useState([]);
  const [loadingUploadFilePdf, setLoadingUploadFilePdf] = useState(false);

  const [visibleSocialLink, setVisibleSocialLink] = useState(false);
  const [visibleDeleteLink, setVisibleDeleteLink] = useState(false);
  const [index, setIndex] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t("Success"),
        description: t("Upload photo successful"),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setState({
        imageUrl: info.file.response.data,
        loading: false,
      });
    }
  };

  const getData = useCallback(() => {
    authPostData({
      url: `api/v1/artist/show-particular-artist?culture=${culture}`,
      method: "POST",
      payload: { ...filterConditions },
      setLoading: setLoadingList,
      setImportLoading: () => { },
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataCountryAlbum(
            res.data.map((e, index) => ({
              ...e,
              no:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
    });
  }, [filterConditions, culture]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions, culture]);

  const onChangeSwitch = (e, record) => {
    const payload = {
      ...record,
      isHideDisplay: !e,
    };
    authPutData({
      url: `api/v1/artist/update-artist?culture=${culture}`,
      method: "PUT",
      payload,
      setLoading: setLoadingList,
      onSuccess: (res) => {
        if (res?.statusCode === 200) {
          notification.open({
            message: t("Success"),
            description: t("Update successful"),
            icon: <SmileOutlined style={{ color: "#10e910" }} />,
          });
          setIsModalEdit(false);
          getData();
        }
      },
    });
  };

  const columns = [
    {
      title: "",
      render: (record) => (
        <Space size="middle">
          <Switch
            checked={!record.isHideDisplay}
            onChange={(e) => onChangeSwitch(e, record)}
          />
        </Space>
      ),
      align: "center",
      hidden: !isAdmin,
    },
    {
      title: t("No."),
      dataIndex: "no",
      align: "center",
      width: "4%",
    },
    {
      title: t("Artist name"),
      dataIndex: "artistName",
      render: (text, record) => {
        const defaultLink = record.link.find((item) => item.isDefault)?.link;

        return (
          <>
            {record?.link.length > 0 ? (
              <a
                href={defaultLink}
                style={{ color: "#1890ff", cursor: "pointer" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </a>
            ) : (
              <p>{text}</p>
            )}
          </>
        );
      },
      align: "left",
      width: "25%",
      className: "min-width-180",
    },
    {
      title: t("Email"),
      dataIndex: "email",
      align: "left",
      width: "25%",
      className: "min-width-180",
    },
    {
      title: t("Avatar"),
      render: (record) =>
        record.imageUrl ? (
          <img
            src={BASE_API_URL + record.imageUrl}
            alt="ImageUrl"
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
          />
        ) : (
          <p style={{ color: "red" }}>{t("No Photo")}</p>
        ),
      align: "center",
      width: "15%",
      className: "min-width-100",
    },
    {
      title: t("Gender"),
      dataIndex: "gender",
      render: (record) => {
        if (record === gender[0].key) {
          return <span>{t("Male")}</span>;
        }
        if (record === gender[1].key) {
          return <span>{t("Female")}</span>;
        }
        return null;
      },
      align: "center",
      width: "15%",
    },
    {
      title: t("Custom..."),
      render: (record) => (
        <Space size="middle">
          {record?.canEdit && (
            <EditOutlined
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => showEditModal(record)}
            />
          )}
        </Space>
      ),
      align: "center",
    },
  ].filter((column) => !column.hidden);

  const showEditModal = (record) => {
    authGetData({
      url: `api/v1/artist/show-artist/${record.artistId}?culture=${culture}`,
      setLoading: setLoadingList,
      setImportLoading: () => { },
      onSuccess: (res) => {
        const title =
          t("Artists information update:") + `${res?.data?.artistName}`;
        setModalTitle(title);
        setDataOriginal(res?.data);
        setState({
          imageUrl: res?.data?.imageUrl,
          loading: false,
        });
        setDataLink(res?.data?.link);
        setContracts(res?.data?.contracts);
      },
    });
    setIsModalEdit(true);
  };

  useEffect(() => {
    const dataForm = {
      ...dataOriginal,
      birthday: dataOriginal?.birthday ? moment(dataOriginal?.birthday) : null,
      startDate: dataOriginal?.startDate
        ? moment(dataOriginal?.startDate)
        : null,
      bankAccount: dataOriginal?.bankAccount
        ? dataOriginal?.bankAccount.toUpperCase()
        : null,
    };
    form2.setFieldsValue(dataForm);
  }, [dataOriginal, form2]);

  useEffect(() => {
    if (isModalEdit) {
      authGetData({
        url: `api/v1/artist/get-bank?culture=${culture}`,
        setLoading,
        setImportLoading: () => { },
        onSuccess: (res) => {
          setDataBank(res);
        },
      });
    }
    setDataBank([]);
  }, [culture, isModalEdit]);

  const toggleCheckBox = (index) => {
    const newDataLink = dataLink.map((item, i) => ({
      ...item,
      isDefault: i === index,
    }));
    setDataLink(newDataLink);
  };

  const columnsLink = [
    {
      title: t("Default"),
      dataIndex: "isDefault",
      render: (text, record, index) => (
        <Checkbox
          checked={record?.isDefault}
          onChange={() => toggleCheckBox(index)}
        />
      ),
      width: "70px",
      align: "center",
    },
    {
      title: t("Stores"),
      dataIndex: "platform",
      className: "column-link",
      width: "110px",
    },
    {
      title: "Link",
      dataIndex: "link",
      className: "column-link",
      width: "260px",
    },
    {
      title: t("Custom..."),
      width: "70px",
      render: (text, record, index) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleEditSocialLink(text, record, index)}
          />
          <DeleteOutlined
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDeleteSocialLink(text, record, index)}
          />
        </Space>
      ),
    },
  ];

  const handleAddSocialLink = useCallback(() => {
    setVisibleSocialLink(true);
  }, []);

  const handleEditSocialLink = (text, record, index) => {
    setDataLinkDetail(record);
    setIndex(index);
    setVisibleSocialLink(true);
  };

  const handleDeleteSocialLink = (text, record, index) => {
    setDataLinkDetail(record);
    setIndex(index);
    setVisibleDeleteLink(true);
  };

  const handleOk = (values) => {
    const payload = {
      artistId: dataOriginal.artistId,
      ...values,
      birthday: values.birthday
        ? moment(values.birthday).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      imageUrl: state.imageUrl,
      contracts: contracts,
      startDate: values.startDate
        ? moment(values.startDate).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      link: dataLink,
      isHideDisplay: dataOriginal.isHideDisplay,
    };
    authPutData({
      url: `api/v1/artist/update-artist?culture=${culture}`,
      method: "PUT",
      payload,
      setLoading: setLoadingList,
      onSuccess: (res) => {
        if (res?.statusCode === 200) {
          notification.open({
            message: t("Success"),
            description: t("Update successful"),
            icon: <SmileOutlined style={{ color: "#10e910" }} />,
          });
          setIsModalEdit(false);
          getData();
        }
      },
    });
  };

  const ModalWarningChange = () =>
    Modal.confirm({
      centered: true,
      width: 600,
      title: t("Save the change?"),
      content: t(
        "If you move/close tab without saving, the changes you made earlier will be lost"
      ),
      onOk() {
        setIsModalEdit(false);
        setDataOriginal([]);
        setState({
          imageUrl: null,
        });
        setContracts([]);
        form2.resetFields();
      },
      onCancel() {
        navigate("/admin/artist-management");
      },
      okText: t("Agree"),
      okButtonProps: { type: "primary" },
      cancelText: t("Cancel"),
    });

  const handleCancel = () => {
    ModalWarningChange();
  };

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };
  const { loading, imageUrl } = state;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("Upload image")}</div>
    </div>
  );

  const handleClick = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  const handleReset = () => {
    form1.resetFields();
    setFilterConditions({
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const pattern =
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
    const isValidPattern = pattern.test(value);
    const isValidLength = value.length >= 7 && value.length <= 20;

    if (isValidPattern && isValidLength) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("Invalid phone number format"));
    }
  };

  //remove file
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeFile = (indexToDelete) => {
    const updatedContracts = contracts.filter(
      (_, index) => index !== indexToDelete
    );
    setContracts(updatedContracts);
  };

  const beforeUploadPdf = (file) => {
    const isTypePdf =
      file.name.toLowerCase().endsWith() === "pdf" ||
      file.type === "application/pdf";
    if (!isTypePdf) {
      notification.error({
        message: t("Notification"),
        description: t("The only permitted file format for download is .pdf"),
      });
    }
    return isTypePdf;
  };

  // View file contract

  const viewFile = (item) => {
    window.open(`${item.contractLink}`);
  };

  //render file đã upload

  const renderFile = useCallback(() => {
    if (contracts?.length) {
      return (
        <ul>
          {contracts.map((item, index) => (
            <li key={index}>
              <div
                key={item}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "96%",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => viewFile(item, index)}
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(null)}
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    color: "#1890ff",
                    alignItems: "center",
                    textDecoration: isHovered === index ? "underline" : "none",
                  }}
                >
                  {item.contractName}
                </div>
                <div
                  onClick={() => removeFile(index)}
                  style={{ cursor: "pointer" }}
                >
                  <DeleteOutlined />
                </div>
              </div>
            </li>
          ))}
        </ul>
      );
    } else return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts, removeFile]);

  const handleFileChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingUploadFilePdf(false);
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t("Success"),
        description: t("Uploaded contract successfully"),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setContracts([
        ...contracts,
        {
          id: null,
          contractName: info.file.response.data.fileName,
          contractLink: info.file.response.data.linkFile,
        },
      ]);
    }
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div className="select-option">
          <Form form={form1} onFinish={handleClick}>
            <Row gutter={[20, 20]}>
              <Col xl={6} lg={8} md={12}>
                <FormItem name="artists">
                  <Input placeholder={t("Input artist name/email")} />
                </FormItem>
              </Col>
              <Col xl={6} lg={8} md={12}>
                <FormItem label={t("Gender")} name="gender">
                  <Select placeholder={t("All")}>
                    <Select.Option value="Nam">{t("Male")}</Select.Option>
                    <Select.Option value="Nữ">{t("Female")}</Select.Option>
                  </Select>
                </FormItem>
              </Col>
              <Col xl={6} lg={8} md={12}>
                <FormItem label={t("Displayed status")} name="status">
                  <Select placeholder={t("All")}>
                    <Select.Option value={true}>{t("Disable")}</Select.Option>
                    <Select.Option value={false}>{t("Enable")}</Select.Option>
                  </Select>
                </FormItem>
              </Col>
              <Col xl={6} lg={8} md={24}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Artist list")}</div>
            </div>

            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  defaultCurrent={filterConditions.pageIndex}
                  rowKey="key"
                  dataSource={dataCountryAlbum}
                  columns={columns}
                  totalData={total}
                  loading={loadingList}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                />
              </ConfigProvider>

              <Modal
                title={modalTitle}
                open={isModalEdit}
                footer={null}
                centered
                onCancel={handleCancel}
                width={900}
                bodyStyle={{ maxHeight: "450px", overflowY: "scroll" }}
              >
                <Styled>
                  <Form
                    form={form2}
                    layout="vertical"
                    onFinish={handleOk}
                    id="modal-form"
                  >
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item name="imageUrl">
                          <Upload
                            name="File"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={
                              BASE_API_URL + `api/v1/upload?culture=${culture}`
                            }
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                          >
                            {imageUrl ? (
                              <img
                                src={BASE_API_URL + imageUrl}
                                alt="avatar"
                                style={{ width: "100%", height: "100%" }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Row gutter={20}>
                          <Col span={12}>
                            <Form.Item
                              label={t("Artist name")}
                              name="artistName"
                              rules={[
                                {
                                  required: true,
                                  message: t("Input stage name of artist"),
                                },
                              ]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("Email")}
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: t("Please input email"),
                                },
                                {
                                  type: "email",
                                  message: t("Invalid email format"),
                                },
                              ]}
                            >
                              <Input placeholder={t("Input artist email")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("Birth date")} name="birthday">
                              <DatePicker
                                locale={
                                  i18n.language === "vi" ? localeVi : localeEn
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("Gender")}
                              name="gender"
                              rules={[
                                {
                                  required: true,
                                  message: t("Select artist of gender"),
                                },
                              ]}
                            >
                              <Select placeholder={t("Select gender")}>
                                <Select.Option value="Nam">
                                  {t("Male")}
                                </Select.Option>
                                <Select.Option value="Nữ">
                                  {t("Female")}
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("Phone number")}
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: t("Phone number is required"),
                                },
                                { validator: validatePhoneNumber },
                              ]}
                            >
                              <Input
                                placeholder={t("Input contect phone number")}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("Joining date")}
                              name="startDate"
                            >
                              <DatePicker
                                locale={
                                  i18n.language === "vi" ? localeVi : localeEn
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={24}>
                        <Form.Item label={t("Address")} name="address">
                          <TextArea
                            rows={1}
                            placeholder={t("Input contact address")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label={t("Contract file")}>
                          <Upload
                            name="File"
                            showUploadList={false}
                            action={BASE_API_URL + `api/v1/artist/up-file-pdf`}
                            beforeUpload={beforeUploadPdf}
                            onChange={handleFileChange}
                            multiple={false}
                            loading={loadingUploadFilePdf}
                            disabled={loadingUploadFilePdf}
                            className="upload-file"
                            style={{ width: "100%", display: "block" }}
                          >
                            <Button
                              style={{ width: "100%" }}
                              icon={<UploadOutlined />}
                            >
                              Chọn hoặc kéo thả để tải file (*.pdf)
                            </Button>
                          </Upload>
                          {renderFile()}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={t("Bank name")} name="bankName">
                          <Select
                            placeholder={t("Select bank name")}
                            showSearch
                            showArrow={true}
                            allowClear={true}
                            style={{ width: "100%" }}
                            filterOption={(input, option) => {
                              if (option && option.children) {
                                return (
                                  removeAccents(option.children)
                                    .toLowerCase()
                                    .indexOf(
                                      removeAccents(input).toLowerCase()
                                    ) >= 0
                                );
                              }
                            }}
                          >
                            {dataBank?.length
                              ? dataBank.map((item) => (
                                <Option key={item.name} value={item.name}>
                                  {item.name}
                                </Option>
                              ))
                              : undefined}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={t("Account number")}
                          name="bankNumber"
                          rules={[
                            {
                              validator: (rule, value, callback) => {
                                const regex = /^([0-9]\d*)$/; // Chỉ chấp nhận số và số 0 có thể đứng đầu
                                if (value && !regex.test(value)) {
                                  callback(t("Account number invalid"));
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                        >
                          <Input placeholder={t("Input account number")} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={t("Beneficiary name")}
                          name="bankAccount"
                          wrap="on"
                        >
                          <Input
                            placeholder={t("Input beneficiary name")}
                            onChange={(e) =>
                              form2.setFieldsValue({
                                bankAccount: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={24}>
                        <Form.Item label={t("Link list")}>
                          <Button
                            className="btn-add-link"
                            onClick={handleAddSocialLink}
                          >
                            {t("Add Link")}
                          </Button>
                        </Form.Item>
                        {dataLink && Object.keys(dataLink).length > 0 && (
                          <div className="table-link">
                            <Table
                              dataSource={dataLink}
                              columns={columnsLink}
                              bordered
                              pagination={false}
                            />
                          </div>
                        )}
                        <div className="btn-action">
                          <Space key="update-album-thumb">
                            <Button htmlType="button" onClick={handleCancel}>
                              {t("Cancel")}
                            </Button>
                            <Button
                              htmlType="submit"
                              type="primary"
                              loading={loading}
                            >
                              {t("Update")}
                            </Button>
                          </Space>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Styled>
              </Modal>
              {visibleSocialLink && (
                <ModalSocialLink
                  visibleSocialLink={visibleSocialLink}
                  setVisibleSocialLink={setVisibleSocialLink}
                  dataLinkDetail={dataLinkDetail}
                  setDataLinkDetail={setDataLinkDetail}
                  dataLink={dataLink}
                  setDataLink={setDataLink}
                  index={index}
                  setIndex={setIndex}
                />
              )}
              {visibleDeleteLink && (
                <ModalDeleteLink
                  visibleDeleteLink={visibleDeleteLink}
                  setVisibleDeleteLink={setVisibleDeleteLink}
                  dataLinkDetail={dataLinkDetail}
                  setDataLinkDetail={setDataLinkDetail}
                  dataLink={dataLink}
                  setDataLink={setDataLink}
                  index={index}
                  setIndex={setIndex}
                />
              )}
            </div>
          </div>
        </div>
      </ContentStyled>
    </Spin>
  );
};

export default AlbumArtistMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }
  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }
  .ant-row {
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-select {
    display: block;
  }

  @media (max-width: 992px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;

const Styled = styled.div`
  #modal-form {
    display: block;
    width: 100%;
  }
  .avatar-uploader .ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    margin: 8px 0 0 32px;
  }
  .ant-form-item {
    margin: 0 0 12px !important;
  }
  .ant-form-item-label {
    white-space: pre-wrap;
    text-align: left;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .btn-action {
    white-space: pre-wrap;
    text-align: right;
    margin: 20px 0;
  }
  .btn-field {
    color: blue;
  }
  .table-link {
    margin-bottom: 10px;
  }
  .table-link Table {
    table-layout: fixed !important;
  }
  .table-link .column-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .btn-add-link {
    width: 100%;
  }

  .upload-file .ant-upload.ant-upload-select {
    display: inline-block;
    width: 100% !important;
  }

  @media (max-width: 800px) {
    .avatar-uploader .ant-upload-select-picture-card {
      width: 180px;
      height: 180px;
      margin: 16px 0 0 20px;
    }
  }
`;
