import { AutoComplete, Button, Checkbox, Form, Input, Modal, Select, Space, Spin } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture, removeAccents } from "@utils/function";
import { authGetData, authPostData, authPutData } from "@utils/request";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
const { Option } = AutoComplete;

export default function ModalSocialLink({ visibleSocialLink, setVisibleSocialLink, dataLinkDetail, setDataLinkDetail, dataLink, setDataLink, index, setIndex }) {
    const { t, i18n } = useTranslation();
    const savedLanguage = localStorage.getItem("selectedLanguage");
    const culture = mapLanguageToCulture();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const [value, setValue]=useState('');
    const onChange = (data)=>{setValue(data);};
    const [valueSearch, setValueSearch] = useState("")

    useEffect(() => {
        authGetData({
            url: `api/v1/overview/filter/platforms?culture=${culture}`,
            setLoading,
            onSuccess: (res) => {
                if (res.statusCode === 201) {
                    setPlatformData(res.data);
                }
            },
        });
    }, []);

    useEffect(() => {
        if (dataLinkDetail) {
            form.setFieldsValue({
                platform: dataLinkDetail?.platform,
                link: dataLinkDetail?.link,
                isDefault: dataLinkDetail?.isDefault,
            });
        }
    }, [form, dataLinkDetail]);

    const onFinish = useCallback(
        (values) => {
            if (Object.keys(dataLinkDetail).length > 0) {
                const dataUpdate = { ...values, isDefault: dataLinkDetail?.isDefault }
                const newDataLink = [...dataLink];
                newDataLink.splice(index, 1, dataUpdate);
                setDataLink(newDataLink);
                handleCancel();
            } else {
                if (dataLink?.length > 0) {
                    const dataAdd = { ...values, isDefault: false }
                    const newDataLink = [...dataLink];
                    newDataLink.push(dataAdd);
                    setDataLink(newDataLink);
                    handleCancel();
                } else {
                    const dataAdd = { ...values, isDefault: true }
                    const newDataLink = [...dataLink];
                    newDataLink.push(dataAdd);
                    setDataLink(newDataLink);
                    handleCancel();
                }
            }
        },
        [dataLinkDetail, dataLink, setDataLink, index]
    );

    const handleCancel = () => {
        setIndex(null);
        setDataLinkDetail({});
        setVisibleSocialLink(false)
    }

    return (
        <Modal
            title={Object.keys(dataLinkDetail).length > 0 ? t('Update Link') : t('Add Link')}
            open={visibleSocialLink}
            maskClosable={false}
            onCancel={handleCancel}
            footer={null}
            width={600}
        >
            <Spin spinning={loading}>
                <Styled>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            name="platform"
                            label={t('Stores')}
                            rules={[
                                {
                                    required: true,
                                    message: t('This field is required'),
                                },
                            ]}
                        >
                            <AutoComplete
                                onChange={onChange}
                                onSearch={(text)=>{setValueSearch(text)}}
                                style={{ width: "100%" }}
                                filterOption={(input, option) => {
                                    if (option && option.children) {
                                        return (
                                            removeAccents(option.children)
                                                .toLowerCase()
                                                .indexOf(removeAccents(input).toLowerCase()) >= 0
                                        );
                                    }
                                }}
                            >
                                {platformData?.length
                                    ? platformData.map((item) => (
                                        <Option key={item.platformName} value={item.platformName}>
                                            {item.platformName}
                                        </Option>
                                    ))
                                    : undefined}
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            name="link"
                            label="Link"
                            rules={[
                                {
                                    required: true,
                                    message: t('This field is required')
                                },
                                // {
                                //     validator: (_, value) =>
                                //         value && value.trim() === ""
                                //             ? Promise.reject(new Error("Không được để trống"))
                                //             : Promise.resolve(),
                                // },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item className="btn-submit">
                            <Button htmlType="button" onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button type="primary" htmlType="submit">
                                {Object.keys(dataLinkDetail).length > 0 ? t('Update') : t('Add')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Styled>
            </Spin>
        </Modal>
    );
}

const Styled = styled.div`
    .btn-submit .ant-form-item-control-input-content {
        display: flex;
        justify-content: end;
        gap: 10px;
    }
    .btn-submit .ant-btn {
        margin-bottom: 20px !important;
    }
`;