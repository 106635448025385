import React from "react";
import styled from "styled-components";
import { BASE_API_URL, } from "@utils/constants";
import { CustomerServiceOutlined } from "@ant-design/icons";

const CartItemComponent = (props) => {
    const { item, index, name, totalView, isMiniIcon = true, isFlag = false } = props
    const Linking = styled.a``;

    const formatNumber = (number) => {
        return number
            ? number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            : "0"
    }

    return (
        <CardItem>
            <Linking style={{ color: "#000" }} href={item.link} target="_blank">
                <CardDetail>
                    <div className="info-image">
                        <strong style={{
                            minWidth: "50px",
                            display: "flex",
                            justifyContent: "center"
                        }}>{index + 1}.</strong>
                        <div style={{ position: "relative" }}>
                            <img className={`img-background ${isFlag ? "image-flag" : ""}`} src={isFlag ? BASE_API_URL + item.flagUrl : BASE_API_URL + item.imageUrl} alt={item.name} />
                            {
                                isMiniIcon &&
                                <div className="icon">
                                    <img src={item.linkImagesPlatform} alt="icon" />
                                </div>
                            }
                        </div>
                        <strong>{name}</strong>
                    </div>
                    <div style={{ flexWrap: "nowrap", display: "flex" }}>
                        <CustomerServiceOutlined />
                        <strong style={{ marginLeft: "5px" }}>{formatNumber(totalView)}</strong>
                    </div>
                </CardDetail>
            </Linking>
        </CardItem >
    );
};

export default CartItemComponent;

const CardItem = styled.div`
    padding: 10px;
    border-radius: 4px;

    :hover {
        background-color: rgb(160 178 207);
    }
`

const CardDetail = styled.div`
    width: 100%;
    height: 50px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .info-image {
        display: flex;
        align-items: center;
        
        .img-background {
            height: 50px;
            width: 50px;
            object-fit: cover;
            margin: 0 10px;
            border-radius: 4px;
        }

        .image-flag {
            width: 70px !important;
        }
        
        .icon {
            position: absolute;
            bottom: -3px;
            right: 7px;
            img {
                height: 16px;
                width: 16px;
            }
        }
    }
`
