import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIImei = {
  LIST_IMEI: `${BASE_API_URL_CAP_DIEN}/imei/list`,
  SYNC_IMEI: `${BASE_API_URL_CAP_DIEN}/imei/sync`,
  CREATE_IMEI: `${BASE_API_URL_CAP_DIEN}/imei/create`,
  UPDATE_IMEI: `${BASE_API_URL_CAP_DIEN}/imei/update`,
  REMOVE_IMEI: `${BASE_API_URL_CAP_DIEN}/imei/delete`,
  GET_IMEI: `${BASE_API_URL_CAP_DIEN}/imei/get`,
  LIST_NGUOI_DUNG: `${BASE_API_URL_CAP_DIEN}/imei/list-nguoi-dung`,
};
