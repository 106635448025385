import { authDeleteData, authGetData } from "@utils/request";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Input,
  Row,
  Space,
  Table,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DEFAULT_PAGESIZE, PAGE_SIZE_OPTIONS, SETTING, STATUS_CODE_200 } from "@utils/constants";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { alertMessage, mapLanguageToCulture } from "@utils/function";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ModalCreateOrUpdateSpin from "./ModalCreateOrUpdateSpin";
import Confirm from "@components/Layout/ModalConfirm";

const mappingAction = {
  view: "View",
  create: "Create",
  edit: "Edit",
};

const SpinSettings = ({ data }) => {
  const culture = mapLanguageToCulture();
  const { t, i18n } = useTranslation();
  const [spinData, setSpinData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [actionForm, setActionForm] = useState(mappingAction.view);
  const [exRateData, setExRateData] = useState(undefined);
  const [form] = Form.useForm();

  const getData = () => {
    authGetData({
      url: `api/mobile/v1/spin/spins`,
      method: "GET",
      setLoading: setIsLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setSpinData(res?.data || []);
        } else {
        }
      },
    });
  };

  const getDataExchangeRate = () => {
    authGetData({
      url: `api/v1/admin/settings?culture=${culture}`,
      method: "GET",
      setLoading: setIsLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          res.data.map((item) => {
            if (item.key === SETTING.ex_rate) {
              setExRateData(item);
            }
          });
        } else {
        }
      },
    });
  };

  useEffect(() => {
    getData();
    getDataExchangeRate();
  }, []);

  const toggleModal = (act, record, isReload) => {
    setActionForm(act);
    if (act === mappingAction.edit) {
      setCurrentRecord(record);
    } else {
      setCurrentRecord(null);
    }
    if (isReload) {
      getData();
    }
  };

  const handleConfirm = (id) => {
    const textModal = {
      title: t("Please confirm"),
      content: t("Confirm delete spin"),
      okText: t("Agree"),
      cancelText: t("Cancel"),
      onOk: () => {
        handleDelete(id);
      },
      className: "modal-delete",
      t,
    };
    Confirm(textModal);
  };

  const handleDelete = (id) => {
      authDeleteData({
        url: `api/mobile/v1/spin?id=${id}`,
        method: "DELETE",
        setLoading: setIsLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUS_CODE_200) {
            alertMessage(
              "success",
              t("Success"),
              t("Delete successful")
            );
            getData();
          } else {
            alertMessage("error", t("Error"), res.message);
          }
        },
      });
    };

  const columns = [
    {
      title: t("No."),
      dataIndex: "id",
      width: "4%",
      render: (record, item) => (
        <span>{spinData.findIndex((spin) => spin?.id === item?.id) + 1}</span>
      ),
    },
    {
      title: t("Vietnamese name"),
      dataIndex: "name",
      render: (record, item) => (
        <Space className="spin-table-column">{item.name}</Space>
      ),
      width: "24%",
    },
    {
      title: t("English name"),
      dataIndex: "nameEn",
      render: (record, item) => (
        <Space className="spin-table-column">{item.nameEn}</Space>
      ),
      width: "24%",
    },
    {
      title: t("Start day"),
      dataIndex: "startDate",
      render: (record, item) => (
        <Space className="spin-table-column">
          {moment(item.startDate).format("YYYY-MM-DD HH:mm:ss")}
        </Space>
      ),
      width: "18%",
    },
    {
      title: t("End day"),
      dataIndex: "endDate",
      render: (record, item) => (
        <Space className="spin-table-column">
          {moment(item.endDate).format("YYYY-MM-DD HH:mm:ss")}
        </Space>
      ),
      width: "18%",
    },
    {
      title: t("Custom..."),
      render: (record, item) => (
        <Space className="spin-table-column" style={{ textAlign: "center" }}>
          <EditOutlined
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => toggleModal(mappingAction.edit, item)}
          />
          <DeleteOutlined 
            style={{ color: "red" }}
            onClick={() => handleConfirm(item.id)}
          />
        </Space>
      ),
      width: "12%",
    },
  ];

  const renderModalCreateOrUpdateSpin = () => {
    if (
      actionForm === mappingAction.create ||
      actionForm === mappingAction.edit
    ) {
      return (
        <ModalCreateOrUpdateSpin
          closeModal={() => toggleModal(mappingAction.view, null, true)}
          currentRecord={currentRecord}
          act={actionForm}
          mappingAction={mappingAction}
          exRateData={exRateData}
        />
      );
    } else {
      return null;
    }
  };

  const layout = {
    labelCol: { lg: 6, md: 8 },
    wrapperCol: { lg: 12, md: 15 },
  };

  return (
    <ContentStyled>
      {/* <div className="select-option spin-search">
        <Form
          {...layout}
          form={form}
          // onFinish={onFinish}
        >
          <Row gutter={[20, 20]}>
            <Col xl={17} lg={17} md={24}>
              <Form.Item label={t("File download time")} name="time">
                <DatePicker picker="year" />
              </Form.Item>
            </Col>
            <Col xl={7} lg={7} md={11}>
              <div className="action-buttons">
                <Button>{t("Clear all")}</Button>
                <Button type="primary" htmlType="submit">
                  {t("Search")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>  */}
      <div className="content">
        {" "}
        <div className="header">
          <div className="title">{t("List of spin")}</div>

          <button
            className="btn primary-btn btn-create"
            onClick={() => {
              toggleModal(mappingAction.create);
            }}
          >
            {t("Add")} <PlusOutlined />
          </button>
        </div>
        <ConfigProvider
          renderEmpty={() => <Empty description={t("No Records Found")} />}
        >
          {renderModalCreateOrUpdateSpin()}
          <Table
            bordered
            rowKey="id"
            loading={isLoading}
            totalData={spinData?.length || 0}
            pagination={{
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                        defaultPageSize: DEFAULT_PAGESIZE,
            }}
            dataSource={spinData || []}
            columns={columns}
          />
        </ConfigProvider>
      </div>
    </ContentStyled>
  );
};

export default memo(SpinSettings);

const ContentStyled = styled.div`
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 10px 20px;
  }
  .btn-create {
    margin-bottom: 0px;
  }
  .spin-search {
    border: 1px solid #bfbfbf;
    padding: 0px 10px 0px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  .title {
    font-weight: 500;
    font-size: 14px;
    align-self: center;
    text-transform: uppercase;
  }
  .ant-form-item-label > label {
    display: block;
    white-space: normal;
  }

  .spin-prize-card-mt {
    margin-top: 10px;
  }
  .spin-table-column {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: 992px) {
    .ant-input {
      height: 44px;
    }
  }
`;
