import { PlatformLogos } from "@pages/PlatformLogos/PlatformLogos";
import AlbumMain from "@pages/Admin/AlbumManagement/AlbumMain";
import AlbumCountryMain from "@pages/Admin/AlbumManagement/AlbumCountryMain";
import AlbumArtistMain from "@pages/Admin/AlbumManagement/AlbumArtistMain";
import AlbumPlatformMain from "@pages/Admin/AlbumManagement/AlbumPlatformMain";
import ImportCSVMain from "@pages/Admin/ImportCSV/ImportCSVMain";
import VersionMain from "@pages/Admin/VersionManagement/VersionMain";
import LogTimeMain from "@pages/Admin/LogTimeManagement/LogTimeMain";
import OverviewMain from "@pages/Admin/Overview/OverviewMain";
import PaymentHistoryMain from "@pages/Admin/PaymentHistory/PaymentHistoryMain";
import PaymentMain from "@pages/Admin/PaymentManagement/PaymentMain";
import SettingMain from "@pages/Admin/Setting/SettingMain";
import SettingShareRateMain from "@pages/Admin/SettingShareRate/SettingShareRateMain";
import SalesReportMain from "@pages/Admin/SalesReport/SalesReportMain";
import StatisticMain from "@pages/Admin/Statistic/StatisticMain";
import CreateUser from "@pages/Admin/UserManagement/CreateUser";
import InforMain from "@pages/Admin/UserManagement/Infor/InforMain";
import UserManagementMain from "@pages/Admin/UserManagement/UserManagementMain";
import ProfileMain from "@pages/User/Profile/ProfileMain";
import { authGetData } from "@utils/request";
import { Alert, BackTop, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { IoChevronUpOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { setArtistShareRate } from "../../redux/Date/DateReducer";
import Footer from "./Footer";
import Sider from "./Sider";
import styled from "styled-components";
import HeaderMain from "./HeaderMain";
const { Content } = Layout;

export default function AdminComponent() {
  const [, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.user);
  const artistShareRate = useSelector((state) => state.date.artistShareRate);
  const location = useLocation();
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    message.destroy();

    if (
      location.pathname === "/admin/import-csv" ||
      location.pathname === "/admin/user-management" ||
      location.pathname === "/admin/album-management" ||
      location.pathname.includes("/admin/setting")
    )
      return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <StyledHeader>
        <HeaderMain />
      </StyledHeader>
      <div style={{marginTop: "53px"}}>
        <Sider />
        <Styled>
          <Content>
            <Routes>
              <Route path="/platform-logos" element={<PlatformLogos />} />
              <Route path="/profile" element={<ProfileMain />} />
              <Route path="/overview" element={<OverviewMain />} />
              <Route path="/statistic" element={<StatisticMain />} />
              <Route path="/payment-management" element={<PaymentMain />} />
              <Route path="/album-management" element={<AlbumMain />} />
              <Route path="/album-management-country" element={<AlbumCountryMain />} />
              <Route path="/album-management-platform" element={<AlbumPlatformMain />} />
              <Route path="/log-time" element={<LogTimeMain />} />
              <Route path="/artist-management" element={<AlbumArtistMain />} />
              <Route path="/version" element={<VersionMain />} />
              <Route path="/payment-history" element={<PaymentHistoryMain />} />
              <Route path="/user-management" element={<UserManagementMain />} />
              <Route path="/user-management/create-user" element={<CreateUser />} />
              <Route path="/user-management/:id" element={<InforMain />} />
              <Route path="/import-csv" element={<ImportCSVMain />} />
              <Route path="/setting" element={<SettingMain />} />
              <Route path="/sales-report" element={<SalesReportMain />} />
              <Route path="/setting-share-rate" element={<SettingShareRateMain />} />
            </Routes>

            <Footer />
          </Content>
        </Styled>
      </div>

      <BackTop style={{ zIndex: "10000" }}>
        <IoChevronUpOutline />
      </BackTop>
    </>
  );
}

const Styled = styled.div`
  .ant-layout-content {
    background-color: rgb(229, 229, 229);
    margin-left: 210px;
    height: 100%;
  }
  .ant-layout-content .ant-layout-content {
    margin-left: 0;
  }

  @media (max-width: 980px) {
    .ant-layout-content {
      margin-left: 160px;
    }
  }
`;

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`