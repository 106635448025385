import { SETTING } from "@utils/constants";
import { authGetData } from "@utils/request";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "./Common/Common";
import Label from "./Label/Label";
import CurrencyMain from "./Currency/CurrencyMain";
import EmailMain from "./Email/EmailMain";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture } from "@utils/function";
import SpinSettings from "./SpinSettings/SpinSettings";

const { TabPane } = Tabs;

const SettingMain = () => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const [loading, setLoading] = useState(false);
  const [mailData, setMailData] = useState({});
  const [labelData, setLabelData] = useState({});
  const [rateData, setRateData] = useState(null);
  const [pwData, setPwData] = useState({});
  const [feeData, setFeeData] = useState({});
  const [exRateData, setExRateData] = useState({});
  const [shRateData, setShRateData] = useState({});
  const getData = () => {
    authGetData({
      url: `api/v1/admin/settings?culture=${culture}`,
      method: "GET",
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          res.data.map((item) => {
            if (item.key === SETTING.email) {
              setMailData(JSON.parse(item.value));
            } else if (item.key === SETTING.label) {
              setLabelData(JSON.parse(item.value));
            } else if (item.key === SETTING.rate) {
              setRateData(JSON.parse(item.value));
            } else if (item.key === SETTING.password) {
              setPwData(item);
            } else if (item.key === SETTING.transaction_fee) {
              setFeeData(item);
            } else if (item.key === SETTING.share_rate) {
              setShRateData(item);
            } else if (item.key === SETTING.ex_rate) {
              setExRateData(item);
            }
          });
        } else {
        }
      },
    });
  };
  useEffect(() => {
    getData();
  }, [savedLanguage]);
  return (
    <>
      <div
        style={{
          padding: "20px",
          backgroundColor: "rgb(229, 229, 229)",
        }}
      >
        <ContentStyled>
          <Tabs defaultActiveKey="1">
            <TabPane tab={t("Common configuration")} key="1">
              <Common
                pwData={pwData}
                data={mailData}
                labelData={labelData}
                loading={loading}
                getData={getData}
              />
            </TabPane>
            <TabPane tab={t("Label configuration")} key="2">
              <Label
                pwData={pwData}
                data={mailData}
                labelData={labelData}
                loading={loading}
                getData={getData}
              />
            </TabPane>
            <TabPane tab={t("Currency configuration")} key="3">
              <CurrencyMain
                data={rateData}
                loading={loading}
                feeData={feeData}
                exRateData={exRateData}
                shRateData={shRateData}
                getData={getData}
              />
            </TabPane>
            <TabPane tab={t("Email configuration")} key="4">
              <EmailMain data={mailData} loading={loading} getData={getData} />
            </TabPane>
            <TabPane tab={t("Spin configuration")} key="5">
              <SpinSettings loading={loading} getData={getData} />
            </TabPane>
          </Tabs>
        </ContentStyled>
      </div>
    </>
  );
};

export default SettingMain;

const ContentStyled = styled.div`
  background-color: #fdfdfd;
  border-radius: 8px 8px 0px 0px;
  .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 0px 20px;
    .ant-tabs-tab-btn {
      font-weight: 500 !important;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 20px !important;
  }
  button {
    margin-bottom: 20px;
  }
`;
