import React from "react";

function DashBoardIcon({ className }) {
  return (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1.17995V18.82C9 19.46 8.41 19.94 7.79 19.8C3.32 18.8 0 14.79 0 9.99995C0 5.20995 3.32 1.19995 7.79 0.199954C8.41 0.0599543 9 0.539954 9 1.17995ZM11.03 1.17995V7.98995C11.03 8.53995 11.48 8.98995 12.03 8.98995H18.82C19.46 8.98995 19.94 8.39995 19.8 7.76995C18.95 4.00995 16 1.04995 12.25 0.199954C11.62 0.0599543 11.03 0.539954 11.03 1.17995ZM11.03 12.01V18.82C11.03 19.46 11.62 19.94 12.25 19.8C16.01 18.95 18.96 15.98 19.81 12.22C19.95 11.6 19.46 11 18.83 11H12.04C11.48 11.01 11.03 11.46 11.03 12.01Z"/>
  </svg>
  );
}

export default DashBoardIcon;