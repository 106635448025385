import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIEmail = {
  LIST_EMAIL: `${BASE_API_URL_CAP_DIEN}/email-config/list`,
  SYNC_EMAIL: `${BASE_API_URL_CAP_DIEN}/email-config/sync`,
  GET_EMAIL: `${BASE_API_URL_CAP_DIEN}/email-config/get`,
  CREATE_EMAIL: `${BASE_API_URL_CAP_DIEN}/email-config/create`,
  UPDATE_EMAIL: `${BASE_API_URL_CAP_DIEN}/email-config/update`,
  REMOVE_EMAIL: `${BASE_API_URL_CAP_DIEN}/email-config/delete`,
};
