import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIDonVi = {
  LIST_DON_VI: `${BASE_API_URL_CAP_DIEN}/don-vi/list`,
  SYNC_DON_VI: `${BASE_API_URL_CAP_DIEN}/don-vi/sync`,
  CREATE_DON_VI: `${BASE_API_URL_CAP_DIEN}/don-vi/create`,
  UPDATE_DON_VI: `${BASE_API_URL_CAP_DIEN}/don-vi/update`,
  REMOVE_DON_VI: `${BASE_API_URL_CAP_DIEN}/don-vi/delete`,
  GET_DON_VI: `${BASE_API_URL_CAP_DIEN}/don-vi/get`,
};
