import AdminLayout from "@components/Layout/Admin";
import LandingPageLayout from "@components/Layout/LandingPage";
import User from "@components/Layout/User";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import ChangingPassword from "./pages/LoginScreen/ChangingPassword";
import ChangingPasswordSuccess from "./pages/LoginScreen/ChangingPasswordSuccess";
import ChangePasswordUser from "./pages/LoginScreen/ChangePasswordUser";
import ForgetPassword from "./pages/LoginScreen/ForgetPassword";
import LoginForm from "./pages/LoginScreen/LoginForm";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            isAuthenticated ? (
              user.isAdmin ? (
                <Navigate to="/admin" />
              ) : (
                <Navigate to="/user" />
              )
            ) : (
              <Navigate to="/landing-page" />
            )
          }
        />
        <Route
          path="/landing-page/*"
          element={
            <LandingPageLayout />
          }
        />
        <Route
          path="/admin/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <AdminLayout />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <User />
            </PrivateRoute>
          }
        />
        <Route path="/landing-page" element={<Navigate to="/landing-page/overview" />} />
        <Route path="/user" element={<Navigate to="/user/overview" />} />
        <Route path="/admin" element={<Navigate to="/admin/overview" />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/changing-password" element={<ChangingPassword />} />
        <Route path="/change-password-user" element={<ChangePasswordUser />} />
        <Route
          path="/changing-password-success"
          element={<ChangingPasswordSuccess />}
        />
      </Routes>
    </>
  );
}

function PrivateRoute({ children, isAuthenticated }) {
  return isAuthenticated ? children : <Navigate to="/landing-page" />;
}

export default App;
