import { Button, Form, Input, Spin, notification } from "antd";
import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import UserIcon from "@assets/icon/UserIcon";
import Footer from "@components/Layout/Footer";
import { authPostData } from "@utils/request";
import { useNavigate } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import LogoComponent from "../../components/Layout/Logo";
import { setEmail } from "@redux/Date/DateReducer";
import { useDispatch, useSelector } from "react-redux";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.date.email);
  const dispatch = useDispatch();
  const onFinish = useCallback((values) => {
    dispatch(setEmail(values.email));
    authPostData({
      url: "api/v1/user/forgot-pwd",
      method: "POST",
      payload: values,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: "Thành công",
            description: res.message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          navigate("/changing-password");
        } else {
          setLoading(false);
        }
      },
    });
  }, []);
  const resendOtp = () => {
    if (email) {
      authPostData({
        url: "api/v1/user/otp-resend",
        method: "POST",
        payload: email,
        setLoading,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            notification.open({
              message: "Thành công",
              description: res.message,
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            navigate("/changing-password");
          } else {
            setLoading(false);
          }
        },
      });
    } else {
      notification.error({
        duration: 3,
        message: "Thông báo!",
        description: "Bạn chưa nhập email",
      });
    }
  };
  return (
    <Spin spinning={loading}>
      <div style={{ backgroundColor: "#e5e5e5" }}>
        <div className="login-container">
          <LogoComponent />
          <div className="login-form">
            <div className="form-title">
              <span>Quên mật khẩu</span>
            </div>
            <div className="form-description">
              <span>Chúng tôi sẽ gửi cho bạn về email của bạn mã OTP</span>
            </div>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="form-login"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Định dạng E-mail không chính xác!",
                  },
                  {
                    required: true,
                    message: "Vui lòng nhập E-mail!",
                  },
                ]}
              >
                <Input
                  className="form-input"
                  size="large"
                  placeholder="Vui lòng nhập email của bạn"
                  prefix={
                    <UserIcon style={{ marginRight: "10px !important" }} />
                  }
                />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className="full login-button"
              >
                <span>Gửi</span>
              </Button>
              <div className="form-cancel-to-login">
                <Link to="/login">Hủy</Link>
              </div>
            </Form>
          </div>
        </div>
        <Footer />
      </div>
    </Spin>
  );
}
