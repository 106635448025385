import { BASE_API_URL } from "@utils/constants";
import { Table, ConfigProvider, Empty } from "antd";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";

function OverViewDetailTopRelease({ releaseLoading, isAdmin }) {
  const {t} = useTranslation();
  const data = useSelector((state) => state.date.trackData);

  const base_api = BASE_API_URL;

  const releaseData = useSelector((state) => state.date.releaseData);

  const filter = [t('Tracks'), t('Releases')];

  const [table, setTable] = useState(t('Tracks'));

  const columns = [
    {
      title: t('No.'),
      dataIndex: "key",
      align: "center",
      width: "60px",
      className: "overview-detail-top-release-number",
    },
    {
      title: t('Tracks'),
      dataIndex: "song",
      align: "left",
      className: "overview-detail-top-release-song",
      width: "300px",
    },
    {
      title: t('Labels'),
      dataIndex: "artist",
      align: "left",
      className: "overview-detail-top-release-artist",
      width: "180px",
    },
    {
      title: t('Releases'),
      dataIndex: "release",
      align: "left",
      className: "overview-detail-top-release-album",
      width: "240px",
    },
    {
      title: t('Stream/Download'),
      dataIndex: "stream",
      align: "right",
      className: "overview-detail-top-release-stream",
      width: "86px",
    },
    {
      title: "% " + t('Stream/Download'),
      dataIndex: "streamPer",
      align: "right",
      className: "overview-detail-top-release-streamPer",
      width: "99px",
    },
  ];

  const trackData =
    data &&
    data.map((item, index) => {
      return {
        key: `${index + 1}`,
        song: (
          <>
            <img
              src={item.imageUrl ? base_api + item.imageUrl : "../image.png"}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "6px",
                objectFit: "cover",
              }}
              alt="an-img"
            />
            <h1>{item.trackName}</h1>
          </>
        ),
        artist: `${item.artistName || t('Unknown')}`,
        release: `${item.releaseName}`,
        stream: `${item.trackValue
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`,
        streamPer: `${(Number(item.trackPercentValue.toFixed(0))) == Number(item.trackPercentValue.toFixed(1)) ?
          Number(item.trackPercentValue.toFixed(0)) + ".0" : Number(item.trackPercentValue.toFixed(1))}%`,
      };
    });

  const columnRelease = [
    {
      title: t('No.'),
      dataIndex: "key",
      align: "center",
      width: "60px",
      className: "overview-detail-top-release-number",
    },
    {
      title: t('Releases'),
      dataIndex: "release",
      align: "left",
      className: "overview-detail-top-release-song",
      width: "240px",
    },
    {
      title: t('Labels'),
      dataIndex: "artist",
      align: "left",
      className: "overview-detail-top-release-artist",
      width: "180px",
    },
    {
      title: t('Stream/Download'),
      dataIndex: "stream",
      align: "right",
      className: "overview-detail-top-release-stream",
      width: "86px",
    },
    {
      title: "% " + t('Stream/Download'),
      dataIndex: "streamPer",
      align: "right",
      className: "overview-detail-top-release-streamPer",
      width: "99px",
    },
  ];

  const dataRelease =
    releaseData &&
    releaseData.map((item, index) => {
      return {
        key: `${index + 1}`,
        release: (
          <>
            <img
              src={item.imageUrl ? base_api + item.imageUrl : "../image.png"}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "6px",
                objectFit: "cover",
              }}
              alt="an-img"
            />
            <h1>{item.releaseName}</h1>
          </>
        ),
        artist: `${item.claimName || t('Unknown')}`,
        stream: `${item.releaseValue
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") || t('Unknown')
          }`,
        streamPer: `${(Number(item.releasePercentValue.toFixed(0))) == Number(item.releasePercentValue.toFixed(1)) ?
          Number(item.releasePercentValue.toFixed(0)) + ".0" : Number(item.releasePercentValue.toFixed(1))}%`,
      };
    });

  return table === "Bản thu" || table === "Tracks" ? (
    <div className="overview-detail-top-release">
      <ConfigProvider
        renderEmpty={() => <Empty description={t('No Records Found')} />}
      >
        <div className="overview-detail-top-release-container">
          <div className="overview-platform-title">
            <h1>{t('Top stream/download tracks list')}</h1>
            <div className="overview-platform-title-sort">
              <Filter
                title={t('Sort by')}
                filter={filter}
                setTable={setTable}
                table={table}
              />
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={trackData}
            loading={releaseLoading}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </ConfigProvider>
    </div>
  ) : (
    <div className="overview-detail-top-release">
      <ConfigProvider
        renderEmpty={() => <Empty description={t('No Records Found')}  />}
      >
        <div className="overview-detail-top-release-container">
          <div className="overview-platform-title">
            <h1>{t('Top stream/download releases list')}</h1>
            <div className="overview-platform-title-sort">
              <Filter
                title={t('Sort by')} 
                filter={filter}
                setTable={setTable}
                table={table}
              />
            </div>
          </div>
          <Table
            columns={columnRelease}
            dataSource={dataRelease}
            pagination={false}
            loading={releaseLoading}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </ConfigProvider>
    </div>
  );
}

export default memo(OverViewDetailTopRelease);
