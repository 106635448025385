import TableComponent from "@components/TableComponent";
import SelectionLabels from "@components/SelectLabels";
import {
  disabledDateMonth,
  handlePagination,
  mapCurrencyToCulture,
  mapLanguageToCulture,
} from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Row,
  Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DEFAULT_PAGESIZE } from "@utils/constants";

const StatisticMain = () => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const currency = mapCurrencyToCulture();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const cancelTokenSource = useRef();
  const [dates, setDates] = useState([]);
  const [form] = Form.useForm();

  const handleMapColumnsCounTry = (t) => {
    return [
      {
        title: t("No."),
        key: "index_country",
        dataIndex: "key",
        align: "center",
        width: "4%",
      },
      {
        title: t("Teritories"),
        dataIndex: "country",
        align: "left",
        key: "country",
        width: "36%",
      },
      {
        title:
          currency === "EUR"
            ? t("Net revenue") + " (€)"
            : t("Net revenue") + " (đ)",
        dataIndex: "clientRevenue",
        key: "clientRevenue_country",
        align: "right",
        width: "20%",
      },
      {
        title: t("Stream/Download"),
        dataIndex: "stream",
        key: "stream_country",
        align: "right",
        width: "20%",
      },
      {
        title:
          currency === "EUR"
            ? t("Unit price") + " (*10⁴ €)"
            : t("Unit price") + " (đ)",
        dataIndex: "averageNumber",
        key: "averageNumber_country",
        align: "right",
        width: "20%",
      },
    ];
  };

  const handleMapColumnsPlatform = (t) => {
    return [
      {
        title: t("No."),
        key: "index_platform",
        dataIndex: "key",
        align: "center",
        width: "4%",
      },
      {
        title: t("Stores"),
        dataIndex: "platform",
        key: "platform",
        align: "left",
        width: "36%",
      },
      {
        title:
          currency === "EUR"
            ? t("Net revenue") + " (€)"
            : t("Net revenue") + " (đ)",
        dataIndex: "clientRevenue",
        key: "clientRevenue_platform",
        align: "right",
        width: "20%",
      },
      {
        title: t("Stream/Download"),
        dataIndex: "stream",
        key: "stream_platform",
        align: "right",
        width: "20%",
      },
      {
        title:
          currency === "EUR"
            ? t("Unit price") + " (*10⁴ €)"
            : t("Unit price") + " (*10⁴ €)",
        dataIndex: "averageNumber",
        key: "averageNumber_platform",
        align: "right",
        width: "20%",
      },
    ];
  };
  const handleMapColumnsRelease = (t) => {
    return [
      {
        title: t("No."),
        key: "index_release",
        dataIndex: "key",
        align: "center",
        width: "4%",
      },
      {
        title: t("Releases"),
        dataIndex: "release",
        key: "release",
        align: "left",
        width: "36%",
      },
      {
        title:
          currency === "EUR"
            ? t("Net revenue") + " (€)"
            : t("Net revenue") + " (đ)",
        dataIndex: "clientRevenue",
        key: "clientRevenue_release",
        align: "right",
        width: "20%",
      },
      {
        title: t("Stream/Download"),
        dataIndex: "stream",
        key: "stream_release",
        align: "right",
        width: "20%",
      },
      {
        title:
          currency === "EUR"
            ? t("Unit price") + " (*10⁴ €)"
            : t("Unit price") + " (đ)",
        dataIndex: "averageNumber",
        key: "averageNumber_release",
        align: "right",
        width: "20%",
      },
    ];
  };
  const handleMapColumnsTrack = (t) => {
    return [
      {
        title: t("No."),
        key: "index_track",
        dataIndex: "key",
        align: "center",
        width: "4%",
      },
      {
        title: t("Tracks"),
        dataIndex: "track",
        key: "track",
        align: "left",
        width: "28%",
        className: "min-width-240",
      },
      {
        title: t("Releases"),
        dataIndex: "release",
        key: "release_track",
        align: "left",
        width: "26%",
        className: "min-width-240",
      },
      {
        title:
          currency === "EUR"
            ? t("Net revenue") + " (€)"
            : t("Net revenue") + " (đ)",
        dataIndex: "clientRevenue",
        key: "clientRevenue_track",
        align: "right",
        width: "14%",
      },
      {
        title: t("Stream/Download"),
        dataIndex: "stream",
        key: "stream_track",
        align: "right",
        width: "13%",
      },
      {
        title:
          currency === "EUR"
            ? t("Unit price") + " (*10⁴ €)"
            : t("Unit price") + " (đ)",
        dataIndex: "averageNumber",
        key: "averageNumber_track",
        align: "right",
        width: "13%",
      },
    ];
  };

  const [loading, setLoading] = useState(false);
  const [sortEnum, setSortEnum] = useState(1);
  const [topRevenueOrView, setTopRevenueOrView] = useState(1);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [filterConditions, setFilterConditions] = useState({
    ...pagination,
    sortEnum: sortEnum,
    topRevenueOrView: topRevenueOrView,
  });
  const [url, setUrl] = useState(`api/v1/statistic/country`);
  const [columns, setColumns] = useState(handleMapColumnsCounTry(t));
  const [total, setTotal] = useState();
  const [tableDataSource, setTableDataSource] = useState();

  const [activeCountry, setActiveCountry] = useState(true);
  const [activePlatform, setActivePlatform] = useState(false);
  const [activeRelease, setActiveRelease] = useState(false);
  const [activeTrack, setActiveTrack] = useState(false);

  useEffect(() => {
    authGetData({
      url: `api/v1/update/reportingmonth`,
      setLoading: () => {},
      onSuccess: (res) => {
        if (res) {
          setDates(res);
          setFilterConditions({
            startDate: moment(res).startOf("month").format("MM/YYYY"),
            endDate: moment(res).format("MM/YYYY"),
            ...pagination,
            sortEnum: sortEnum,
            topRevenueOrView: topRevenueOrView,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const endDate = moment(dates);
    const startDate = moment(dates).startOf("month");

    form.setFieldsValue({
      time: [startDate, endDate],
    });
  }, [dates, form]);

  useEffect(() => {
    handleGetData({ url, culture, currency });
  }, [filterConditions, url, culture, currency]);

  useEffect(() => {
    if (activeCountry) {
      handleQueryChangeCountry();
    }
    if (activePlatform) {
      handleQueryChangePlatform();
    }
    if (activeRelease) {
      handleQueryChangeRelease();
    }
    if (activeTrack) {
      handleQueryChangeTrack();
    }
  }, [currency]);

  const handleQueryChangeCountry = () => {
    const updated_url = `api/v1/statistic/country`;

    setColumns(handleMapColumnsCounTry(t));
    setActiveCountry(true);
    setActivePlatform(false);
    setActiveRelease(false);
    setActiveTrack(false);
    setUrl(updated_url);
    setFilterConditions({
      ...filterConditions,
      ...pagination,
    });
  };

  const handleQueryChangePlatform = () => {
    const updated_url = `api/v1/statistic/platform`;

    setColumns(handleMapColumnsPlatform(t));
    setActiveCountry(false);
    setActivePlatform(true);
    setActiveRelease(false);
    setActiveTrack(false);
    setUrl(updated_url);
    setFilterConditions({
      ...filterConditions,
      ...pagination,
    });
  };

  const handleQueryChangeRelease = () => {
    const updated_url = `api/v1/statistic/release`;

    setColumns(handleMapColumnsRelease(t));
    setActiveCountry(false);
    setActivePlatform(false);
    setActiveRelease(true);
    setActiveTrack(false);
    setUrl(updated_url);
    setFilterConditions({
      ...filterConditions,
      ...pagination,
    });
  };

  const handleQueryChangeTrack = () => {
    const updated_url = `api/v1/statistic/track`;

    setColumns(handleMapColumnsTrack(t));
    setActiveCountry(false);
    setActivePlatform(false);
    setActiveRelease(false);
    setActiveTrack(true);
    setUrl(updated_url);
    setFilterConditions({
      ...filterConditions,
      ...pagination,
    });
  };

  const handleGetData = useCallback(
    ({ url, culture, currency }) => {
      if (typeof cancelTokenSource.current != typeof undefined) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to new request."
        );
      }

      //Save the cancel token for the current request

      cancelTokenSource.current = axios.CancelToken.source();

      authPostData({
        url: url + `?culture=${culture}`,
        method: "POST",
        payload: {
          ...filterConditions,
        },
        setLoading,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            setTableDataSource(
              res.data.map((e, index) => ({
                ...e,
                key:
                  (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                  (index + 1),
                stream: e.stream
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                clientRevenue: `${Number(Number(e.netRevenue.toFixed(3)))
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`,
              }))
            );

            setTotal(res.paging.totalCount);
          }
        },
        currency,
      });
    },
    [filterConditions, culture, currency]
  );

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      ...pagination,
      sortEnum: sortEnum,
      topRevenueOrView: topRevenueOrView,
      startDate: values?.time[0]
        ? moment(values?.time[0]).format("MM/YYYY")
        : null,
      endDate: values?.time[1]
        ? moment(values?.time[1]).format("MM/YYYY")
        : null,
    });
  };

  const handleReset = () => {
    const endDate = moment(dates);
    const startDate = moment(dates).startOf("month");

    form.setFieldsValue({
      time: [startDate, endDate],
    });
    form.resetFields(["userIds"]);
    setFilterConditions({
      startDate,
      endDate,
      ...pagination,
      sortEnum: sortEnum,
      topRevenueOrView: topRevenueOrView,
    });
  };

  const handleChangeSort = (value) => {
    setTopRevenueOrView(value);

    setFilterConditions({
      ...filterConditions,
      ...pagination,
      sortEnum: sortEnum,
      topRevenueOrView: value,
    });
  };

  const handleChange = (value) => {
    setSortEnum(value);

    setFilterConditions({
      ...filterConditions,
      ...pagination,
      sortEnum: value,
      topRevenueOrView: topRevenueOrView,
    });
  };

  useEffect(() => {
    if (activeCountry) {
      setColumns(handleMapColumnsCounTry(t));
    }
    if (activePlatform) {
      setColumns(handleMapColumnsPlatform(t));
    }
    if (activeRelease) {
      setColumns(handleMapColumnsRelease(t));
    }
    if (activeTrack) {
      setColumns(handleMapColumnsTrack(t));
    }
  }, [i18n.language]);

  return (
    <>
      <ContentStyled className="statistic-container">
        <div className="select-option">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[20, 20]}>
              <Col xl={9} lg={12} md={24}>
                <FormItem label={t("Reporting month")} name="time">
                  <RangePicker
                    locale={i18n.language === "vi" ? localeVi : localeEn}
                    format="MM/YYYY"
                    picker="month"
                    disabledDate={disabledDateMonth}
                  />
                </FormItem>
              </Col>
              <Col xl={8} lg={12} md={13}>
                <Form.Item label={t("Labels")} name="userIds">
                  <SelectionLabels
                    url={`api/v1/overview/filter/labelnames`}
                    formKey="userIds"
                    form={form}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>

              <Col xl={7} lg={24} md={11}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="header-button">
                <Button
                  active={activeCountry}
                  className={activeCountry ? "query active" : "query"}
                  onClick={handleQueryChangeCountry}
                >
                  {t("Teritories")}
                </Button>
                <Button
                  active={activePlatform}
                  className={activePlatform ? "query active" : "query"}
                  onClick={handleQueryChangePlatform}
                >
                  {t("Stores")}
                </Button>
                <Button
                  active={activeRelease}
                  className={activeRelease ? "query active" : "query"}
                  onClick={handleQueryChangeRelease}
                >
                  {t("Releases")}
                </Button>
                <Button
                  active={activeTrack}
                  className={activeTrack ? "query active" : "query"}
                  onClick={handleQueryChangeTrack}
                >
                  {t("Tracks")}
                </Button>
              </div>
              <div className="header-sort">
                <Select onChange={handleChangeSort} value={topRevenueOrView}>
                  <Option value={1}>{t("Sorting by net revenue")}</Option>
                  <Option value={2}>{t("Sorting by stream/download")}</Option>
                  <Option value={3}>{t("Sorting by unit price")}</Option>
                </Select>
                <Select
                  value={sortEnum}
                  style={{ width: 114 }}
                  onChange={handleChange}
                >
                  <Option value={1}>{t("Highest")}</Option>
                  <Option value={2}>{t("Lowest")}</Option>
                </Select>
              </div>
            </div>

            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  rowKey="key"
                  dataSource={tableDataSource}
                  columns={columns}
                  totalData={total}
                  loading={loading}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </ContentStyled>
    </>
  );
};

export default StatisticMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }

  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }

  .ant-row {
    align-items: center;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .header {
    display: flex;
  }

  .header-button {
    display: flex;
    gap: 10px;
  }

  .header-button button {
    color: rgb(127, 129, 131);
    background-color: rgb(217, 219, 221) !important;
    border-color: rgb(217, 219, 221) !important;
    padding: 4px 10px;
  }

  .header-button .active {
    color: rgb(253, 253, 253);
    background-color: rgb(0, 51, 102) !important;
  }

  .header-sort {
    display: flex;
    column-gap: 10px;
    .ant-select {
      width: 242px;
    }
  }

  .min-width-240 {
    min-width: 240px;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: end;
    }
  }

  @media (max-width: 992px) {
    .header {
      display: block;
    }
    .header-button {
      justify-content: start;
      margin-bottom: 20px;
    }
    .header-sort {
      justify-content: end;
    }
  }
`;
