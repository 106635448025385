import StreamIcon from "@assets/icon/StreamIcon";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mapCurrencyToCulture } from "@utils/function";

function OverViewDetailTotalRevenue() {
    const { t } = useTranslation();
    const currency = mapCurrencyToCulture();
    const totalRevenue = useSelector((state) => state.date.totalRevenue);
    const previousTotalRevenue = useSelector(
        (state) => state.date.previousTotalRevenue
    );
    const [percentageRevenue, setPerRevenue] = useState();

    useEffect(() => {
        if (previousTotalRevenue !== 0 && totalRevenue !== 0) {
            setPerRevenue(
                ((totalRevenue - previousTotalRevenue) / previousTotalRevenue) * 100
            );
        } else if (totalRevenue === 0) {
            setPerRevenue(0);
        } else {
            return setPerRevenue(100);
        }
    }, [previousTotalRevenue, totalRevenue]);

    const total = [
        {
            id: 0,
            title: t('Revenue'),
            number: `${totalRevenue
                ? Number(totalRevenue.toFixed(3))
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                : "0"
                }`,
            percentage
                : percentageRevenue === 100 ? ""
                    : percentageRevenue ? `${Number(percentageRevenue.toFixed(2))}%`
                        : "0%",
            time: percentageRevenue === 100 ? "" : t('In the same period last year'),
            icon: <StreamIcon />,
        },
    ];

    return (
        <>
            <div className="overview-total">
                <div className="overview-total-item">
                    <div className="overview-total-item-beside">
                        <h1>{total[0].title}</h1>
                        {currency === 'EUR' ? (
                            <p>{total[0].number} €</p>
                            ) : (
                            <p>{total[0].number} đ</p>
                        )}                        
                        {totalRevenue - previousTotalRevenue < 0 ? (
                            <span style={{ color: "red" }}>{total[0].percentage}</span>
                        ) : (
                            <span style={{ color: "#48bb78" }}>{total[0].percentage}</span>
                        )}
                        <span>{total[0].time}</span>
                    </div>
                    {total[0].icon}
                </div>
            </div>
        </>
    );
}

export default memo(OverViewDetailTotalRevenue);
