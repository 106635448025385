import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APITram = {
  LIST_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/list`,
  SYNC_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/sync`,
  CREATE_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/create`,
  UPDATE_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/update`,
  REMOVE_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/delete`,
  GET_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/get`,
  SEARCH_TRAM: `${BASE_API_URL_CAP_DIEN}/tram/search`,
};
