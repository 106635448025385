import { postData } from "@utils/request";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BASE_API_URL, } from "@utils/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { ConfigProvider, Pagination, Spin } from "antd";
import { useTranslation } from "react-i18next";
import CartItemComponent from "../CardItemComponent/CardItem";
import localeVi from "antd/es/locale-provider/vi_VN";
import localeEn from "antd/es/locale-provider/en_US";

const paginationDefault = {
    pageSize: 30,
    pageIndex: 1,
    totalPages: 0
}

const ArtistsPage = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(paginationDefault)
    const [totalCount, setTotalCount] = useState(0)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const payloaded = {
            searchTerm: "",
            pageSize: pagination.pageSize,
            pageIndex: pagination.pageIndex,
        };

        postData({
            url: BASE_API_URL + `api/mobile/v1/artist`,
            method: 'POST',
            payload: payloaded,
            setLoading: setLoading,
            onSuccess: (res) => {
                setTotalCount(res.paging.totalCount)
                setData(res.data);
            },
        });
    }, [pagination])

    const handleChangePage = (currenPage, pageSize) => {
        const newPagination = {
            ...pagination,
            pageIndex: pagination.pageSize !== pageSize ? 1 : currenPage,
            pageSize: pageSize
        }
        setPagination(newPagination)
    }

    return (
        <ContentStyled >
            <ContainerList>
                <strong style={{ fontSize: "30px", display: "flex", justifyContent: "center", textAlign: "center" }}>{t('Listens by artists')}</strong>
                <div>
                    {
                        loading && <div className="spin-list">
                            <Spin indicator={<LoadingOutlined spin />} />
                        </div>
                    }
                    {
                        data?.map((item, index) => {
                            return (
                                <CartItemComponent
                                    index={((pagination.pageIndex - 1) * pagination.pageSize) + index}
                                    key={item.id}
                                    item={item}
                                    name={item.artistName}
                                    totalView={item.totalView}
                                />
                            )
                        })
                    }
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: "30px",
                }}>
                    <ConfigProvider locale={i18n.language === "vi" ? localeVi : localeEn}>
                        <Pagination
                            showSizeChanger={true}
                            current={pagination.pageIndex}
                            total={totalCount}
                            pageSize={pagination.pageSize}
                            pageSizeOptions={[30, 50, 100]}
                            onChange={handleChangePage}
                        />
                    </ConfigProvider>
                </div>
            </ContainerList>
        </ContentStyled >
    );
};

export default ArtistsPage;

const ContentStyled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  .content {
    background-color: #fdfdfd;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 0px 20px;
    .ant-tabs-tab-btn {
      font-weight: 500 !important;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 20px !important;
  }
  button {
    margin-bottom: 20px;
  }

  @media (max-width: 980px) {
    padding: 0 20px 20px 20px;
  }

  @media (max-width: 481px) {
     padding: unset;
  }
`;

const ContainerList = styled.div`
    width: 750px;
    padding: 10px;
    min-height: 88vh;
    border-radius: 10px;

    .list-data {
        margin-top: 10px;
        overflow: hidden scroll;
        scrollbar-width: none;
        max-height: 79vh;
    }
    
    .spin-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        opacity: 0.5;
        background: white;
        transition: all 0.3s;
    }
`