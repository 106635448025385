import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  STATUSCODE_500,
  TOKEN_NAME,
  BASE_API_URL,
} from "@utils/constants";
import { notification } from "antd";
import Axios from "axios";
import store from "../redux/store";
import { resetAuth } from "../redux/Auth/authReducer";

notification.config({
  maxCount: 1,
  duration: 2,
});

Axios.interceptors.response.use(
  (response) => {
    if (response && response.data.statusCode === STATUSCODE_500) {
      const savedLanguage = localStorage.getItem("selectedLanguage");
      notification.error({
        duration: 4,
        message: savedLanguage === "vi" ? "Thông báo!" : "Notification!",
        description: response.data.message,
      });
    }
    return response;
  },
  (error) => {
    let mess = `${error}`;
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (error && error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetAuth());
        return notification.error({
          duration: 5,
          message: savedLanguage === "vi" ? "Thông báo!" : "Notification!",
          description: 
          savedLanguage === "vi" 
          ? "Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại !" 
          : "The session has expired. Please login again",
        });
      } else if (error.response.data.statusCode === 400) {
        mess = error.response.data.message;

        return notification.error({
          duration: 5,
          message: savedLanguage === "vi" ? "Thông báo!" : "Notification!",
          description: mess,
        });
      } else if (error.response.data.statusCode === 500) {
        mess = error.response.data.message;
        notification.error({
          duration: 5,
          message: savedLanguage === "vi" ? "Thông báo!" : "Notification!",
          description: mess,
        });
        throw new Error(mess);
      }
      mess = error.response.data.message;
    }
    return error.response;
  }
);
async function defaultGet(endpoint) {
  return await Axios({
    method: METHOD_GET,
    url: endpoint,
  });
}

async function defaultPost(endpoint, method, body) {
  return await Axios({
    headers: {},
    method: method,
    url: endpoint,
    data: body,
  });
}

export async function authGet(endpoint, cancelToken, currency) {
  const token = localStorage.getItem(TOKEN_NAME);
  const userid = localStorage.getItem("userid");
  if (cancelToken) {
    return await Axios({
      headers: {
        Authorization: `Bearer ${token}`,
        AuthenPassword: `${userid}`,
        Currency: currency,
      },
      method: METHOD_GET,
      url: BASE_API_URL + endpoint,
      cancelToken: cancelToken.token,
    });
  }

  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      AuthenPassword: `${userid}`,
      Currency: currency,
    },
    method: METHOD_GET,
    url: BASE_API_URL + endpoint,
  });
}

async function authDelete(endpoint) {
  const token = localStorage.getItem(TOKEN_NAME);
  const userid = localStorage.getItem("userid");
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      AuthenPassword: `${userid}`
    },
    method: METHOD_DELETE,
    url: BASE_API_URL + endpoint,
  });
}

async function authDeleteBody(endpoint, body) {
  const token = localStorage.getItem(TOKEN_NAME);
  const userid = localStorage.getItem("userid");
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      AuthenPassword: `${userid}`
    },
    method: METHOD_DELETE,
    url: BASE_API_URL + endpoint,
    data: body,
  });
}

export async function authDeleteDataBody({ url, setLoading, onSuccess, body }) {
  setLoading(true);
  try {
    const res = await authDeleteBody(url, body);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

async function authPost(endpoint, method, body, cancelToken, currency) {
  const token = localStorage.getItem(TOKEN_NAME);
  const userid = localStorage.getItem("userid");
  if (cancelToken)
    return await Axios({
      headers: {
        Authorization: `Bearer ${token}`,
        AuthenPassword: `${userid}`,
        Currency: currency,
      },
      method: method,
      url: BASE_API_URL + endpoint,
      data: body,
      cancelToken: cancelToken.token,
    });

  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      AuthenPassword: `${userid}`,
      Currency: currency,
    },
    method: method,
    url: BASE_API_URL + endpoint,
    data: body,
  });
}

async function authPut(endpoint, method, body) {
  const token = localStorage.getItem(TOKEN_NAME);
  const userid = localStorage.getItem("userid");
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      AuthenPassword: `${userid}`
    },
    method: method,
    url: BASE_API_URL + endpoint,
    data: body,
  });
}

export async function authPutData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPut(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authPostData({
  url,
  method,
  payload,
  setLoading,
  setImportLoading,
  onSuccess,
  cancelToken,
  currency,
}) {
  setLoading(true);
  setImportLoading({});
  try {
    const res = await authPost(url, method, payload, cancelToken, currency);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
    setImportLoading(false);
  }
}

export async function authGetData({ url, setLoading, onSuccess, cancelToken, currency }) {
  setLoading(true);
  try {
    const res = await authGet(url, cancelToken, currency);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authDeleteData({ url, setLoading, onSuccess }) {
  setLoading(true);
  try {
    const res = await authDelete(url);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function getData({ url, setLoading, onSuccess }) {
  setLoading(true);
  try {
    const res = await defaultGet(url);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function postData({
  url,
  payload,
  method = METHOD_POST,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await defaultPost(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authGetMultiplesRequest({
  endpoints,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    await Axios.all(endpoints.map((endpoint) => authGet(endpoint))).then(
      (data) => {
        if (data.length) {
          onSuccess(data);
        }
      }
    );
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authPostMultiplesRequest({
  endpoints,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    await Axios.all(
      endpoints.map((endpoint) =>
        authPost(endpoint.url, endpoint.method, endpoint.payload)
      )
    ).then((data) => {
      if (data.length) {
        onSuccess(data);
      }
    });
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

function getFileName(response) {
  let filename = "";
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("filename") !== -1) {
    const filenameRegex = /UTF-8(.*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = decodeURIComponent(matches[1].replace(/['"]/g, ""));
    }
  }
  return filename;
}

export async function downloadFile({ endpoint, setLoading, nameoffile }) {
  setLoading(true);
  const token = localStorage.getItem(TOKEN_NAME);
  const userid = localStorage.getItem("userid");
  try {
    const res = await Axios({
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        AuthenPassword: `${userid}`
      },
      responseType: "blob",
      method: "GET",
      url: endpoint,
    });

    const fileName = getFileName(res);
    if (res && res.data && res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName ? fileName : nameoffile);
      document.body.appendChild(link);
      link.click();
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}
