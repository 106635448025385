import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIVungDonGia = {
  LIST_VUNG_DON_GIA: `${BASE_API_URL_CAP_DIEN}/vung-don-gia/list`,
  SYNC_VUNG_DON_GIA: `${BASE_API_URL_CAP_DIEN}/vung-don-gia/sync`,
  GET_VUNG_DON_GIA: `${BASE_API_URL_CAP_DIEN}/vung-don-gia/get`,
  CREATE_VUNG_DON_GIA: `${BASE_API_URL_CAP_DIEN}/vung-don-gia/create`,
  UPDATE_VUNG_DON_GIA: `${BASE_API_URL_CAP_DIEN}/vung-don-gia/update`,
  REMOVE_VUNG_DON_GIA: `${BASE_API_URL_CAP_DIEN}/vung-don-gia/delete`,
};
