import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Filter({ filter, title, setTable, table }) {
  const {t, i18n} = useTranslation();
  const [activeTrack, setActiveTrack] = useState(true);
  const [activeRelease, setActiveRelease] = useState(false);

  const handleTrackTitle = () => {
    setTable(t('Tracks'));
    setActiveTrack(true);
    setActiveRelease(false);
  };

  const handleReleaseTitle = () => {
    setTable(t('Releases'));
    setActiveTrack(false);
    setActiveRelease(true);
  };

  return (
    <div className="overview-bar-filter">
      <span>{title}</span>
      <ul>
        <li
          className={activeTrack ? "active" : ""}
          active={activeTrack}
          onClick={handleTrackTitle}
        >
          {t('Tracks')}
        </li>
        <li
          className={activeRelease ? "active" : ""}
          active={activeRelease}
          onClick={handleReleaseTitle}
         >
          {t('Releases')}
        </li>
      </ul>
    </div>
  );
}

export default Filter;
