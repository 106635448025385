import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import { BASE_API_URL, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  beforeUpload,
  handlePagination,
  mapLanguageToCulture,
  removeAccents,
} from "@utils/function";
import { authGetData, authPostData, authPutData } from "@utils/request";
import { authDeleteData } from "@utils/request";
import {
  Button,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Space,
  Spin,
  Upload,
  Select,
  Col,
  Form,
  Row,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const { Option } = Select;

const AlbumCountryMain = () => {
  const { t } = useTranslation();
  const culture = mapLanguageToCulture();
  const [form] = Form.useForm();
  const cancelTokenSource = useRef();
  const [total, setTotal] = useState();
  const [id, setId] = useState();
  const [updateLink, setUpdateLink] = useState();
  const [dataCountryAlbum, setDataCountryAlbum] = useState([]);
  const [load, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({ imageUrl: null, loading: false });
  const [tableLoading, setTableLoading] = useState(false);
  const [countryIds, setCountryIds] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t("Success"),
        description: t("Upload photo successful"),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setUpdateLink(info.file.response.data);
      setState({
        imageUrl: info.file.response.data,
        loading: false,
      });
    }
  };

  const getData = () => {
    authPostData({
      url: `api/v1/country?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataCountryAlbum(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
      cancelToken: cancelTokenSource.current,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions, culture]);

  const columns = [
    {
      title: t("No."),
      dataIndex: "key",
      align: "center",
      width: "10%",
    },
    {
      title: t("Teritories"),
      dataIndex: "countryName",
      align: "left",
      width: "32%",
    },
    {
      title: t("Avatar"),
      render: (record) =>
        record.countryImageURL ? (
          <img
            src={BASE_API_URL + record.countryImageURL}
            alt="Avatar"
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
          />
        ) : (
          <p style={{ color: "red" }}>{t("No Photo")}</p>
        ),
      align: "center",
      width: "30%",
    },
    {
      title: t("Custom..."),
      render: (record) => (
        <Space size="middle">
          <CloudUploadOutlined
            style={{ color: "blue", cursor: "pointer", fontSize: 20 }}
            onClick={() => showModal(record)}
          />
          <DeleteOutlined
            style={{ color: "red", fontSize: 20, cursor: "pointer" }}
            onClick={() => showDeleteConfirm(record)}
          />
        </Space>
      ),
      align: "center",
      width: "28%",
    },
  ];
  const showDeleteConfirm = (record) => {
    setDeleteId(record.id);
    setVisible(true);
  };

  const showModal = (record) => {
    setId(record.id);
    setState({ imageUrl: record.countryImageURL });
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setState({
      imageUrl: null,
      loading: true,
    });

    const formData = {
      uploadType: 3,
      imageUrl: state.imageUrl,
    };
    authPutData({
      url: `api/v1/change-image/${id}?culture=${culture}`,
      method: "PUT",
      payload: formData,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setIsModalVisible(false);
          notification.open({
            message: t("Success"),
            description: t("Update successful"),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          getData();
          setState({
            imageUrl: null,
            loading: false,
          });
        }
      },
    });
  };

  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/delete-image/${id}/3?culture=${culture}`,
      method: "DELETE",
      setLoading: setTableLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setVisible(false);
          notification.open({
            message: t("Success"),
            description: t("Delete successful"),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
          getData();
          setState({
            imageUrl: null,
            loading: false,
          });
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false);
    setState({
      imageUrl: null,
    });
  };

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/countries`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setCountryIds(res.data);
        }
      },
    });
  }, []);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };
  const { loading, imageUrl } = state;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("Upload image")}</div>
    </div>
  );

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  // Xử lý xóa bộ lọc

  const handleReset = () => {
    form.resetFields();
    form.setFieldsValue({
      status: null,
    });
    setFilterConditions({
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div className="select-option">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[20, 20]}>
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t("Teritories")} name="countries">
                  <Select
                    placeholder={t("All")}
                    mode="multiple"
                    showSearch
                    showArrow={true}
                    allowClear={true}
                    loading={loading}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {countryIds?.length
                      ? countryIds.map((item) => (
                          <Option key={item.countryId} value={item.countryName}>
                            {item.countryName}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t("Photo status")} name="status">
                  <Select placeholder={t("All")}>
                    <Option value={1}>{t("Photo")}</Option>
                    <Option value={2}>{t("No Photo")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} lg={24} md={24}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Teritory list")}</div>
            </div>

            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  defaultCurrent={filterConditions.pageIndex}
                  rowKey="key"
                  dataSource={dataCountryAlbum}
                  columns={columns}
                  totalData={total}
                  loading={loading}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                />
              </ConfigProvider>
              <Modal
                title={t("Teritory photo update")}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                footer={[
                  <Space key="update-album-thumb">
                    <Button key="back" type="outline" onClick={handleCancel}>
                      {t("Cancel")}
                    </Button>
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={handleOk}
                    >
                      {t("Update")}
                    </Button>
                  </Space>,
                ]}
              >
                <Upload
                  name="File"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={BASE_API_URL + `api/v1/upload?culture=${culture}`}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={BASE_API_URL + imageUrl}
                      alt="avatar"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Modal>
              <Modal
                key="confirm-delete-image-country"
                title={t("Please confirm")}
                open={visible}
                onOk={() => handleDelete(deleteId)}
                onCancel={handleCancel}
                footer={[
                  <Button
                    type="outline"
                    className="btn outline-btn"
                    key="back"
                    onClick={handleCancel}
                  >
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    className="btn primary-btn"
                    onClick={() => handleDelete(deleteId)}
                  >
                    {t("Agree")}
                  </Button>,
                ]}
              >
                <p>{t("Are you sure you want to delete ?")}</p>
              </Modal>
            </div>
          </div>
        </div>
      </ContentStyled>
    </Spin>
  );
};

export default AlbumCountryMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }

  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }

  .ant-row {
    align-items: center;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;
