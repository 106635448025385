import { SmileOutlined } from "@ant-design/icons";
import FileIcon from "@assets/icon/FileIcon";
import TableComponent from "@components/TableComponent";
import { mapLanguageToCulture, disabledDateTime } from "@utils/function";
import { authDeleteDataBody, authGetData, authPostData, downloadFile } from "@utils/request";
import {
  Button,
  ConfigProvider,
  Empty,
  Modal,
  Form,
  Spin,
  notification,
  Space,
  Typography,
  Upload,
  Col,
  Row,
  DatePicker,
  Input,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
const { Text } = Typography;

const ImportCSVMain = () => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const [state, setState] = useState({ fileList: null });
  const [importLoading, setImportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();

  const [environment, setEnvironment] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
     pageSize: DEFAULT_PAGESIZE,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [dataIds, setDataIds] = useState([]);
  const [dates, setDates] = useState([]);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const [tableDataSource, setTableDataSource] = useState([]);

  const onChangePagination = useCallback((data) => {
    setFilterConditions(prevConditions => ({
      ...prevConditions,
      pageIndex: data.current,
      pageSize: data.pageSize,
    }));
  }, []);

  useEffect(() => {
    const endDate = moment.utc();
    const startDate = moment.utc().subtract(1, "year");

    form.setFieldsValue({
      time: [startDate, endDate],
    });
  }, []);



  const handleGetData = useCallback(() => {
    authPostData({
      url: `api/v1/admin/files?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading,
      setImportLoading: () => { },
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setTableDataSource(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1)
            }))
          );

          setTotal(res.paging.totalCount);
        }
      },
      //cancelToken: cancelTokenSource.current,

    });
  }, [culture, filterConditions]);


  useEffect(() => {
    handleGetData();
  }, [filterConditions, culture]);


  const { fileList } = state;
  const Linking = styled.a``;

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", fileList[0]);

    authPostData({
      url: `api/v1/import-data?culture=${culture}`,
      method: "POST",
      payload: formData,
      setLoading,
      setImportLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setState({
            fileList: null,
          });
          notification.open({
            message: t('Success'),
            description: t('Success'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });

          handleGetData();
        }
      },
    });
  };

  const props = {
    onRemove: () => {
      setState(() => ({
        fileList: null,
      }));
    },
    beforeUpload: (file) => {
      setState(() => ({
        fileList: [file],
      }));
      return false;
    },
    fileList,
    accept: ".csv",
  };


  useEffect(() => {
    authGetData({
      url: `api/v1/update/environment?culture=${culture}`,
      setLoading: () => { },
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setEnvironment(res.data);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const testing = (fileid, nameoffile) => {
    const endpoint = environment + "api/v1/export-file/" + fileid;
    console.log(endpoint);
    downloadFile({ endpoint, setLoading, nameoffile });
  }

  const columns = [
    {
      title: t('No.'),
      dataIndex: "key",
      align: "center",
      width: "4%",
    },
    {
      title: t('File name'),
      dataIndex: "name",
      align: "left",
      render: (record, item) => (
        <Linking className="export-file" onClick={() => testing(item.fileId, item.name)}>
          {item.name}
        </Linking>
      ),
      width: "36%",
    },
    {
      title: t('File upload time'),
      dataIndex: "createdAt",
      render: (text) => (
        <span>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</span>
      ),
      align: "left",
      width: "20%",
      className: 'min-width-170'
    },
    {
      title: t('Performent'),
      dataIndex: "authorName",
      align: "left",
      width: "20%",
      className: 'min-width-150'
    },
    {
      title: t('Records number'),
      dataIndex: "recordCount",
      align: "right",
      width: "20%",
      className: 'min-width-150'
    },
  ];

  const handleOpenModalDelete = () => {
    setVisibleModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setVisibleModalDelete(false);
  };

  const handleOk = useCallback(() => {
    authDeleteDataBody({
      url: `api/v1/delete-data?culture=${culture}`,
      setLoading: setConfirmLoading,
      body: dataIds,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            duration: 4,
            message: t('Notification'),
            description: t('Delete successful'),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
          setDataIds([]);
          setSelectedRowKeys([]);
          handleGetData();
          setVisibleModalDelete(false);
        }
      },
    });
  }, [dataIds, handleGetData]);

  const modalDeleteVisible = useMemo(() => {
    if (visibleModalDelete) {
      return (
        <Modal
          className="payment-modal"
          title={t('Confirm file delete')}
          open={visibleModalDelete}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCloseModalDelete}
          maskClosable={false}
          footer={[
            <Space key="confirm-payment">
              <Button
                type="outline"
                key="back"
                onClick={handleCloseModalDelete}
              >
                {t('Cancel')}
              </Button>
              <Button
                key="submit"
                type="danger"
                loading={confirmLoading}
                onClick={handleOk}
              >
                {t('Agree')}
              </Button>
            </Space>,
          ]}
        >
          <p>{t('Are you sure you want to delete this file?')}</p>
        </Modal>
      );
    }
  }, [confirmLoading, handleOk, visibleModalDelete]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setDataIds(selectedRows.map((item) => item.fileId));
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    if (dates && dates.length > 0) {
      form.setFieldsValue({
        filterDate: [dates[0], dates[1]],
      });
    }
  }, [dates, form]);

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
       pageSize: DEFAULT_PAGESIZE,
      startTime: values?.time[0] ? moment(values?.time[0]) : null,
      endTime: values?.time[1] ? moment(values?.time[1]) : null,
    })
  };

  // Xử lý xóa bộ lọc

  const handleReset = () => {
    const endTime = moment.utc();
    const startTime = moment.utc().subtract(1, "year");

    form.resetFields();
    form.setFieldsValue({
      time: [startTime, endTime],
      fileNames: "",
    });
    setFilterConditions({
      startTime,
      endTime,
      pageIndex: 1,
       pageSize: DEFAULT_PAGESIZE,
    })
  };

  return (
    <>
      <Spin spinning={loading}>
        <ContentStyled>
          <div className="select-option">
            <Form
              form={form}
              onFinish={onFinish}
            >
              <Row gutter={[20, 20]}>
                <Col xl={9} lg={12} md={24}>
                  <Form.Item label={t('File download time')} name="time">
                    <RangePicker
                      locale={i18n.language === 'vi' ? localeVi : localeEn}
                      format="DD/MM/YYYY"
                      disabledDate={disabledDateTime}
                    />
                  </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={13}>
                  <Form.Item label={t('File name')} name="fileNames" >
                    <Input
                      placeholder={t('Input file name')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={7} lg={24} md={11}>
                  <div className="action-buttons">
                    <Button onClick={handleReset}>{t('Clear all')}</Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t('Search')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="table-data--wrap">
            <div className="table-data--main">
              <div className="header">
                <div className="title">{t('CSV file List')}</div>
              </div>

              <div className="action">
                <Upload {...props}>
                  <Button className="button">
                    <span>{t('Select file')}</span>
                    <FileIcon />
                  </Button>
                </Upload>
                <Button
                  className="upload"
                  onClick={handleUpload}
                  loading={importLoading}
                  disabled={!fileList}
                >
                  {importLoading ? t('Loading') : t('Upload')}
                </Button>
                <Button
                  type="danger"
                  onClick={handleOpenModalDelete}
                  style={{ marginLeft: "10px" }}
                  disabled={dataIds < 1}
                >
                  {t('Delete file')}
                </Button>
                <Text
                  type="danger"
                  style={{
                    flexBasis: "100%",
                    marginTop: "10px",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  {t('Please make sure the Label field in the uploaded CSV File matches the Label')}
                </Text>
                <Text
                  type="danger"
                  style={{
                    flexBasis: "100%",
                    marginTop: "3px",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  {t('defined in the system')} {" "}
                  <b style={{ cursor: "pointer" }}>
                    <u>
                      <Link to={"/admin/setting"}>{t('here')}</Link>
                    </u>
                  </b>{" "}
                  {t('before uploading the File')}
                </Text>
              </div>

              <div className="content">
                <ConfigProvider
                  renderEmpty={() => <Empty description={t('No Records Found')} />}
                >
                  <TableComponent
                    defaultCurrent={filterConditions.pageIndex}
                    rowKey="key"
                    dataSource={tableDataSource}
                    columns={columns}
                    totalData={total}
                    loading={loading}
                    onChangePagination={onChangePagination}
                    pagination={filterConditions}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          {modalDeleteVisible}
        </ContentStyled>
      </Spin>
    </>
  );
};

export default ImportCSVMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  } 

  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }

  .ant-row {
    align-items: center;
  }

  .ant-form-item {
    margin: 0 !important;
  }
  .action {
    box-shadow: inset 0px -0.5px 0px #d9dbdd;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .button {
      background-color: #d9dbdd;
      border-color: #d9dbdd;
      color: #7f8183;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      border-radius: 4px;
      span {
        margin-right: 10px;
      }
    }
    .upload {
      background-color: #003366;
      border-color: #003366;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      border-radius: 4px;
      margin-left: 20px;
    }

  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;
