import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";


export default function ModalDeleteLink({ visibleDeleteLink, setVisibleDeleteLink, dataLinkDetail, setDataLinkDetail, dataLink, setDataLink, index, setIndex }) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {

        let newDataLink;

        if (dataLinkDetail?.isDefault) {
            newDataLink = dataLink.slice();
            newDataLink.splice(index, 1);
            if (newDataLink.length > 0) {
                newDataLink[0].isDefault = true;
            }
        } else {
            newDataLink = dataLink.slice();
            newDataLink.splice(index, 1);
        }
        setDataLink(newDataLink);
        handleCancel();
    };

    const handleCancel = () => {
        setIndex(null);
        setVisibleDeleteLink(false);
        setDataLinkDetail({});
    };

    return (
        <Modal
            title={t('Please confirm')}
            open={visibleDeleteLink}
            onOk={() => handleDelete()}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="outline"
                    className="btn outline-btn"
                    key="back"
                    onClick={handleCancel}
                >
                    {t('Cancel')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    className="btn primary-btn"
                    onClick={() => handleDelete()}
                >
                    {t('Agree')}
                </Button>,
            ]}
        >
            <p>{t('Are you sure you want to delete ?')}</p>
        </Modal>
    )
};
