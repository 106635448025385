import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDay: null,
  endDay: null,
  totalRevenue: null,
  totalStream: null,
  previousTotalRevenue: null,
  previousTotalStream: null,
  trackData: null,
  load: false,
  releaseData: null,
  countryData: null,
  platformData: null,
  artistsData: null,
  streamPlatform: null,
  userRevenue: null,
  incomeData: null,
  valueArtist: null,
  valueViewer: null,
  valueOwner: false,
  releaseId: null,
  trackId: null,
  artistShareRate: null,
  email: null
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setStartDay: (state, action) => {
      state.startDay = action.payload;
    },
    setEndDay: (state, action) => {
      return {
        ...state,
        endDay: action.payload,
      };
    },
    setTotalRevenue: (state, action) => {
      return {
        ...state,
        totalRevenue: action.payload,
      };
    },
    setTotalStream: (state, action) => {
      return {
        ...state,
        totalStream: action.payload,
      };
    },
    setTotalGrossRevenue: (state, action) => {
      return {
        ...state,
        totalGrossRevenue: action.payload,
      };
    },
    setPreviousTotalStream: (state, action) => {
      return {
        ...state,
        previousTotalStream: action.payload,
      };
    },
    setPreviousTotalRevenue: (state, action) => {
      return {
        ...state,
        previousTotalRevenue: action.payload,
      };
    },
    setPreviousGrossRevenue: (state, action) => {
      return {
        ...state,
        previousGrossRevenue: action.payload,
      };
    },
    setTrackData: (state, action) => {
      return {
        ...state,
        trackData: action.payload,
      };
    },
    setLoad: (state, action) => {
      return {
        ...state,
        load: action.payload,
      };
    },
    setReleaseData: (state, action) => {
      return {
        ...state,
        releaseData: action.payload,
      };
    },
    setCountryData: (state, action) => {
      return {
        ...state,
        countryData: action.payload,
      };
    },
    setPlatformData: (state, action) => {
      return {
        ...state,
        platformData: action.payload,
      };
    },
    setArtistsData: (state, action) => {
      return {
        ...state,
        artistsData: action.payload,
      };
    },
    setStreamPlatForm: (state, action) => {
      return {
        ...state,
        streamPlatform: action.payload,
      };
    },
    setUserRevenue: (state, action) => {
      return {
        ...state,
        userRevenue: action.payload,
      };
    },
    setIncomeData: (state, action) => {
      return {
        ...state,
        incomeData: action.payload,
      };
    },
    setValueArtist: (state, action) => {
      return {
        ...state,
        valueArtist: action.payload,
      };
    },
    setValueViewer: (state, action) => {
      return {
        ...state,
        valueViewer: action.payload,
      };
    },
    setValueOwner: (state, action) => {
      return {
        ...state,
        valueOwner: action.payload,
      };
    },
    setReleaseId: (state, action) => {
      return {
        ...state,
        releaseId: action.payload,
      };
    },
    setTrackId: (state, action) => {
      return {
        ...state,
        trackId: action.payload,
      };
    },
    setArtistShareRate: (state, action) => {
      return {
        ...state,
        artistShareRate: action.payload,
      };
    },
    setEmail: (state, action) => {
      return {
        ...state,
        email: action.payload,
      };
    },
  },
});

export const {
  setStartDay,
  setEndDay,
  setTotalStream,
  setTotalRevenue,
  setTotalGrossRevenue,
  setPreviousTotalStream,
  setPreviousTotalRevenue,
  setPreviousGrossRevenue,
  setTrackData,
  setLoad,
  setReleaseData,
  setCountryData,
  setPlatformData,
  setArtistsData,
  setStreamPlatForm,
  setUserRevenue,
  setIncomeData,
  setValueArtist,
  setValueViewer,
  setValueOwner,
  setReleaseId,
  setTrackId,
  setArtistShareRate,
  setEmail
} = dateSlice.actions;
export default dateSlice.reducer;
