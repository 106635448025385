import { Layout, Spin } from "antd";
import React, { useState } from "react";
import { useCallback } from "react";
import FilterInput from "../../../components/Filter/FilterInput";
import OverViewDetailApplication from "./Component/OverViewDetailApplication";
import OverViewDetailRevenue from "./Component/OverViewDetailRevenue";
import OverViewDetailTopRelease from "./Component/OverViewDetailTopRealease";
import OverViewDetailTopStream from "./Component/OverViewDetailTopStream";
import OverViewRegion from "./Component/OverViewRegion";
import OverViewRevenue from "./Component/OverViewRevenue";
import OverViewDetailTotalGrossRevenue from "./Component/OverViewDetailTotalGrossRevenue";
import OverViewDetailTotalStream from "./Component/OverViewDetailTotalStream";
import OverViewDetailTotalRevenue from "./Component/OverViewDetailTotalRevenue";
import OverViewDetailTopArtist from "./Component/OverViewDetailTopArtist";
import { useSelector } from "react-redux";

const { Content } = Layout;
function OverViewMain() {
  const { isAdmin } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState([]);

  const [releaseId, setReleaseId] = useState(null);
  const [tracksId, setTracksId] = useState(null);

  const [valueSort, setValueSort] = useState("1");
  const [revenueSort, setRevenueSort] = useState("1");
  const [detailTotalLoading, setDetailTotalLoading] = useState(false);
  const [detailRevenueLoading, setDetailRevenueLoading] = useState(false);
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [applicationLoading, setApplicationLoading] = useState(false);
  const [releaseLoading, setReleaseLoading] = useState(false);
  const [queryRevenuePlatform, setQueryRevenuePlatform] = useState({})

  const handleToggle = useCallback(() => {
    if (!open.length) {
      setOpen([1]);
    }
  }, [open]);

  return (
    <Spin spinning={loading}>
      <FilterInput
        open={open}
        handleToggle={handleToggle}
        releaseId={releaseId}
        tracksId={tracksId}
        setReleaseId={setReleaseId}
        setTracksId={setTracksId}
        valueSort={valueSort}
        setDetailTotalLoading={setDetailTotalLoading}
        setDetailRevenueLoading={setDetailRevenueLoading}
        setRevenueLoading={setRevenueLoading}
        setApplicationLoading={setApplicationLoading}
        revenueSort={revenueSort}
        queryRevenuePlatform={queryRevenuePlatform}
        setQueryRevenuePlatform={setQueryRevenuePlatform}
      />
      <Content style={{ backgroundColor: "#E5E5E5" }}>
        <div className="pad-10">
          {isAdmin ? (
            <>
              <div className="row no-gutters">
                <div className="col l-7 m-12">
                  <OverViewDetailTotalGrossRevenue
                    detailTotalLoading={detailTotalLoading}
                  />
                </div>
                <div className="col l-5 m-12">
                  <OverViewDetailTotalStream
                    detailTotalLoading={detailTotalLoading}
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col l-7 m-12">
                  <div style={{ height: "38%" }}>
                    <OverViewDetailTotalRevenue
                      detailTotalLoading={detailTotalLoading}
                    />
                  </div>
                  <div style={{ height: "62%" }}>
                    <OverViewDetailRevenue
                      detailRevenueLoading={detailRevenueLoading}
                    />
                  </div>
                </div>
                <div className="col l-5 m-12">
                  <OverViewDetailTopArtist loading={loading} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row no-gutters">
                <div className="col l-7 m-6">
                  <OverViewDetailTotalRevenue
                    detailTotalLoading={detailTotalLoading}
                  />
                </div>
                <div className="col l-5 m-6">
                  <OverViewDetailTotalStream
                    detailTotalLoading={detailTotalLoading}
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col l-7 m-12">
                  <div style={{ height: "100%" }}>
                    <OverViewDetailRevenue
                      detailRevenueLoading={detailRevenueLoading}
                    />
                  </div>
                </div>
                <div className="col l-5 m-12">
                  <OverViewDetailTopArtist loading={loading} />
                </div>
              </div>
            </>
          )}

          <div className="row no-gutters">
            <div className="col l-7 m-12">
              <OverViewDetailApplication
                releaseId={releaseId}
                tracksId={tracksId}
                setValueSort={setValueSort}
                applicationLoading={applicationLoading}
                setApplicationLoading={setApplicationLoading}
                valueSort={valueSort}
                setRevenueSort={setRevenueSort}
                revenueSort={revenueSort}
                queryRevenuePlatform={queryRevenuePlatform}
                setQueryRevenuePlatform={setQueryRevenuePlatform}
              />
            </div>

            <div className="col l-5 m-12">
              <OverViewDetailTopStream loading={loading} />
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col l-7 m-12">
              <OverViewRevenue revenueLoading={revenueLoading} />
            </div>
            <div className="col l-5 m-12">
              <OverViewRegion />
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col l-12 m-12">
              <OverViewDetailTopRelease
                setReleaseLoading={setReleaseLoading}
                releaseLoading={releaseLoading}
                isAdmin={isAdmin}
              />
            </div>
          </div>
        </div>
      </Content>
    </Spin>
  );
}

export default OverViewMain;
