import { BASE_API_URL, BASE_API_URL_CAP_DIEN } from "@utils/constants";
import { APITram } from "../api-url/danh-muc/tram";
import { APICauHinhChung } from "../api-url/danh-muc/cau-hinh-chung";
import { APIUyQuyen } from "../api-url/danh-muc/uy-quyen";
import { APITiLeBanDien } from "../api-url/danh-muc/ti-le-ban-dien";
import { APILo } from "../api-url/danh-muc/lo";
import { APIToDoi } from "../api-url/danh-muc/doi";
import { APIImei } from "../api-url/danh-muc/imei";
import { APINganHang } from "../api-url/danh-muc/ngan-hang";
import { APITheoNgayHieuLuc } from "../api-url/danh-muc/theo-ngay-hieu-luc";
import { APITroNgai } from "../api-url/danh-muc/loai-tro-ngai";
import { APIDiaChinh } from "../api-url/danh-muc/dia-chinh";
import { APISoGhiChiSo } from "../api-url/danh-muc/so-ghi-chi-so";
import { APIMucTroNgai } from "../api-url/danh-muc/muc-tro-ngai";
import { APIVatTuLienSo } from "../api-url/danh-muc/vat-tu-lien-so";
import { APiTinhTrang } from "../api-url/danh-muc/tinh-trang";
import { APICongTo } from "../api-url/danh-muc/cong-to";
import { APILoaiYeuCau } from "../api-url/danh-muc/loai-yeu-cau";
import { APIVungDonGia } from "../api-url/danh-muc/vung-don-gia";
import { APIEmail } from "../api-url/danh-muc/email";
import { APIChungLoaiVatTu } from "../api-url/danh-muc/chung-loai-vat-tu";
import { APIVatTu } from "../api-url/danh-muc/vat-tu";
import { APIDonVi } from "../api-url/danh-muc/don-vi";
import { APIDuyetDoiTruong } from "../api-url/cap-dien/doi-truong/duyet-doi-truong";
import { APIGiaoDichVien } from "../api-url/cap-dien/giao-dich-vien/giao-dich-vien";
import { APIDongBoTiepNhanYeuCau } from "../api-url/cap-dien/dong-bo-tiep-nhan-yeu-cau/dong-bo";
import { APIPhanCong } from "../api-url/cap-dien/phan-cong/phan-cong";
import { APITrangThai } from "../api-url/danh-muc/trang-thai";
import { APISpin } from "../api-url/danh-muc/spin";

export const Endpoint = {
  LOGIN: `${BASE_API_URL}/auth/login`,
  ROOM: `${BASE_API_URL}/user/room`,
  DOI: `${BASE_API_URL}/doi/create`,
  GET_PROFILE: `${BASE_API_URL}/user/profile`,
  GET_MODULE_LIST: `${BASE_API_URL}/module`,
  CREATE_ROLE: `${BASE_API_URL}/role`,

  //common

  GET_UNIT_LIST: `${BASE_API_URL}/common/donvis`,
  GET_POSITION_LIST: `${BASE_API_URL}/common/positions`,

  //danh mục

  //trạm

  LIST_TRAM: APITram.LIST_TRAM,
  SYNC_TRAM: APITram.SYNC_TRAM,
  GET_TRAM: APITram.GET_TRAM,
  CREATE_TRAM: APITram.CREATE_TRAM,
  UPDATE_TRAM: APITram.UPDATE_TRAM,
  REMOVE_TRAM: APITram.REMOVE_TRAM,
  SEARCH_TRAM: APITram.SEARCH_TRAM,

  // Danh mục cấu hình chung

  LIST_CAU_HINH_CHUNG: APICauHinhChung.LIST_CAU_HINH_CHUNG,
  CREATE_CAU_HINH_CHUNG: APICauHinhChung.CREATE_CAU_HINH_CHUNG,
  UPDATE_CAU_HINH_CHUNG: APICauHinhChung.UPDATE_CAU_HINH_CHUNG,
  REMOVE_CAU_HINH_CHUNG: APICauHinhChung.REMOVE_CAU_HINH_CHUNG,
  //tỉ lệ giá bán điện

  LIST_GIA_BAN_DIEN: APITiLeBanDien.LIST_GIA_BAN_DIEN,
  SYNC_GIA_BAN_DIEN: APITiLeBanDien.SYNC_GIA_BAN_DIEN,
  GET_GIA_BAN_DIEN: APITiLeBanDien.GET_GIA_BAN_DIEN,
  CREATE_GIA_BAN_DIEN: APITiLeBanDien.CREATE_GIA_BAN_DIEN,
  UPDATE_GIA_BAN_DIEN: APITiLeBanDien.UPDATE_GIA_BAN_DIEN,
  REMOVE_GIA_BAN_DIEN: APITiLeBanDien.REMOVE_GIA_BAN_DIEN,

  //lộ

  LIST_LO: APILo.LIST_LO,
  SYNC_LO: APILo.SYNC_LO,
  GET_LO: APILo.GET_LO,
  CREATE_LO: APILo.CREATE_LO,
  UPDATE_LO: APILo.UPDATE_LO,
  REMOVE_LO: APILo.REMOVE_LO,

  //tổ đội

  LIST_DOI: APIToDoi.LIST_DOI,
  SYNC_DOI: APIToDoi.SYNC_DOI,
  GET_DOI: APIToDoi.GET_DOI,
  CREATE_DOI: APIToDoi.CREATE_DOI,
  UPDATE_DOI: APIToDoi.UPDATE_DOI,
  REMOVE_DOI: APIToDoi.REMOVE_DOI,

  //imei

  LIST_IMEI: APIImei.LIST_IMEI,
  SYNC_IMEI: APIImei.SYNC_IMEI,
  GET_IMEI: APIImei.GET_IMEI,
  CREATE_IMEI: APIImei.CREATE_IMEI,
  UPDATE_IMEI: APIImei.UPDATE_IMEI,
  REMOVE_IMEI: APIImei.REMOVE_IMEI,
  LIST_NGUOI_DUNG: APIImei.LIST_NGUOI_DUNG,

  //Ngan hang

  LIST_NGAN_HANG: APINganHang.LIST_NGAN_HANG,
  SYNC_NGAN_HANG: APINganHang.SYNC_NGAN_HANG,
  GET_NGAN_HANG: APINganHang.GET_NGAN_HANG,
  CREATE_NGAN_HANG: APINganHang.CREATE_NGAN_HANG,
  UPDATE_NGAN_HANG: APINganHang.UPDATE_NGAN_HANG,
  REMOVE_NGAN_HANG: APINganHang.REMOVE_NGAN_HANG,

  // Ủy quyền

  LIST_UY_QUYEN: APIUyQuyen.LIST_UY_QUYEN,
  CREATE_UY_QUYEN: APIUyQuyen.CREATE_UY_QUYEN,
  UPDATE_UY_QUYEN: APIUyQuyen.UPDATE_UY_QUYEN,
  REMOVE_UY_QUYEN: APIUyQuyen.REMOVE_UY_QUYEN,
  LIST_NGUOI_DUOC_UY_QUYEN: APIUyQuyen.LIST_NGUOI_DUOC_UY_QUYEN,

  // Theo ngày hiệu lực

  LIST_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.LIST_THEO_NGAY_HIEU_LUC,
  CREATE_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.CREATE_THEO_NGAY_HIEU_LUC,
  UPDATE_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.UPDATE_THEO_NGAY_HIEU_LUC,
  REMOVE_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.REMOVE_THEO_NGAY_HIEU_LUC,

  // trở ngại

  LIST_TRO_NGAI: APITroNgai.LIST_TRO_NGAI,
  CREATE_TRO_NGAI: APITroNgai.CREATE_TRO_NGAI,
  UPDATE_TRO_NGAI: APITroNgai.UPDATE_TRO_NGAI,
  REMOVE_TRO_NGAI: APITroNgai.REMOVE_TRO_NGAI,

  // địa chính

  LIST_DIA_CHINH: APIDiaChinh.LIST_DIA_CHINH,
  CREATE_DIA_CHINH: APIDiaChinh.CREATE_DIA_CHINH,
  UPDATE_DIA_CHINH: APIDiaChinh.UPDATE_DIA_CHINH,
  REMOVE_DIA_CHINH: APIDiaChinh.REMOVE_DIA_CHINH,
  SYNC_DIA_CHINH: APIDiaChinh.SYNC_DIA_CHINH,
  // số ghi chỉ số

  LIST_SO_GHI_CHI_SO: APISoGhiChiSo.LIST_SO_GHI_CHI_SO,
  SYNC_SO_GHI_CHI_SO: APISoGhiChiSo.SYNC_SO_GHI_CHI_SO,

  // vật tư liên sở

  LIST_VAT_TU_LIEN_SO: APIVatTuLienSo.LIST_VAT_TU_LIEN_SO,
  SYNC_VAT_TU_LIEN_SO: APIVatTuLienSo.SYNC_VAT_TU_LIEN_SO,

  //     //Tình trạng

  LIST_TINH_TRANG: APiTinhTrang.LIST_TINH_TRANG,
  SYNC_TINH_TRANG: APiTinhTrang.SYNC_TINH_TRANG,
  GET_TINH_TRANG: APiTinhTrang.GET_TINH_TRANG,
  CREATE_TINH_TRANG: APiTinhTrang.CREATE_TINH_TRANG,
  UPDATE_TINH_TRANG: APiTinhTrang.UPDATE_TINH_TRANG,
  REMOVE_TINH_TRANG: APiTinhTrang.REMOVE_TINH_TRANG,

  // công tơ

  LIST_CONG_TO: APICongTo.LIST_CONG_TO,
  SYNC_CONG_TO: APICongTo.SYNC_CONG_TO,

  //trở ngại

  LIST_MUC_TRO_NGAI: APIMucTroNgai.LIST_TRO_NGAI,
  SYNC_MUC_TRO_NGAI: APIMucTroNgai.SYNC_TRO_NGAI,

  //yêu cầu

  LIST_LOAI_YEU_CAU: APILoaiYeuCau.LIST_LOAI_YEU_CAU,
  SYNC_LOAI_YEU_CAU: APILoaiYeuCau.SYNC_LOAI_YEU_CAU,
  GET_LOAI_YEU_CAU: APILoaiYeuCau.GET_LOAI_YEU_CAU,
  CREATE_LOAI_YEU_CAU: APILoaiYeuCau.CREATE_LOAI_YEU_CAU,
  UPDATE_LOAI_YEU_CAU: APILoaiYeuCau.UPDATE_LOAI_YEU_CAU,
  REMOVE_LOAI_YEU_CAU: APILoaiYeuCau.REMOVE_LOAI_YEU_CAU,

  //chủng loại vật tư

  LIST_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.LIST_CHUNG_LOAI_VAT_TU,
  SYNC_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.SYNC_CHUNG_LOAI_VAT_TU,
  GET_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.GET_CHUNG_LOAI_VAT_TU,
  CREATE_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.CREATE_CHUNG_LOAI_VAT_TU,
  UPDATE_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.UPDATE_CHUNG_LOAI_VAT_TU,
  REMOVE_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.REMOVE_CHUNG_LOAI_VAT_TU,

  //vật tư

  LIST_VAT_TU: APIVatTu.LIST_VAT_TU,
  SYNC_VAT_TU: APIVatTu.SYNC_VAT_TU,
  GET_VAT_TU: APIVatTu.GET_VAT_TU,
  CREATE_VAT_TU: APIVatTu.CREATE_VAT_TU,
  UPDATE_VAT_TU: APIVatTu.UPDATE_VAT_TU,
  REMOVE_VAT_TU: APIVatTu.REMOVE_VAT_TU,

  //Email

  LIST_EMAIL: APIEmail.LIST_EMAIL,
  SYNC_EMAIL: APIEmail.SYNC_EMAIL,
  GET_EMAIL: APIEmail.GET_EMAIL,
  CREATE_EMAIL: APIEmail.CREATE_EMAIL,
  UPDATE_EMAIL: APIEmail.UPDATE_EMAIL,
  REMOVE_EMAIL: APIEmail.REMOVE_EMAIL,

  //Vùng đơn giá

  LIST_VUNG_DON_GIA: APIVungDonGia.LIST_VUNG_DON_GIA,
  SYNC_VUNG_DON_GIA: APIVungDonGia.SYNC_VUNG_DON_GIA,
  GET_VUNG_DON_GIA: APIVungDonGia.GET_VUNG_DON_GIA,
  CREATE_VUNG_DON_GIA: APIVungDonGia.CREATE_VUNG_DON_GIA,
  UPDATE_VUNG_DON_GIA: APIVungDonGia.UPDATE_VUNG_DON_GIA,
  REMOVE_VUNG_DON_GIA: APIVungDonGia.REMOVE_VUNG_DON_GIA,

  //đơn vị

  LIST_DON_VI: APIDonVi.LIST_DON_VI,
  SYNC_DON_VI: APIDonVi.SYNC_DON_VI,
  GET_DON_VI: APIDonVi.GET_DON_VI,
  CREATE_DON_VI: APIDonVi.CREATE_DON_VI,
  UPDATE_DON_VI: APIDonVi.UPDATE_DON_VI,
  REMOVE_DON_VI: APIDonVi.REMOVE_DON_VI,

  // duyệt đội trưởng - hồ sơ

  LIST_HO_SO: APIDuyetDoiTruong.LIST_HO_SO,
  CREATE_HO_SO: APIDuyetDoiTruong.CREATE_HO_SO,
  UPDATE_HO_SO: APIDuyetDoiTruong.UPDATE_HO_SO,
  REMOVE_HO_SO: APIDuyetDoiTruong.REMOVE_HO_SO,

  //Giao dich vien

  LIST_GIAO_DICH_VIEN: APIGiaoDichVien.LIST_GIAO_DICH_VIEN,
  CREATE_GIAO_DICH_VIEN: APIGiaoDichVien.CREATE_GIAO_DICH_VIEN,
  UPDATE_GIAO_DICH_VIEN: APIGiaoDichVien.UPDATE_GIAO_DICH_VIEN,
  REMOVE_GIAO_DICH_VIEN: APIGiaoDichVien.REMOVE_GIAO_DICH_VIEN,

  //đồng bộ tiếp nhận yêu cầu

  SYNC_TIEP_NHAN_YEU_CAU: APIDongBoTiepNhanYeuCau.SYNC_TIEP_NHAN_YEU_CAU,

  //phân công

  LIST_PHAN_CONG: APIPhanCong.LIST_PHAN_CONG,
  GET_PHAN_CONG: APIPhanCong.GET_PHAN_CONG,
  UPDATE_PHAN_CONG: APIPhanCong.UPDATE_PHAN_CONG,
  UPDATE_PHAN_CONG_LAI: APIPhanCong.UPDATE_PHAN_CONG_LAI,

  //trạng thái

  LIST_TRANG_THAI: APITrangThai.LIST_TRANG_THAI,
  CREATE_TRANG_THAI: APITrangThai.CREATE_TRANG_THAI,
  UPDATE_TRANG_THAI: APITrangThai.UPDATE_TRANG_THAI,
  REMOVE_TRANG_THAI: APITrangThai.REMOVE_TRANG_THAI,

  //Spin
  
  ENABLE_USER: APISpin.ENABLE_USER,
};
