import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  SmileOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import { getErrorForm, mapLanguageToCulture } from "@utils/function";
import { authDeleteData, authGetData, authPostData, authPutData } from "@utils/request";
import { BASE_API_URL } from "@utils/constants";
import {
  Button,
  Col,
  Form,
  Input,
  Spin,
  Upload,
  notification,
} from "antd";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Common = () => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const [form] = Form.useForm();
  const [load, setLoading] = useState(false);

  const [guideFileName, setGuideFileName] = useState(null);
  const [guideLinkFile, setGuideLinkFile] = useState(null);
  const [loadingUploadFilePdf, setLoadingUploadFilePdf] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const getData = () => {
    authGetData({
      url: `api/v1/contact/get-contact?culture=${culture}`,
      method: "GET",
      setLoading: setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          form.setFieldsValue(res.data);
          setGuideFileName(res.data?.pdfName);
          setGuideLinkFile(res.data?.pdfLink);
        }
      },
    });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [culture]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onFinish = (values) => {
    const payload = { ...values, pdfLink: guideLinkFile, pdfName: guideFileName }

    authPostData({
      url: `api/v1/contact/update-contact?culture=${culture}`,
      method: "POST",
      payload: payload,
      setLoading: setLoading,
      setImportLoading: () => { },
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: res.message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const layout = {
    labelCol: { xxl: 4, xl: 5, lg: 6, md: 6 },
    wrapperCol: { xxl: 10, xl: 10, lg: 12, md: 12 },
  };

  //remove file

  const removeFile = () => {
    setGuideFileName(null);
    setGuideLinkFile(null);
  };

  const beforeUploadPdf = (file) => {
    const isTypePdf =
      file.name.toLowerCase().endsWith() === "pdf" ||
      file.type === "application/pdf";
    if (!isTypePdf) {
      notification.error({
        message: t('Notification'),
        description: t('The only permitted file format for download is .pdf'),
      });
    }
    return isTypePdf;
  };

  const viewFile = (guideLinkFile) => {
    window.open(`${guideLinkFile}`);
  }

  //render file đã upload

  const renderFile = useCallback(() => {
    if (guideFileName && guideLinkFile) {
      return (
        <div
          key={guideFileName}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "98%",
            alignItems: "center",
          }}
        >
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              width: "100%",
              cursor: "pointer",
              color: "#1890ff",
              alignItems: "center",
              textDecoration: isHovered ? 'underline' : 'none',
            }}
            onClick={() => viewFile(guideLinkFile)}
          >
            {guideFileName}
          </div>
          <div onClick={() => removeFile()} style={{ cursor: "pointer" }}>
            <DeleteOutlined />
          </div>
        </div>
      );
    } else return null;
  }, [guideFileName, guideLinkFile, isHovered]);

  const handleFileChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingUploadFilePdf(false);
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t('Success'),
        description: t('Uploaded app user guide file successfully'),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setGuideFileName(info.file.response.data.fileName);
      setGuideLinkFile(info.file.response.data.linkFile);
    }
  };

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const pattern = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
    const isValidPattern = pattern.test(value);
    const isValidLength = value.length >= 7 && value.length <= 20;

    if (isValidPattern && isValidLength) {
      return Promise.resolve();
    } else {
      return Promise.reject(t('Invalid phone number format'));
    }
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div>
          <Form
            form={form}
            onFinish={onFinish}
            {...layout}
          >
            <Form.Item
              label={t('Phone number')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('Phone number is required'),
                },
                { validator: validatePhoneNumber },
              ]}
              labelAlign="left"
            >
              <Input placeholder={t('Input contect phone number')} />
            </Form.Item>
            <Form.Item
              label={t('Address')}
              name="address"
              rules={[
                { required: true, message: t('Please input address') },
              ]}
              labelAlign="left"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label={t('Email')}
              rules={[
                { type: "email", message: t('Invalid email format') },
                { required: true, message: t('Please input email') },
              ]}
              labelAlign="left"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('The app user guide file')}
              labelAlign="left"
            >
              <Upload
                name="File"
                showUploadList={false}
                action={BASE_API_URL + `api/v1/artist/up-file-pdf`}
                beforeUpload={beforeUploadPdf}
                onChange={handleFileChange}
                multiple={false}
                loading={loadingUploadFilePdf}
                disabled={loadingUploadFilePdf}
                className="upload-file"
                style={{ width: "100%", display: "block" }}
              >
                <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
                  {t('Select or drag drop to download files')} (*.pdf)
                </Button>
              </Upload>
              {renderFile()}
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: { xl: 6, lg: 9, md: 9 } }}>
              <Button type="primary" htmlType="submit" className="button">
                {t('Set up')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </ContentStyled>
    </Spin>
  );
};

export default memo(Common);

const ContentStyled = styled.div`
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .ant-form-item-label > label {
    display: block;
    white-space: normal;
  }

  .upload-file .ant-upload.ant-upload-select {
    display: inline-block;
    width: 100% !important;
  }

  @media (max-width: 992px) {
    .ant-input {
      height: 44px;
    }
  }
`;

const RenderFileContainer = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  border: 1px solid rgb(217, 217, 217);
  background: rgb(250, 250, 250);

  div {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 1;
    color: #c4c4c4;
    margin: 0;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
  }
  input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
`;

const UploadFileContainer = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  border: 1px solid rgb(217, 217, 217);
  background: rgb(250, 250, 250);

  div {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 1;
    color: #c4c4c4;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
`;
