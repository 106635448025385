import React, { memo, useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useSelector } from "react-redux";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

function OverViewRegion() {
  const {t} = useTranslation();
  const countryData = useSelector((state) => state.date.countryData); 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const datas =
    countryData &&
    countryData.map((item) => {
      return {
        name: item.countryName,
        value: item.countryValue,
      };
    });

  const COLORS = [
    "#3399ff",
    "#c882f1",
    "#ff69b6",
    "#ff7766",
    "#ffa600",
    "#65789B",
  ];

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          listStyle: "unset",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: `${entry.color}`,
            }}
          >
            {entry.value}:{" "}
            {entry.payload.payload.value
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
            ({ (index + 1 != payload.length || payload.length == 1) ? (entry.payload.percent * 100).toFixed(1) : countryData[countryData.length - 1].percentView }%)
            {}
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
            fontSize: "11px",
          }}
        >
          <label>
            {payload[0].name} : {payload[0].value
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </label>
        </div>
      )
    };
    return null;
  };

  return (
    <div className="overview-region">
      {countryData !== null && countryData.length > 0 ? (
        <div className="overview-region-container">
            <h1>{t('Top stream/download [By Teritories]')}</h1>
            <div style={{ width: '100%' }}>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart
                style={{ display: 'flex', flexDirection: 'row', width: "100%" }}
              >
                <Pie
                  width="55%"
                  data={datas}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius="75%" // sizeOfPieChart
                  fill="#8884d8"
                  dataKey="value"
                >
                  {datas.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  width="40%"
                  align="right"
                  verticalAlign="middle"
                  layout="vertical"
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="overview-region-container-empty">
          <h1>{t('Top stream/download [By Teritories]')}</h1>
          <Empty description={t('No Records Found')} />
        </div>
      )}
    </div>
  );
}

export default memo(OverViewRegion);
