import OverviewMain from "@pages/Admin/Overview/OverviewMain";
import PaymentMain from "@pages/Admin/PaymentManagement/PaymentMain";
import AccountInfoMain from "@pages/User/Profile/ProfileMain";
import StatisticMain from "@pages/Admin/Statistic/StatisticMain";
import PaymentHistoryMain from "@pages/Admin/PaymentHistory/PaymentHistoryMain";
import UserManagementMain from "@pages/Admin/UserManagement/UserManagementMain";
import InforMain from "@pages/Admin/UserManagement/Infor/InforMain";
import AlbumArtistMain from "@pages/Admin/AlbumManagement/AlbumArtistMain";
import { Layout, BackTop, message } from "antd";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import Footer from "./Footer";
import Sider from "./Sider";
import { IoChevronUpOutline } from "react-icons/io5";
import { useEffect } from "react";
import HeaderMain from "./HeaderMain";

const { Content } = Layout;

export default function UserComponent() {
  const location = useLocation();

  useEffect(() => {
    message.destroy();

    if (
      location.pathname === "/user/import-csv" ||
      location.pathname === "/user/user-management" ||
      location.pathname === "/user/album-management" ||
      location.pathname.includes("/user/setting")
    )
      return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <StyledHeader>
        <HeaderMain />
      </StyledHeader>
      <div style={{marginTop: "53px"}}>
        <Sider />
        <Styled>
          <Content>
            <Routes>
              <Route path="/profile" element={<AccountInfoMain />} />
              <Route path="/overview" element={<OverviewMain />} />
              <Route path="/statistic" element={<StatisticMain />} />
              <Route path="/artist-management" element={<AlbumArtistMain />} />
              <Route path="/user-management" element={<UserManagementMain />} />
              <Route path="/user-management/:id" element={<InforMain />} />
              <Route path="/payment-management" element={<PaymentMain />} />
              <Route path="/payment-history" element={<PaymentHistoryMain />} />
            </Routes>

            <Footer />
          </Content>
        </Styled>
      </div>

      <BackTop style={{ zIndex: "10000" }}>
        <IoChevronUpOutline />
      </BackTop>
    </>
  );
}

const Styled = styled.div`
  .ant-layout-content {
    background-color: rgb(229, 229, 229);
    margin-left: 210px;
    height: 100%;
  }
  .ant-layout-content .ant-layout-content {
    margin-left: 0;
  }

  @media (max-width: 980px) {
    .ant-layout-content {
      margin-left: 160px;
    }
  }
`;

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`
