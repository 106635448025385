import usaFlag from './image/co-my.png';
import vietnamFlag from './image/co-vn.png';
import euFlag from './image/co-eu.png'

export const LIST_LOCALE = [
  {
    value: 'en',
    label: 'US',
    image: usaFlag,
  },
  {
    value: 'vi',
    label: 'VN',
    image: vietnamFlag,
  },
];

export const LIST_CURRENCY = [
  {
    value: 'eur',
    label: 'EUR(€)',
    image: euFlag,
  },
  {
    value: 'vnd',
    label: 'VND(₫)',
    image: vietnamFlag,
  },
];
