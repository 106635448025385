import { Button, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import usersIcon from './image/users.png';
import LogoutIcon from "@assets/icon/LogoutIcon";
import AvatarDefault from "@assets/images/Frame 3019.png";
import { LIST_CURRENCY, LIST_LOCALE } from "./constants";
import i18n from "../../i18n";
import { authPostData } from "@utils/request";
import { authGetData } from "@utils/request";
import { logoutSuccess } from "../../redux/Auth/authReducer";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
    setValueArtist,
    setValueViewer,
    setValueOwner,
    setReleaseId,
    setTrackId,
    setEndDay,
    setStartDay
} from "../../redux/Date/DateReducer";
import AccountInfo from "./AccountInfo";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";

const HeaderMain = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isAdmin, isAuthenticated } = useSelector((state) => state.auth);
    const [profile, setProfile] = useState("");
    const [currentLanguage, setCurrentLanguage] = useState("vi");
    const [currentCurrency, setCurrentCurrency] = useState("vnd");
    const payloaded = {
        token: "",
        versioning: "",
        platform: ""
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage");
        const savedCurrency = localStorage.getItem("selectedCurrency");
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
            setCurrentLanguage(savedLanguage);
        }
        if (savedCurrency) {
            setCurrentCurrency(savedCurrency);
        }
    }, []);

    const changeLanguage = (e) => {
        const selectedLanguage = e;
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem("selectedLanguage", selectedLanguage);
        setCurrentLanguage(selectedLanguage);
    };

    const changeCurrency = (e) => {
        const selectedCurrency = e;
        i18n.changeLanguage(currentLanguage);
        localStorage.setItem("selectedCurrency", selectedCurrency);
        setCurrentCurrency(selectedCurrency);
    }

    useEffect(() => {
        if (window.location.pathname.includes("/admin/") || window.location.pathname.includes("/user/")) {
            authGetData({
                url: `api/v1/user/profile`,
                setLoading: () => { },
                onSuccess: (res) => {
                    if (res.statusCode === 200) {
                        setProfile(res?.data);
                        if (res.data.labelName == "DeleteUser1234567890") {
                            dispatch(setStartDay(null));
                            dispatch(setEndDay(null));
                            dispatch(setValueOwner(false));
                            dispatch(setValueArtist(""));
                            dispatch(setValueViewer(""));
                            dispatch(setReleaseId(""));
                            dispatch(setTrackId(""));
                            authPostData({
                                url: "api/v1/user/logout",
                                method: "POST",
                                payload: payloaded,
                                setLoading: () => { },
                                setImportLoading: () => { },
                            });
                            dispatch(logoutSuccess());
                        }
                    }
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    const handleRedirectURL = () => {
        if (isAdmin) {
            return "/admin"
        } else {
            return "/user"
        }
    }

    return (
        <>
            <Styled>
                <div className="logo" >
                    <NavLink to={"overview"}>
                        <img src="../LogoMain.svg" alt="logo" />
                    </NavLink>
                </div>
                <div className="flex">
                    {
                        !window.location.pathname.includes("/landing-page/") &&
                        <div className="currency">
                            <Select
                                id="currency-select"
                                onChange={changeCurrency}
                                value={currentCurrency}
                                defaultValue={currentCurrency}
                            >
                                {LIST_CURRENCY.map((item) => {
                                    return (
                                        <Option key={item.value} value={item.value}>
                                            <Space>
                                                <div>
                                                    <img
                                                        src={item.image}
                                                        alt=""
                                                        style={{ width: '24px', marginRight: '8px' }}
                                                        width={24}
                                                        height={20}
                                                    />
                                                    {item.label}
                                                </div>
                                            </Space>
                                        </Option>
                                    );
                                }
                                )}
                            </Select>
                        </div>
                    }

                    <div className="translate">
                        <Select
                            id="language-select"
                            onChange={changeLanguage}
                            value={currentLanguage}
                            defaultValue={currentLanguage}
                        >
                            {LIST_LOCALE.map((item) => {
                                return (
                                    <Option key={item.value} value={item.value}>
                                        <Space>
                                            <div>
                                                <img
                                                    src={item.image}
                                                    alt=""
                                                    style={{ width: '24px', marginRight: '8px' }}
                                                    width={24}
                                                    height={20}
                                                />
                                                {item.label}
                                            </div>
                                        </Space>
                                    </Option>
                                );
                            }
                            )}
                        </Select>
                    </div>

                    {
                        window.location.pathname.includes("/landing-page/") ?
                            <NavLink to={isAuthenticated ? handleRedirectURL() : "/login"} >
                                <UserOutlined style={{
                                    fontSize: "18px",
                                    color: "#fff"
                                }} />
                            </NavLink>
                            :
                            <NavLink to={"/landing-page"} >
                                <img src={usersIcon} alt="users" width="30px" height="30px" />
                            </NavLink>
                    }

                    {
                        (isAuthenticated && !window.location.pathname.includes("/landing-page/")) &&
                        <div className="user">
                            <div className="user-name ml-[10px]">
                                <NavLink style={{ color: "#fff" }} to={isAdmin ? "/admin/profile" : "/user/profile"} >
                                    <span style={{ color: "#fff" }}>{profile.fullName}</span>
                                </NavLink>
                            </div>
                            <div width="46px">
                                <AccountInfo
                                    profile={profile}
                                    payloaded={payloaded}
                                />
                            </div>
                        </div>
                    }

                    {
                        !isAuthenticated &&
                        <div className="user">
                            <Button
                                type="primary"
                                size="small"
                            >
                                <NavLink to={"/login"} >
                                    <span>{t('Login')}</span>
                                </NavLink>
                            </Button>
                        </div>
                    }
                </div>
            </Styled>
        </>
    )
}

export default HeaderMain;

const Styled = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(181, 181, 181, 0.25);
    background-color: #003366;
    align-items: center;
    padding: 8px 20px;
    min-height: 53px;

    .flex {
        align-items: center;
        gap: 20px;
    }

    .currency, .translate {
        .ant-select {
            width: 100%;
            font-size: 12px;
        }
        .ant-select-selector {
            height: 32px;
            padding: 0 6px;
            background-color: #003366;
            color: #fff;
            border-color: #003366;
        }
        .ant-select-arrow {
            display: none;
        }
    }

    .currency {
        width: 110px;
    }

    .translate {
        width: 84px;
    }

    .user {
        display: flex;
        align-items: center;
        color: #fff;
    }

    .user-name {
        display: flex;
        max-width: 120px;
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

`