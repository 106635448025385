import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, Spin, notification, Checkbox } from "antd";
import { authPutData } from "@utils/request";
import { SmileOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture } from "@utils/function";

const EmailMain = ({ data, loading, getData }) => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const [checked, setChecked] = useState(data.EnableSsl);
  const [load, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    }
  }, [form, data]);
  const onFinish = (values) => {
    const formData = {
      ...values,
      email: values.UserName,
      EnableSsl: checked,
    };
    authPutData({
      url: `api/v1/admin/setting/email?culture=${culture}`,
      method: "PUT",
      payload: formData,
      setLoading,
      onSuccess: (res) => {
        getData();
        if (res.statusCode === 200) {
          notification.open({
            message: t('Success'),
            description: t('Update information success'),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        }
      },
    });
  };
  const layout = {
    labelCol: { xl: 8, lg: 12, md: 12},
    wrapperCol: { xl: 15, lg: 12, md: 12 },
  };
  return (
    <ContentStyled>
      <Spin spinning={loading}>
        <h2>{t('Set up email')}</h2>
        <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
          <Form.Item
            name="UserName"
            label={t('Email')}
            rules={[
              { type: "email", message: t('Invalid email format') },
              { required: true, message: t('Please input email') },
            ]}
            labelAlign="left"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="PassWord"
            label={t('Password')}
            rules={[{ required: true, message: t('Please input password') }]}
            labelAlign="left"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="Host"
            label={t('Host')}
            rules={[{ required: true, message: t('Please input host') }]}
            labelAlign="left"
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            name="Port"
            label={t('Port')}
            rules={[{ required: true, message: t('Please input post') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              labelAlign="left"
              name="ExpireOtp"
              label={t('OTP timeout (seconds)')}
              rules={[{ required: true, message: t('Please input expiry time OTP')}]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name="ResendOtp"
              label={t('OTP resend timeout (seconds)')}
              rules={[{ required: true, message: t('Please input waiting time to request OTP resend')}]}
            >
              <Input type="number" />
            </Form.Item>
          <Form.Item name="EnableSsl" label={t('EnableSsl')} labelAlign="left">
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
            ></Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
            <Button type="primary" htmlType="submit" className="button">
              {t('Set up')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </ContentStyled>
  );
};

export default memo(EmailMain);

const ContentStyled = styled.div`
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .ant-form-item-label > label {
    display: block;
    white-space: normal;
  }

  @media (max-width: 992px) {
    .ant-input {
      height: 44px;
    }
  }
`;
