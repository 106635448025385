import PasswordIcon from "@assets/icon/PasswordIcon";
import UserIcon from "@assets/icon/UserIcon";
import Footer from "@components/Layout/Footer";
import { authPostData } from "@utils/request";
import i18n from "../../i18n";
import { Button, Form, Input, Select, Space, Spin, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LogoComponent from "../../components/Layout/Logo";
import { signInFinish } from "../../redux/Auth/authReducer";
import { LIST_LOCALE } from "../../components/Layout/constants";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isAdmin } = useSelector((state) => state.auth);
  const [currentLanguage, setCurrentLanguage] = useState("vi");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { Option } = Select;

  useEffect(() => {
    if (isAuthenticated) {
      if (isAdmin) {
        navigate("/admin");
      } else {
        navigate("/user");
      }
    }
  }, [isAuthenticated, navigate, isAdmin]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setCurrentLanguage(savedLanguage);
    }
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      authPostData({
        url: "api/v1/user/login",
        method: "POST",
        payload: values,
        setLoading,
        setImportLoading: () => { },
        onSuccess: (res) => {
          if (res.statusCode === 200 && res.data.userId == null) {
            dispatch(signInFinish(res.data));
            localStorage.setItem("selectedCurrency", "vnd");
            const savedLanguage = localStorage.getItem("selectedLanguage");
            if (!savedLanguage) {
              i18n.changeLanguage("vi");
              localStorage.setItem("selectedLanguage", "vi");
            }
          }
          else {
            navigate("/change-password-user?email=" + values.userName + "&type=2");
          }
        },
      });
    },
    [dispatch]
  );

  const changeLanguage = (e) => {
    const selectedLanguage = e;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
    setCurrentLanguage(selectedLanguage);
  };

  return (
    <Spin spinning={loading}>
      <div className="login-form--wrap">
        <div className="login-container">
          <TranslateLanguage>
            <Select
              id="language-select"
              onChange={changeLanguage}
              value={currentLanguage}
              defaultValue={currentLanguage}
              style={{ minWidth: '77px' }}
            >
              {LIST_LOCALE.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    <Space>
                      <div>
                        <img
                          src={item.image}
                          alt=""
                          style={{ width: '24px', marginRight: '8px' }}
                          width={24}
                          height={20}
                        />
                        {item.label}
                      </div>
                    </Space>
                  </Option>
                );
              }
              )}
            </Select>
          </TranslateLanguage>
          <LogoComponent />
          <div className="login-form">
            <div className="form-title">
              <span>{t('Login')}</span>
            </div>
            <div className="form-description">
              <span>{t('Sign in to your account to continue')}</span>
            </div>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              autoComplete="off"
              className="form-login"
            >
              <Form.Item
                name="userName"
                rules={[
                  {
                    type: "email",
                    message: t('E-mail format is incorrect!'),
                  },
                  {
                    required: true,
                    message: t('Please enter email') + '!',
                  },
                ]}
              >
                <Input
                  className="form-input"
                  size="large"
                  placeholder={t('Please enter email')}
                  prefix={
                    <UserIcon style={{ marginRight: "10px !important" }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('Please enter password') + '!',
                  },
                ]}
              >
                <Input.Password
                  autoComplete="on"
                  className="form-input"
                  size="large"
                  name="password"
                  placeholder={t('Please enter password')}
                  prefix={<PasswordIcon style={{ marginRight: "12px" }} />}
                />
              </Form.Item>
              {/* <Form.Item valuePropName="checked">
                <Switch />
                <span className="login-remember">Lưu đăng nhập</span>
              </Form.Item> */}

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className="full login-button"
                  disabled={loading}
                >
                  <span>{t('Login')}</span>
                </Button>
              </Form.Item>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
                <div className="form-forgot-password">
                  <Link to="/landing-page"><ArrowLeftOutlined style={{ marginRight: "5px" }} />{t('Go to home page')}</Link>
                </div>
                <div className="form-forgot-password">
                  <Link to="/forget-password">{t('Forgot password?')}</Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <Footer />
      </div >
    </Spin >
  );
}

const TranslateLanguage = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;

    .ant-select {
      color: #fff
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: 1px solid #40a9ff !important;
    }

    .ant-select-arrow {
      color: #fff
    }
`