import TableComponent from "@components/TableComponent";
import {
  handlePagination,
  mapCurrencyToCulture,
  mapLanguageToCulture,
} from "@utils/function";
import { authPostData } from "@utils/request";
import { ConfigProvider, Empty } from "antd";
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import PaymentHistoryFilter from "./Components/PaymentHistoryFilter";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGESIZE } from "@utils/constants";
const PaymentHistoryMain = () => {
  const { t } = useTranslation();
  const culture = mapLanguageToCulture();
  const currency = mapCurrencyToCulture();
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [total, setTotal] = useState();
  const [dataPaymentHistory, setDataPaymentHistory] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [userIds, setUserIds] = useState();

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };

  const getdata = useCallback(() => {
    authPostData({
      url: `api/v1/income/transaction?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading: setLoadingList,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataPaymentHistory(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
              clientNetRevenue: parseFloat(e.clientNetRevenue.toFixed(3))
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
              userNetRevenue: parseFloat(
                (e.netRevenue - e.clientNetRevenue).toFixed(3)
              )
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
      currency,
    });
  }, [filterConditions, culture, currency]);

  useEffect(() => {
    getdata();
  }, [getdata, filterConditions, culture, currency]);

  const columns = [
    {
      title: t("No."),
      dataIndex: "key",
      align: "center",
      width: "4%",
    },
    {
      title: t("Labels"),
      dataIndex: "userName",
      align: "left",
      width: "12%",
    },
    {
      title: t("Releases"),
      dataIndex: "releaseTitle",
      align: "left",
      width: "14%",
    },
    {
      title: t("Tracks"),
      dataIndex: "trackTitle",
      align: "left",
      width: "22%",
    },
    {
      title:
        currency === "EUR"
          ? t("Label of revenue") + " (€)"
          : t("Label of revenue") + " (đ)",
      dataIndex: "clientNetRevenue",
      align: "right",
      width: "14%",
    },
    {
      title:
        currency === "EUR"
          ? t("3S of revenue") + " (€)"
          : t("3S of revenue") + " (đ)",
      dataIndex: "userNetRevenue",
      align: "right",
      width: "12%",
    },
    {
      title: t("Status"),
      render: (text, record) => {
        return record.isPaid ? (
          <p style={{ color: "#2EC957", marginTop: "10px" }}>{t("Success")}</p>
        ) : (
          <p>{t("Unpaid")}</p>
        );
      },
      align: "left",
      width: "10%",
    },
    {
      title: t("Payment time"),
      dataIndex: "paidDate",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm:ss"),
      align: "left",
      width: "12%",
    },
  ];

  return (
    <Styled>
      <PaymentHistoryFilter
        loading={loading}
        filterConditions={filterConditions}
        setTotal={setTotal}
        setLoading={setLoading}
        setFilterConditions={setFilterConditions}
        setUserIds={setUserIds}
        userIds={userIds}
      />
      <div className="table-data--wrap">
        <div className="table-data--main">
          <div className="header">
            <div className="title">{t("Transaction list")}</div>
          </div>
          <div className="content">
            <ConfigProvider
              renderEmpty={() => <Empty description={t("No Records Found")} />}
            >
              <TableComponent
                rowKey="key"
                dataSource={dataPaymentHistory}
                columns={columns}
                totalData={total}
                loading={loadingList}
                onChangePagination={onChangePagination}
                pagination={filterConditions}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default PaymentHistoryMain;

const Styled = styled.div`
  .title {
    display: flex;
    align-items: center;
  }
  .filter-wrap .ant-collapse-content {
    border-radius: 8px;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
`;
