import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { mapCurrencyToCulture } from "@utils/function";

function OverViewDetailRevenue() {
  const { t } = useTranslation();
  const currency = mapCurrencyToCulture();
  const userData = useSelector((state) => state.auth.user);
  const incomeData = useSelector((state) => state.date.incomeData);
  const data = [
    {
      name: "3S Intersoft",
      totalRevenue:
        userData.isAdmin &&
          incomeData !== null &&
          incomeData.admin &&
          incomeData.admin.totalRevenue !== 0
          ? parseFloat(Number(incomeData.admin.totalRevenue.toFixed(3)))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0",
      paidRevenue:
        userData.isAdmin &&
          incomeData !== null &&
          incomeData.admin &&
          incomeData.admin.paidRevenue !== 0
          ? parseFloat(incomeData.admin.paidRevenue.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0",
    },
    {
      name: t('Labels'),
      totalRevenue:
        incomeData !== null &&
          incomeData.admin &&
          incomeData.user.totalRevenue !== 0
          ? parseFloat(incomeData.user.totalRevenue.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0",
      paidRevenue:
        incomeData !== null &&
          incomeData.user &&
          incomeData.user.totalRevenue !== 0
          ? parseFloat(incomeData.user.paidRevenue.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0",
    },
  ];

  const dataUser = [
    {
      name: t('Artist'),
      totalRevenue:
        incomeData !== null
          ? parseFloat(incomeData.user.totalRevenue.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0",
      paidRevenue:
        incomeData !== null
          ? parseFloat(incomeData.user.paidRevenue.toFixed(3))
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
          : "0",
    },
  ];

  return (
    <div className="overview-detail-revenue">
      <div className="overview-detail-revenue-item">
        <div className="overview-detail-revenue-item-beside">
          <h1>{t('Revenue Split')}</h1>
          {userData.isAdmin ? (
            data.map((item, index) => {
              const { name, totalRevenue, paidRevenue } = item;
              return (
                <div
                  key={index}
                  className={
                    name === "3S Intersoft"
                      ? "overview-detail-revenue-item-body overview-detail-revenue-3s"
                      : "overview-detail-revenue-item-body overview-detail-revenue-artist"
                  }
                >
                  <div className="overview-detail-revenue-item-body-beside">
                    <h2>{name}</h2>
                    <div className="overview-detail-revenue-item-body-mid">
                      {currency === 'EUR' ? (
                        <p>{totalRevenue} €</p>
                        ) : (
                        <p>{totalRevenue} đ</p>
                      )}
                      <span>{t('Total Revenue')}</span>
                    </div>
                    <div className="overview-detail-revenue-item-body-side">
                      {currency === 'EUR' ? (
                        <p>{paidRevenue} €</p>
                        ) : (
                        <p>{paidRevenue} đ</p>
                      )}
                      <span>{t('Paid')}</span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className={
                "overview-detail-revenue-item-body overview-detail-revenue-artist"
              }
              style={{
                padding: "20px",
                backgroundColor: "#3399ff",
                marginTop: "80px",
                marginBottom: "80px",
              }}
            >
              <div className="overview-detail-revenue-item-body-beside">
                <h2>{t('Labels')}</h2>
                <div className="overview-detail-revenue-item-body-mid">
                  {currency === 'EUR' ? (
                    <p>
                      {incomeData !== null ? dataUser[0].totalRevenue : 0} €
                    </p>
                  ) : (
                    <p>
                      {incomeData !== null ? dataUser[0].totalRevenue : 0} đ
                    </p>
                  )}
                  <span>{t('Total Revenue')}</span>
                </div>
                <div className="overview-detail-revenue-item-body-side">
                  {currency === 'EUR' ? (
                    <p>
                      {incomeData !== null ? dataUser[0].paidRevenue : 0} €
                    </p>
                  ) : (
                    <p>
                      {incomeData !== null ? dataUser[0].paidRevenue : 0} đ
                    </p>
                  )}
                  <span>{t('Paid')}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default memo(OverViewDetailRevenue);
