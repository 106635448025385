import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import { BASE_API_URL } from "@utils/constants";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import {
  beforeUpload,
  handlePagination,
  mapLanguageToCulture,
  removeAccents,
} from "@utils/function";
import { authGetData, authPostData, authPutData } from "@utils/request";
import { authDeleteData } from "@utils/request";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Upload,
  Table,
  message,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setValueArtist, setReleaseId } from "../../../redux/Date/DateReducer";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalSocialLink from "./Modal/ModalSocialLink";
import ModalDeleteLink from "./Modal/ModalDeleteLink";
const { Option } = Select;

const AlbumMain = () => {
  const { t } = useTranslation();
  const culture = mapLanguageToCulture();
  const cancelTokenSource = useRef();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [total, setTotal] = useState();
  const [id, setId] = useState();
  const [updateLink, setUpdateLink] = useState();
  const [dataAlbum, setDataAlbum] = useState([]);
  const [load, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({ imageUrl: null, loading: false });
  const dispatch = useDispatch();

  const valueArtist = useSelector((state) => state.date.valueArtist);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [userIds, setUserIds] = useState([]);
  const [releaseIds, setReleaseIds] = useState([]);

  const [dataLink, setDataLink] = useState([]);
  const [dataLinkDetail, setDataLinkDetail] = useState({});
  const [visibleSocialLink, setVisibleSocialLink] = useState(false);
  const [visibleDeleteLink, setVisibleDeleteLink] = useState(false);
  const [index, setIndex] = useState(null);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      notification.open({
        message: t("Success"),
        description: t("Upload photo successful"),
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setUpdateLink(info.file.response.data);
      setState({
        imageUrl: info.file.response.data,
        loading: false,
      });
    }
  };

  const getData = () => {
    authPostData({
      url: `api/v1/release?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading: setLoadingList,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataAlbum(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
        }
      },
      cancelToken: cancelTokenSource.current,
    });
  };

  useEffect(() => {
    getData();
  }, [filterConditions, culture]);

  const columns = [
    {
      title: t("No."),
      dataIndex: "key",
      align: "center",
      width: "4%",
    },
    {
      title: t("Releases"),
      dataIndex: "name",
      render: (text, record) => {
        const defaultLink = record.link.find((item) => item.isDefault)?.link;

        return (
          <>
            {record?.link.length > 0 ? (
              <a
                href={defaultLink}
                style={{ color: "#1890ff", cursor: "pointer" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </a>
            ) : (
              <p>{text}</p>
            )}
          </>
        );
      },
      align: "left",
      width: "28%",
      className: "min-width-280",
    },
    {
      title: t("Labels"),
      dataIndex: "authorName",
      align: "left",
      width: "28%",
      className: "min-width-200",
    },
    {
      title: t("Avatar"),
      render: (record) =>
        record.releaseImageUrl ? (
          <img
            src={BASE_API_URL + record.releaseImageUrl}
            alt="Avatar"
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
          />
        ) : (
          <p style={{ color: "red" }}>{t("No Photo")}</p>
        ),
      align: "center",
      width: "20%",
      className: "min-width-120",
    },
    {
      title: t("Custom..."),
      render: (record, item) => (
        <Space size="middle">
          <CloudUploadOutlined
            style={{ color: "blue", cursor: "pointer", fontSize: 20 }}
            onClick={() => showModal(record)}
          />
          <DeleteOutlined
            style={{ color: "red", fontSize: 20, cursor: "pointer" }}
            onClick={() => showDeleteConfirm(record)}
          />
        </Space>
      ),
      align: "center",
      width: "20%",
    },
  ];
  const showDeleteConfirm = (record) => {
    setDeleteId(record.id);
    setVisible(true);
  };

  const showModal = (record) => {
    setId(record.id);
    setDataLink(record.link);
    setState({ imageUrl: record.releaseImageUrl });
    setIsModalVisible(true);
  };

  const toggleCheckBox = (index) => {
    const newDataLink = dataLink.map((item, i) => ({
      ...item,
      isDefault: i === index,
    }));
    setDataLink(newDataLink);
  };

  const columnsLink = [
    {
      title: t("Default"),
      dataIndex: "isDefault",
      render: (text, record, index) => (
        <Checkbox
          checked={record?.isDefault}
          onChange={() => toggleCheckBox(index)}
        />
      ),
      width: "70px",
      align: "center",
    },
    {
      title: t("Stores"),
      dataIndex: "platform",
      className: "column-link",
      width: "110px",
    },
    {
      title: "Link",
      dataIndex: "link",
      className: "column-link",
      width: "260px",
    },
    {
      title: t("Custom..."),
      width: "70px",
      render: (text, record, index) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleEditSocialLink(text, record, index)}
          />
          <DeleteOutlined
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDeleteSocialLink(text, record, index)}
          />
        </Space>
      ),
    },
  ];

  const handleAddSocialLink = useCallback(() => {
    setVisibleSocialLink(true);
  }, []);

  const handleEditSocialLink = (text, record, index) => {
    setDataLinkDetail(record);
    setIndex(index);
    setVisibleSocialLink(true);
  };

  const handleDeleteSocialLink = (text, record, index) => {
    setDataLinkDetail(record);
    setIndex(index);
    setVisibleDeleteLink(true);
  };

  const handleOk = () => {
    setState({
      imageUrl: null,
      loading: true,
    });

    const formData = {
      uploadType: 1,
      imageUrl: state.imageUrl,
      link: dataLink,
    };
    authPutData({
      url: `api/v1/change-image/${id}?culture=${culture}`,
      method: "PUT",
      payload: formData,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t("Success"),
            description: t("Update successful"),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          setIsModalVisible(false);
          getData();
          setState({
            imageUrl: null,
            loading: false,
          });
        }
      },
    });
  };

  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/delete-image/${id}/1?culture=${culture}`,
      method: "DELETE",
      setLoading: setTableLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setVisible(false);
          notification.open({
            message: t("Success"),
            description: t("Delete successful"),
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });
          getData();
          setState({
            imageUrl: null,
            loading: false,
          });
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false);
    setState({
      imageUrl: null,
    });
  };

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };
  const { loading, imageUrl } = state;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("Upload image")}</div>
    </div>
  );

  useEffect(() => {
    authGetData({
      url: `api/v1/overview/filter/labelnames`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setUserIds(res.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (valueArtist) {
      authGetData({
        url: `api/v1/overview/filter/releases?UserId=${valueArtist}`,
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === 201) {
            setReleaseIds(res.data);
          }
        },
      });
    }
  }, [valueArtist]);

  const handleChangeArtist = (value) => {
    setValueArtist(value);
    if (!value) {
      setReleaseIds([]);
    }
    dispatch(setValueArtist(value));
    dispatch(setReleaseId(""));
    form.setFieldsValue({
      ReleaseId: null,
    });
  };

  const handleChangeRelease = (value) => {
    dispatch(setReleaseId(value));
  };

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      releases: values?.ReleaseId ? values?.ReleaseId : null,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  const handleReset = () => {
    setReleaseIds([]);

    dispatch(setValueArtist(""));

    form.setFieldsValue({
      userNames: null,
      ReleaseId: null,
      status: null,
    });
    setFilterConditions({
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  return (
    <>
      <ContentStyled>
        <div className="select-option">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[20, 20]}>
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t("Labels")} name="userNames">
                  <Select
                    placeholder={t("All")}
                    showSearch
                    showArrow={true}
                    allowClear={true}
                    loading={loading}
                    onChange={handleChangeArtist}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      console.log(22222, input, option);
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {userIds?.length
                      ? userIds.map((item) => (
                          <Option key={item.userId} value={item.userId}>
                            {item.labelName}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t("Releases")} name="ReleaseId">
                  <Select
                    placeholder={t("All")}
                    showSearch
                    showArrow={true}
                    allowClear={true}
                    loading={loading}
                    onChange={handleChangeRelease}
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {releaseIds?.length
                      ? releaseIds.map((item) => (
                          <Option key={item.releaseId} value={item.releaseId}>
                            {item.releaseTitle}
                          </Option>
                        ))
                      : undefined}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t("Photo status")} name="status">
                  <Select placeholder={t("All")}>
                    <Option value={1}>{t("Photo")}</Option>
                    <Option value={2}>{t("No Photo")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={24} lg={12} md={12}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Release list")}</div>
            </div>

            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  defaultCurrent={filterConditions.pageIndex}
                  rowKey="key"
                  dataSource={dataAlbum}
                  columns={columns}
                  totalData={total}
                  loading={loadingList}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                />
              </ConfigProvider>

              <Modal
                title={t("Store photo update")}
                open={isModalVisible}
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                centered
                width={760}
                bodyStyle={{ maxHeight: "500px", overflowY: "scroll" }}
              >
                <Styled>
                  <Row gutter={40}>
                    <Form
                      form={form2}
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      onFinish={handleOk}
                      id="modal-form"
                    >
                      <Row className="margin-left-right-20">
                        <Col span={6}>
                          <Form.Item name="imageUrl">
                            <Upload
                              name="File"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action={
                                BASE_API_URL +
                                `api/v1/upload?culture=${culture}`
                              }
                              beforeUpload={beforeUpload}
                              onChange={handleChange}
                            >
                              {imageUrl ? (
                                <img
                                  src={BASE_API_URL + imageUrl}
                                  alt="avatar"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="margin-left-right-20">
                        <Col span={24}>
                          <Form.Item label={t("Link list")}>
                            <Button
                              className="btn-add-link"
                              onClick={handleAddSocialLink}
                            >
                              {t("Add Link")}
                            </Button>
                          </Form.Item>
                          {dataLink && Object.keys(dataLink).length > 0 && (
                            <div className="table-link">
                              <Table
                                dataSource={dataLink}
                                columns={columnsLink}
                                bordered
                                pagination={false}
                                // scroll={{ y: 100, x: false }}
                              />
                            </div>
                          )}
                          <div className="btn-action">
                            <Space key="update-album-thumb">
                              <Button htmlType="button" onClick={handleCancel}>
                                {t("Cancel")}
                              </Button>
                              <Button
                                htmlType="submit"
                                type="primary"
                                loading={loading}
                              >
                                {t("Update")}
                              </Button>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                </Styled>
              </Modal>
              <Modal
                key="confirm-delete-image-album"
                title={t("Please confirm")}
                open={visible}
                onOk={() => handleDelete(deleteId)}
                onCancel={handleCancel}
                footer={[
                  <Button
                    type="outline"
                    className="btn outline-btn"
                    key="back"
                    onClick={handleCancel}
                  >
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    className="btn primary-btn"
                    onClick={() => handleDelete(deleteId)}
                  >
                    {t("Agree")}
                  </Button>,
                ]}
              >
                <p>{t("Are you sure you want to delete ?")}</p>
              </Modal>
              {visibleSocialLink && (
                <ModalSocialLink
                  visibleSocialLink={visibleSocialLink}
                  setVisibleSocialLink={setVisibleSocialLink}
                  dataLinkDetail={dataLinkDetail}
                  setDataLinkDetail={setDataLinkDetail}
                  dataLink={dataLink}
                  setDataLink={setDataLink}
                  index={index}
                  setIndex={setIndex}
                />
              )}
              {visibleDeleteLink && (
                <ModalDeleteLink
                  visibleDeleteLink={visibleDeleteLink}
                  setVisibleDeleteLink={setVisibleDeleteLink}
                  dataLinkDetail={dataLinkDetail}
                  setDataLinkDetail={setDataLinkDetail}
                  dataLink={dataLink}
                  setDataLink={setDataLink}
                  index={index}
                  setIndex={setIndex}
                />
              )}
            </div>
          </div>
        </div>
      </ContentStyled>
    </>
  );
};

export default AlbumMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }

  .action-buttons {
    justify-content: end;
    margin-top: 0;
  }

  .ant-row {
    align-items: center;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: start;
    }
  }
`;

const Styled = styled.div`
  #modal-form {
    display: block;
    width: 100%;
    .margin-left-right-20 {
      margin: 0 20px;
    }
  }
  .avatar-uploader .ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
  }
  .ant-form-item {
    margin: 0 0 12px !important;
  }
  .ant-form-item-label {
    white-space: pre-wrap;
    text-align: left;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .btn-action {
    white-space: pre-wrap;
    text-align: right;
    margin: 20px 0;
  }
  .btn-field {
    color: blue;
  }
  .table-link {
    margin-bottom: 10px;
  }
  .table-link Table {
    table-layout: fixed !important;
  }
  .table-link .column-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .btn-add-link {
    width: 100%;
  }
`;
