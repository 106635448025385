import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APITiLeBanDien = {
  LIST_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ti-le-gia-ban-dien/list`,
  SYNC_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ti-le-gia-ban-dien/sync`,
  CREATE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ti-le-gia-ban-dien/create`,
  UPDATE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ti-le-gia-ban-dien/update`,
  REMOVE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ti-le-gia-ban-dien/delete`,
  GET_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ti-le-gia-ban-dien/get`,
};
