import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  setValueArtist,
  setValueViewer,
  setValueOwner,
  setReleaseId,
  setTrackId,
  setEndDay,
  setStartDay
} from "../../../../redux/Date/DateReducer";
import { logoutSuccess } from "../../../../redux/Auth/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { authPutData, authPostData } from "@utils/request";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture } from "@utils/function";

const Password = () => {
  const { t } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture()
  const payloaded = {
    token: "",
    versioning: "",
    platform: ""
  };
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = (values) => {

    authPutData({
      url: `api/v1/user/change-password?culture=${culture}`,
      method: "PUT",
      payload: values,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          form.resetFields();
          notification.open({
            message: t('Change Password'),
            description: res.message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          dispatch(setStartDay(null));
          dispatch(setEndDay(null));
          dispatch(setValueOwner(false));
          dispatch(setValueArtist(""));
          dispatch(setValueViewer(""));
          dispatch(setReleaseId(""));
          dispatch(setTrackId(""));
          authPostData({
            url: `api/v1/user/logout?culture=${culture}`,
            method: "POST",
            payload: payloaded,
            setLoading: () => { },
            setImportLoading: () => { },
          });
          dispatch(logoutSuccess());
        }
      },
    });
  };

  const onFinishFailed = (errorInfo) => { };

  const layout = {
    labelCol: { xl: 7, lg: 10, md: 8 },
    wrapperCol: { xl: 6, lg: 8, md: 12 },
  };
  return (
    <FormStyled
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={t('Current password')}
        name="oldPassword"
        rules={[{ required: true, message: t('Please input current password') }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={t('New password')}
        name="newPassword"
        rules={[
          { required: true, message: t('Please input new password') },
          { min: 8, message: t('Password must be between 8-25 characters in length') },
          { max: 25, message: t('Password must be between 8-25 characters in length') },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={t('Confirm password')}
        name="confirmNewPassword"
        rules={[
          { required: true, message: t('Please input new password') },
          { min: 8, message: t('Password must be between 8-25 characters in length') },
          { max: 25, message: t('Password must be between 8-25 characters in length') },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
        <Button htmlType="submit" className="button">
          {t('Update')}
        </Button>
      </Form.Item>
    </FormStyled>
  );
};

export default Password;

const FormStyled = styled(Form)`
  .button {
    background-color: #003366;
    border-color: #003366;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
  }
  .ant-form-item-label {
    font-weight: 500;
    font-size: 20px;
  }
`;
