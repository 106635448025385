import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIVatTu = {
  LIST_VAT_TU: `${BASE_API_URL_CAP_DIEN}/vat-tu/list`,
  SYNC_VAT_TU: `${BASE_API_URL_CAP_DIEN}/vat-tu/sync`,
  CREATE_VAT_TU: `${BASE_API_URL_CAP_DIEN}/vat-tu/create`,
  UPDATE_VAT_TU: `${BASE_API_URL_CAP_DIEN}/vat-tu/update`,
  REMOVE_VAT_TU: `${BASE_API_URL_CAP_DIEN}/vat-tu/delete`,
  GET_VAT_TU: `${BASE_API_URL_CAP_DIEN}/vat-tu/get`,
};
